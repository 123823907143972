import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import Swal from "sweetalert2";
import { isJsonString } from "../../utils/utils";

var annoucementRepo = RepositoryFactory.get("annoucement");

export const getAllAnnoucements = createAsyncThunk(
  "annoucement/get_all_annoucements",
  async (_, thunkAPI: any) => {
    try {
      const data = await annoucementRepo.getAllAnnoucements();
      if (data.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  fetchAnnoucementLoader: false,
  annoucements: isJsonString(localStorage?.getItem("annoucements") || "") ? JSON.parse(localStorage?.getItem("annoucements") || "[]") : [],
};
const annoucementSlice = createSlice({
  name: "annoucement",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllAnnoucements.pending, (state: any, _action: any) => {
      state.fetchAnnoucementLoader = true;
    });
    builder.addCase(
      getAllAnnoucements.fulfilled,
      (state: any, _action: any) => {
        state.fetchAnnoucementLoader = false;
        state.annoucements = _action?.payload?.data?.data;
        localStorage.setItem(
          "annoucements",
          JSON.stringify(_action?.payload?.data?.data)
        );
      }
    );
    builder.addCase(getAllAnnoucements.rejected, (state: any, _action: any) => {
      state.fetchAnnoucementLoader = false;
    });
  },
  reducers: {},
});

export default annoucementSlice.reducer;
