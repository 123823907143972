import React, { useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import DollarIco from "../../assets/imgs/icons/19-$ Icon.png";
import { useSelector } from "react-redux";
import moment from "moment";

type Props = {};

const History = (props: Props) => {
  const { walletHistory } = useSelector((state: any) => state.user);
  console.log(walletHistory);
  const [activeTab, setActiveTab] = useState(1);
  const [activeTabWithdraw, setActiveTabWithdraw] = useState(1);
  return (
    <div className="history main-p carousel-top bg-site-bg">
      <Container className="px-0">
        <div className="d-flex align-items-center history-tabs">
          <Button
            className={`${activeTab == 1 ? "active" : "history-tab"} me-2`}
            block
            onClick={() => {
              setActiveTab(1);
            }}
          >
            Withdraw
          </Button>
          <Button
            className={`${
              activeTab == 2 ? "active" : "history-tab"
            } me-2 text-nowrap`}
            block
            onClick={() => {
              setActiveTab(2);
            }}
          >
            Wallet Transfer
          </Button>
          <Button
            className={`${
              activeTab == 4 ? "active" : "history-tab"
            } text-nowrap me-2`}
            block
            onClick={() => {
              setActiveTab(4);
            }}
          >
            Topup Leverage
          </Button>
          <Button
            className={`${
              activeTab == 3 ? "active" : "history-tab"
            } text-nowrap me-2`}
            block
            onClick={() => {
              setActiveTab(3);
            }}
          >
            Re-Buy Leverage
          </Button>
        </div>
        <div className="mt-2">
          {activeTab == 1 ? (
            <>
              <div className="small-card-ns d-flex justify-content-between align-items-center pointer">
                <div>
                  {" "}
                  <h2 className="dashboard__card__heading mb-1">
                    Total Referral Withdrawal
                  </h2>
                  <h2 className="dashboard__card__value mb-0">
                    $
                    {walletHistory?.totalWithdrawal
                      ? Number(walletHistory?.totalWithdrawal).toFixed(3)
                      : 0}
                  </h2>
                </div>
                <div className="text-end">
                  {" "}
                  <h2 className="dashboard__card__heading mb-1">
                    Total ROI Withdrawal
                  </h2>
                  <h2 className="dashboard__card__value mb-0">
                    $
                    {walletHistory?.totalROIWithdrawal
                      ? Number(walletHistory?.totalROIWithdrawal).toFixed(3)
                      : 0}
                  </h2>
                </div>
              </div>
              <div className="d-flex align-items-center history-tabs my-2">
                <Button
                  className={`${
                    activeTabWithdraw == 1 ? "active" : "history-tab"
                  } me-2`}
                  block
                  onClick={() => {
                    setActiveTabWithdraw(1);
                  }}
                >
                  Referral Reward Withdraw
                </Button>
                <Button
                  className={`${
                    activeTabWithdraw == 2 ? "active" : "history-tab"
                  } text-nowrap`}
                  block
                  onClick={() => {
                    setActiveTabWithdraw(2);
                  }}
                >
                  Staking Reward Withdraw
                </Button>
              </div>
              <Row>
                {activeTabWithdraw == 1 ? (
                  <>
                    {walletHistory?.withdrawalHistory?.map(
                      (history: any, idx: number) => (
                        <Col md="4" className="mt-2" key={idx}>
                          <div className="small-card-ns h-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-0 withdraw-card__body-label">
                                Date
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label">
                                {history?.wr_rdate
                                  ? moment(history?.wr_rdate).format(
                                      "DD MMM YYYY"
                                    )
                                  : "N/A"}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Withdrawal Amount
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                $
                                {history?.wr_usd
                                  ? history?.wr_usd.toFixed(3)
                                  : 0}
                              </h2>
                            </div>
                            {/* <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Tax
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                $
                                {history?.wr_tax
                                  ? history?.wr_tax.toFixed(3)
                                  : 0}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Net Amount
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                $
                                {history?.wr_net
                                  ? history?.wr_net.toFixed(3)
                                  : 0}
                              </h2>
                            </div> */}
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Tx-hash
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                <a
                                  href={`https://bscscan.com/tx/${history?.hash}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View Transaction
                                </a>
                              </h2>
                            </div>

                            {/* <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Tax
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                $
                                {history?.wr_tax
                                  ? history?.wr_tax.toFixed(3)
                                  : 0}
                              </h2>
                            </div> */}
                            {/* <div className="d-flex align-items-center justify-content-between mt-1">
                                    <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                      Income After Tax
                                    </h2>
                                    <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                      ${history?.user_withdrawal_wr_tax || history?.user_withdrawal_wrl_amount ? (history?.user_withdrawal_wrl_amount - history?.user_withdrawal_wr_tax).toFixed(3) : 0}
                                    </h2>
                                  </div> */}
                          </div>
                        </Col>
                      )
                    )}
                  </>
                ) : (
                  <>
                    {walletHistory?.totalROIWithdrawalData?.map(
                      (history: any, idx: any) => (
                        <Col md="4" className="mt-2" key={idx}>
                          <div className="small-card-ns h-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-0 withdraw-card__body-label">
                                Date
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label">
                                {history?.user_roi_withdraw_trn_date
                                  ? moment(
                                      history?.user_roi_withdraw_trn_date
                                    ).format("DD MMM YYYY")
                                  : "N/A"}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Transfer Amount
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                $
                                {history?.user_roi_withdraw_busd_amount
                                  ? (history?.user_roi_withdraw_busd_amount).toFixed(
                                      3
                                    )
                                  : 0}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Tax
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                $
                                {history?.user_roi_withdraw_tax
                                  ? (history?.user_roi_withdraw_tax).toFixed(3)
                                  : 0}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Net Amount
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                $
                                {history?.user_roi_withdraw_net
                                  ? history?.user_roi_withdraw_net?.toFixed(3)
                                  : 0}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Tx-hash:
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                <a
                                  href={`https://bscscan.com/tx/${history?.user_roi_withdraw_token_hash}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View Transaction
                                </a>
                              </h2>
                            </div>
                          </div>
                        </Col>
                      )
                    )}
                  </>
                )}
              </Row>
            </>
          ) : activeTab == 2 ? (
            <>
              <div className="small-card-ns d-flex justify-content-between align-items-center pointer">
                <div>
                  {" "}
                  <h2 className="dashboard__card__heading mb-1">
                    Total Wallet Transfer
                  </h2>
                  <h2 className="dashboard__card__value mb-0">
                    $
                    {walletHistory?.totalWalletTransfer
                      ? walletHistory?.totalWalletTransfer
                      : 0}
                  </h2>
                </div>
                <img src={DollarIco} alt="dollar" height="35px" />
              </div>
              <Row>
                {walletHistory?.latestWalletTransferRecord?.map(
                  (history: any, idx: number) => (
                    <Col md="4" className="mt-2" key={idx}>
                      <div className="small-card-ns h-100">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="mb-0 withdraw-card__body-label">
                            Date
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label">
                            {history?.date
                              ? moment(history?.date).format("DD MMM YYYY")
                              : "N/A"}
                          </h2>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                            Transfer Amount
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                            ${history?.amount ? history?.amount.toFixed(3) : 0}
                          </h2>
                        </div>
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </>
          ) : activeTab == 3 ? (
            <>
              <div className="small-card-ns d-flex justify-content-between align-items-center pointer">
                <div>
                  {" "}
                  <h2 className="dashboard__card__heading mb-1">
                    Total Leverage Brought
                  </h2>
                  <h2 className="dashboard__card__value mb-0">
                    $
                    {walletHistory?.totalLeverageBought
                      ? walletHistory?.totalLeverageBought
                      : 0}
                  </h2>
                </div>
                <img src={DollarIco} alt="dollar" height="35px" />
              </div>
              <Row>
                {walletHistory?.latestLeverageHistory?.map(
                  (history: any, idx: any) => (
                    <Col md="4" className="mt-2" key={idx}>
                      <div className="small-card-ns h-100">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="mb-0 withdraw-card__body-label">
                            Date
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label">
                            {history?.date
                              ? moment(history?.date).format("DD MMM YYYY")
                              : "N/A"}
                          </h2>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                            Transfer Amount
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                            ${history?.amount ? history?.amount?.toFixed(3) : 0}
                          </h2>
                        </div>
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </>
          ) : (
            <>
              <div className="small-card-ns d-flex justify-content-between align-items-center pointer">
                <div>
                  {" "}
                  <h2 className="dashboard__card__heading mb-1">
                    Total Topup Leverage
                  </h2>
                  <h2 className="dashboard__card__value mb-0">
                    $
                    {walletHistory?.totalTopupTransaction?.totalTopupTransaction
                      ? Number(
                          walletHistory?.totalTopupTransaction
                            ?.totalTopupTransaction
                        )?.toFixed(3)
                      : 0}
                  </h2>
                </div>
                <img src={DollarIco} alt="dollar" height="35px" />
              </div>
              <Row>
                {walletHistory?.totalTopupTransactionData?.map(
                  (history: any, idx: any) => (
                    <Col md="4" className="mt-2" key={idx}>
                      <div className="small-card-ns h-100">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="mb-0 withdraw-card__body-label">
                            Date
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label">
                            {history?.user_wallet_topup_transaction_transaction_date
                              ? moment(
                                  history?.user_wallet_topup_transaction_transaction_date
                                ).format("DD MMM YYYY")
                              : "N/A"}
                          </h2>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                            Transfer Amount
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                            $
                            {history?.user_wallet_topup_transaction_usdAmount
                              ? history?.user_wallet_topup_transaction_usdAmount?.toFixed(
                                  3
                                )
                              : 0}
                          </h2>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                            Tx-hash:
                          </h2>
                          <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                            <a
                              href={`https://bscscan.com/tx/${history?.user_wallet_topup_transaction_transaction_hash}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Transaction
                            </a>
                          </h2>
                        </div>
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default History;
