import Repository from "./Repository";

const GET_ALL_ANNOUCEMENTS = "/anouncement/getAllAnouncements";
const GET_ANNOUCEMENT_BY_ID = "/anouncement/getAnouncementById";

export default {
  getAllAnnoucements() {
    return Repository.get(GET_ALL_ANNOUCEMENTS);
  },

  getAnnoucementById(id: number) {
    return Repository.get(`${GET_ANNOUCEMENT_BY_ID}/${id}`);
  },
};
