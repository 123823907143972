import React, { useState, useEffect } from "react";
import Logo from "../../assets/imgs/logo.svg";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Title from "./Title";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../store/slices/userSlice";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import SpinnerImg from "../assets/imgs/spinner.svg";
import { getProfileData } from "../store/slices/authSlice";


type Props = {};

const Profile = (props: Props) => {
  const [phone, setPhone] = useState("");
  const dispatch = useDispatch<any>();
  const { user } = useSelector((state: any) => state.auth);
  const { updateProfileLoader } = useSelector((state: any) => state.user);
  const [registerData, setRegisterData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    setRegisterData({
      phone: user?.user?.user_mobile,
      email: user?.user?.user_email,
      name: user?.user?.user_name,
    });
  }, [user?.user]);

  return (

    <LoadingOverlayWrapper
    active={updateProfileLoader}
    text="Please Wait"
    spinner={<img src={SpinnerImg} alt="spinner" />}
  >
    <div className="main-p carousel-top bg-site-bg">
      <Container className="px-0 px-md-2">
        <Title title="Your Profile" />
        <div className="card text-center">
          <div className="card__header border-radius-top">
            <h2 className="card__body-heading mb-0">Update Profile</h2>
          </div>
          <div className="card__body border-radius-bottom">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                let obj = {
                  uname: registerData?.name,
                  uemail: registerData?.email,
                  umobile: registerData?.phone,
                };
                dispatch(updateProfile({payload: obj, onSuccess:()=>{
                  dispatch(getProfileData())
                }}));
              }}
            >
              <Row className="justify-content-center">
                <Col md="4" className="text-start">
                  <Label className="profile__label">Full Name</Label>
                  <Input
                    placeholder="Please enter your full name"
                    required
                    value={registerData?.name}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        name: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col md="4" className="text-start mt-1 mt-md-0">
                  <Label className="profile__label">Email</Label>
                  <Input
                    placeholder="Please enter your email"
                    required
                    type="email"
                    value={registerData?.email}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        email: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col md="4" className="text-start mt-1 mt-md-0">
                  <Label className="profile__label">Contact Number</Label>
                  <PhoneInput
                    buttonClass="button-class"
                    inputClass="input-class"
                    country={"us"}
                    value={registerData?.phone}
                    onChange={(phone) =>
                      setRegisterData({ ...registerData, phone: phone })
                    }
                  />
                </Col>
                <Col md="4" className="mt-3 mb-2">
                  <Button
                    disabled={updateProfileLoader}
                    className="btn__primary-sm"
                    type="submit"
                  >
                    {updateProfileLoader ? (
                      <Spinner size="sm" />
                    ) : (
                      "Save Changes"
                    )}{" "}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Container>
    </div>

    </LoadingOverlayWrapper>
  );
};

export default Profile;
