import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Input, Row } from "reactstrap";
import Title from "../Title";
import { useDispatch, useSelector } from "react-redux";
import { useAccount, useContractRead, useContractWrite } from "wagmi";
import {
  linkdaoStakingABI,
  linkdaoTokenStakingContractAddress,
} from "../../utils/config";
import {
  getDirectReferralReward,
  getEarnings,
  getStakingReward,
  getWallet,
  withdrawFromWallet,
} from "../../store/slices/userSlice";
import { fromWei } from "../../utils/utils";
import LoadingOverlayWrapper from "react-loading-overlay-ts";

import SpinnerImg from "../../assets/imgs/spinner.svg";

type Props = {};

const Withdrawal = (props: Props) => {
  const dispatch = useDispatch<any>();
  const { earnings, withdrawLoader } = useSelector((state: any) => state?.user);

  const { lkdPrice } = useSelector((state: any) => state?.user);
  const { wallet } = useSelector((state: any) => state?.user);
  const { staking_reward } = useSelector((state: any) => state?.user);
  const { direct_reward, rewardLoader } = useSelector(
    (state: any) => state.user
  );
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    const payload = {
      offset: 0,
      limit: 10,
    };
    getStakingReward({ payload });
  }, []);

  const [referralWithdraw, setReferralWithdraw] = useState(0);

  const { address } = useAccount();

  const { data: totalWithdrawableProfit } = useContractRead({
    address: linkdaoTokenStakingContractAddress,
    abi: linkdaoStakingABI,
    functionName: "getWithdrawableTotalProfit",
    // functionName: staking_reward.sum,
    args: [address],
    watch: true,
  });

  const { write: withdrawReward, isLoading: withdrawRewardLoader } =
    useContractWrite({
      address: linkdaoTokenStakingContractAddress,
      abi: linkdaoStakingABI,
      functionName: "withdrawReward",
      args: [],
    });

  // console.log(fromWei(Number(totalWithdrawableProfit)));

  useEffect(() => {
    dispatch(getEarnings());
    dispatch(getDirectReferralReward({ offset: 0, limit: itemsPerPage }));
  }, []);
  return (
    <LoadingOverlayWrapper
      active={withdrawRewardLoader || withdrawLoader}
      text="Please Wait"
      spinner={<img src={SpinnerImg} alt="spinner" />}
    >
      <div className="withdrawal main-p carousel-top bg-site-bg">
        <Container className="px-0 mb-2 mb-md-0">
          <Title title="Withdrawal Your Reward" />
          <Row>
            <Col md="12 ">
              <div className="card">
                <div className="card__body border-radius-all">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="mb-0  " style={{ fontSize: "13px" }}>
                      LKD Live price
                    </h6>
                    <h6
                      className="mb-0  text-site-primary"
                      style={{ fontSize: "17px" }}
                    >
                      ${lkdPrice ? Number(lkdPrice)?.toFixed(3) : 0}
                    </h6>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="card h-100 mt-2">
                <div className="card__header border-radius-top">
                  Staking Reward
                </div>
                <div className="card__body border-radius-bottom">
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="mb-2 card__body-label">
                      Available Staking Reward
                    </h2>
                    <h2 className="mb-2 card__body-label text-site-primary">
                      {/* ${" "} */}
                      {/* {fromWei(Number(totalWithdrawableProfit || 0))?.toFixed( */}
                      {/* 3 */}
                      {/* )} */}$
                      {Number(earnings?.stacking_rewards || 0)?.toFixed(3)}{" "}
                      Approx.
                    </h2>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-1">
                    <Input
                      placeholder="0"
                      className="me-2"
                      // value={fromWei(
                      //   Number(totalWithdrawableProfit || 0)
                      // )?.toFixed(3)}
                      value={Number(earnings?.stacking_rewards || 0)?.toFixed(
                        3
                      )}
                      disabled
                    />
                    <Input
                      placeholder="0 LKD"
                      disabled
                      // value={
                      //   (
                      //     fromWei(Number(totalWithdrawableProfit || 0)) *
                      //     lkdPrice
                      //   )?.toFixed(3) + " LKD"
                      // }
                      value={
                        (
                          Number(earnings?.stacking_rewards || 0) / lkdPrice
                        )?.toFixed(3) + " LKD (Approx.)"
                      }
                    />
                  </div>
                  <div className="text-center mt-3">
                    {/* <Button
                      className="btn__primary-sm px-5"
                      onClick={() => withdrawReward()}
                      disabled={
                        withdrawRewardLoader ||
                        fromWei(Number(totalWithdrawableProfit || 0)) < 10
                      }
                    >
                      {withdrawRewardLoader
                        ? "Withdrawing..."
                        : "Withdraw Reward"}
                    </Button> */}
                    {/* <p className="mt-2 mb-2 card__body-note">
                      Note: Withdrawal fee 10%, Minimum Withdrawal $10
                    </p> */}
                    <p className="mt-2 mb-2 card__body-note">
                      Please try again later
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="6" className="mt-2 mt-md-0">
              <div className=" card h-100 mt-2">
                <div className=" card__header border-radius-top">
                  Referral Reward
                </div>
                <div className=" card__body border-radius-bottom">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      dispatch(
                        withdrawFromWallet({
                          payload: Number(referralWithdraw),
                          onSuccess: () => {
                            setReferralWithdraw(0);
                            dispatch(getWallet());
                          },
                        })
                      );
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <h2 className="mb-2  card__body-label">
                        Available Referral Reward
                      </h2>
                      <h2 className="mb-2  card__body-label text-site-primary">
                        ${Number(wallet?.balance)?.toFixed(3)}
                      </h2>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-1">
                      <Input
                        placeholder="0"
                        className="me-2"
                        required
                        value={referralWithdraw}
                        onChange={(e: any) => {
                          setReferralWithdraw(e.target.value);
                        }}
                      />
                      <Input
                        placeholder="0 LKD"
                        disabled
                        value={
                          (referralWithdraw / lkdPrice)?.toFixed(3) +
                          " LKD (Approx.)"
                        }
                      />
                    </div>
                    <div className="text-center mt-3">
                      {/* <Button
                        className="btn__primary-sm px-5"
                        disabled={withdrawLoader || referralWithdraw < 10}
                        type="submit"
                      >
                        {withdrawLoader ? "Withdrawing" : "Withdraw"}
                      </Button>
                      <p className="mt-2 mb-2  card__body-note">
                        Note: Withdrawal fee 10%, Minimum Withdrawal $10
                      </p> */}
                      <p className="mt-2 mb-2 card__body-note">
                        Please try again later
                      </p>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </LoadingOverlayWrapper>
  );
};

export default Withdrawal;
