import axios from "axios";
import Repository from "./Repository";
const CHECK_SPONSOR = "/user/checksponsor";
const UPDATE_PROFILE = "/user/editProfile";
const WALLET = "/user/wallet";
const BALANCE = "/user/balance";
const GET_STATEMENT = "/user/statement";
const GET_BUSINESS = "/user/business";
const GET_DOWNLINE = "/user/downline";
const FILTER_DOWNLINE = "/user/filterDownline";
const GET_EARNINGS = "/user/income";
const GET_STAKE_INFO = "/user/stakeinfo";
const STAKING_REWARD = "/user/getStakingRewardEarning";
const DIRECT_REFERRAL = "/user/directreferral";
const PERFORMANCE = "/user/perofrmence";
const TOP_REFERRAL = "/user/topreferral";
const PASSIVE_LEVEL = "/user/passive";
const VIP_LEVEL = "/user/vip";
const STAR_ROYALTY = "/user/startRoyalty";
const AWARD = "/user/award";
const CREATE_TICKET = "/user/ticket";
const GET_SUPPORT_TICKETS = "/user/support";
const GET_LKD_PRICE = "/dashboard/getLKDPrice";
const GET_LEVERAGE_DATA = "/user/getLeverageData";
const GET_LIVE_PRICES = "/dashboard/getPrices";
const GET_WALLET_HISTORY = "/user/getHistory";
const TRANSFER_LIVE_BALANCE_TO_LEVERAGE = "/user/transferLiveBalanceToLeverage";
const LEVERAGE_BUY = "/dashboard/ReBuyLeverage";
const STAKE_FROM_WALLET = "/user/stakeFromWallet";
const WITHDRAW_FROM_WALLET = "/user/withdrawFromWallet";
const ADMIN_TOP_REF = "/user/adminTopRef";
const UPDATE_PRICE = "/dashboard/updateLkdPrice";
const STAKING_REWARD_EARNING = "/user/getStakingRewardEarning";
const GET_USERS_TREE = "/user/getUserTree";

export default {
  getLkdPrice() {
    return Repository.get(GET_LKD_PRICE);
  },
  getLeverageData() {
    return Repository.get(GET_LEVERAGE_DATA);
  },
  getLivePrices() {
    return Repository.get(GET_LIVE_PRICES);
  },
  checkSponsor(payload: any) {
    return Repository.post(CHECK_SPONSOR, payload);
  },

  updateProfile(payload: any) {
    return Repository.post(UPDATE_PROFILE, payload);
  },

  getWallet() {
    return Repository.get(WALLET);
  },

  getWalletHistory() {
    return Repository.get(GET_WALLET_HISTORY);
  },

  getUserTree() {
    return Repository.get(GET_USERS_TREE);
  },

  getBalance() {
    return Repository.get(BALANCE);
  },

  getStatement() {
    return Repository.get(GET_STATEMENT);
  },

  getBusiness(payload: any) {
    return Repository.get(
      `${GET_BUSINESS}?offset=${payload?.offset}&limit=${payload?.limit}`
    );
  },

  getEarnings() {
    return Repository.get(GET_EARNINGS);
  },

  getTeam(payload: any) {
    return Repository.get(
      `${GET_DOWNLINE}?offset=${payload?.offset}&limit=${payload?.limit}`
    );
  },

  filterDownline(payload: any) {
    return Repository.get(
      `${FILTER_DOWNLINE}?doaTo=${payload?.doaTo}&doaFrom=${payload?.doaFrom}&tree_level=${payload?.tree_level}&user_lid=${payload?.user_lid}&offset=${payload?.offset}&limit=${payload?.limit}`
    );
  },

  getStakeInfo() {
    return Repository.get(GET_STAKE_INFO);
  },

  getStakingReward(payload: any) {
    console.log(payload);
    return Repository.get(
      `${STAKING_REWARD}?offset=${payload?.offset}&limit=${payload?.limit}`
    );
  },

  getDirectReferralReward(payload: any) {
    return Repository.get(
      `${DIRECT_REFERRAL}?offset=${payload?.offset}&limit=${payload?.limit}`
    );
  },

  getPerformanceReward(payload: any) {
    return Repository.get(
      `${PERFORMANCE}?offset=${payload?.offset}&limit=${payload?.limit}`
    );
  },

  getTopReferral(payload: any) {
    return Repository.get(
      `${TOP_REFERRAL}?offset=${payload?.offset}&limit=${payload?.limit}`
    );
  },

  getPassive(payload: any) {
    if (payload?.level) {
      return Repository.get(
        `${PASSIVE_LEVEL}?offset=${payload?.offset}&limit=${payload?.limit}&level=${payload?.level}`
      );
    } else {
      return Repository.get(
        `${PASSIVE_LEVEL}?offset=${payload?.offset}&limit=${payload?.limit}`
      );
    }
  },

  getVIP(payload: any) {
    if (payload?.level) {
      return Repository.get(
        `${VIP_LEVEL}?offset=${payload?.offset}&limit=${payload?.limit}&level=${payload?.level}`
      );
    } else {
      return Repository.get(
        `${VIP_LEVEL}?offset=${payload?.offset}&limit=${payload?.limit}`
      );
    }
  },

  getStarRoyalty(payload: any) {
    return Repository.get(
      `${STAR_ROYALTY}?offset=${payload?.offset}&limit=${payload?.limit}`
    );
  },

  getAward(payload: any) {
    return Repository.get(
      `${AWARD}?offset=${payload?.offset}&limit=${payload?.limit}`
    );
  },

  createTicket(payload: any) {
    return Repository.post(CREATE_TICKET, payload);
  },

  getSupportTickets() {
    return Repository.get(GET_SUPPORT_TICKETS);
  },
  transferLiveBalanceToLeverage(amount: number) {
    return Repository.get(`${TRANSFER_LIVE_BALANCE_TO_LEVERAGE}/${amount}`);
  },
  leverageBuy(payload: any) {
    return Repository.post(LEVERAGE_BUY, payload);
  },
  stakeFromWallet(payload: any) {
    console.log("result", payload);
    return Repository.post(STAKE_FROM_WALLET, payload);
  },
  withdrawFromWallet(amount: number) {
    return Repository.get(`${WITHDRAW_FROM_WALLET}/${amount}`);
  },
  adminTopRef() {
    return Repository.get(ADMIN_TOP_REF);
  },
  updatePrice() {
    const url = `https://oracle.linkdao.cc/updatePrice`;
    return axios.get(url);
  },
  updateLkdPrice() {
    return Repository.get(UPDATE_PRICE);
  },
  getStakingRewardEarning(payload: any) {
    return Repository.get(
      `${STAKING_REWARD_EARNING}?offset=${payload?.offset}&limit=${payload?.limit}`
    );
  },
};
