import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Title from "./Title";
import TeamIco from "../assets/imgs/icons/TEAM.png";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import NoDataFound from "./NoDataFound";
import { filterDownline, getTeam } from "../store/slices/userSlice";
import ReactPaginate from "react-paginate";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import SpinnerImg from "../assets/imgs/spinner.svg";

type Props = {};

const Team = (props: Props) => {
  const dispatch = useDispatch<any>();
  const { teams, teamLoader } = useSelector((state: any) => state.user);

  const [filterTeam, setFilterTeam] = useState({
    level: "",
    lid: "",
    dateFrom: "",
    dateUpto: "",
  });

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (event: any) => {
    setCurrentPage(event?.selected);
  };

  useEffect(() => {
    let newItems = Number(teams?.total_downline) / itemsPerPage;
    setPageCount(newItems);
  }, [teams]);

  const fetchUpdatedData = () => {
    const startIndex = currentPage * itemsPerPage;
    if (
      filterTeam?.level ||
      filterTeam?.lid ||
      filterTeam?.dateFrom ||
      filterTeam?.dateUpto
    ) {
      let payload = {
        doaTo: filterTeam?.dateUpto
          ? moment(filterTeam?.dateUpto).format("x")
          : "",
        doaFrom: filterTeam?.dateFrom
          ? moment(filterTeam?.dateUpto).format("x")
          : "",
        tree_level: filterTeam?.level,
        user_lid: filterTeam?.lid,
        offset: startIndex,
        limit: itemsPerPage,
      };
      dispatch(filterDownline({ payload }));
    } else {
      let payload = {
        doaTo: "",
        doaFrom: "",
        tree_level: 1,
        user_lid: "",
        offset: startIndex,
        limit: itemsPerPage,
      };
      dispatch(filterDownline({ payload }));
    }
  };

  useEffect(() => {
    fetchUpdatedData();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    setPageCount(0);
  }, []);

  return (
    <LoadingOverlayWrapper
      active={teamLoader}
      text="Please Wait"
      spinner={<img src={SpinnerImg} alt="spinner" />}
    >
      <div className="team main-p carousel-top bg-site-bg">
        <Container className="px-0 px-md-2">
          <Title title="Team Statistics" />
          <Row>
            <Col md="3">
              <div className="card">
                <div className="card__body border-radius-all">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0 filter__card-label">
                        Total Registered Partners
                      </p>
                      <h6 className="mb-0  text-site-primary number">
                        {" "}
                        {teamLoader ? (
                          <Spinner size="sm" />
                        ) : teams?.total_downline ? (
                          teams?.total_downline
                        ) : (
                          0
                        )}
                      </h6>
                    </div>
                    <img src={TeamIco} alt="dollar" height="35px" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="card mt-2 mt-md-0">
                <div className="card__body border-radius-all">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0 filter__card-label">Active Partners</p>
                      <h6 className="mb-0  text-site-primary number">
                        {" "}
                        {teamLoader ? (
                          <Spinner size="sm" />
                        ) : teams?.Active_downline ? (
                          teams?.Active_downline
                        ) : (
                          0
                        )}
                      </h6>
                    </div>
                    <img src={TeamIco} alt="dollar" height="35px" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="card mt-2 mt-md-0">
                <div className="card__body border-radius-all">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0 filter__card-label">
                        In-Active Partners
                      </p>
                      <h6 className="mb-0  text-site-primary number">
                        {" "}
                        {teamLoader ? (
                          <Spinner size="sm" />
                        ) : teams?.Inactive_downline ? (
                          teams?.Inactive_downline
                        ) : (
                          0
                        )}
                      </h6>
                    </div>
                    <img src={TeamIco} alt="dollar" height="35px" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="card mt-2 mt-md-0">
                <div className="card__body border-radius-all">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0 filter__card-label">Total Business</p>
                      <h6 className="mb-0  text-site-primary number">
                        {" "}
                        {teamLoader ? (
                          <Spinner size="sm" />
                        ) : teams?.total_business ? (
                          "$" + teams?.total_business?.toFixed(3)
                        ) : (
                          0
                        )}
                      </h6>
                    </div>
                    <img src={TeamIco} alt="dollar" height="35px" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="card mt-2">
            <div className="card__header border-radius-top">
              <h2 className="team-heading text-site-secondary text-center mb-0">
                Downline Search
              </h2>
            </div>
            <div className="card__body border-radius-bottom">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  let payload = {
                    doaTo: filterTeam?.dateUpto
                      ? moment(filterTeam?.dateUpto).format("x")
                      : "",
                    doaFrom: filterTeam?.dateFrom
                      ? moment(filterTeam?.dateUpto).format("x")
                      : "",
                    tree_level: filterTeam?.level,
                    user_lid: filterTeam?.lid,
                    offset: 0,
                    limit: itemsPerPage,
                  };
                  dispatch(filterDownline({ payload }));
                }}
              >
                <Row className="align-items-center ">
                  <Col md="2">
                    <Label className="mb-2">Level</Label>
                    <Input
                      placeholder="Select Level"
                      type="select"
                      value={filterTeam?.level}
                      onChange={(e: any) => {
                        setFilterTeam({ ...filterTeam, level: e.target.value });
                      }}
                    >
                      <option value="" selected disabled>
                        Select Level
                      </option>
                      {new Array(150).fill(1)?.map((opt, idx) => (
                        <option value={idx + 1} key={idx}>
                          Level {idx + 1}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col md="2" className="mt-1 mt-md-0">
                    <Label className="mb-2">User ID</Label>
                    <Input
                      placeholder="User ID"
                      value={filterTeam?.lid}
                      onChange={(e: any) => {
                        setFilterTeam({ ...filterTeam, lid: e.target.value });
                      }}
                    />
                  </Col>
                  <Col md="2" xs="6" className="mt-1 mt-md-0">
                    <Label className="mb-2">From Date</Label>
                    <Input
                      style={{
                        minHeight: "38px",
                        padding: "0px 10px !important ",
                      }}
                      type="date"
                      value={filterTeam?.dateFrom}
                      onChange={(e: any) => {
                        setFilterTeam({
                          ...filterTeam,
                          dateFrom: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md="2" xs="6" className="mt-1 mt-md-0">
                    <Label className="mb-2">Upto Date</Label>
                    <Input
                      type="date"
                      style={{
                        minHeight: "38px",
                        padding: "0px 10px !important ",
                      }}
                      value={filterTeam?.dateUpto}
                      onChange={(e: any) => {
                        setFilterTeam({
                          ...filterTeam,
                          dateUpto: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col
                    md="4"
                    className="text-center d-md-flex justify-content-md-end mt-3 mt-md-4 mb-2 "
                  >
                    <Button
                      className="btn__primary-sm me-2"
                      type="submit"
                      style={{
                        minHeight: "38px",
                      }}
                    >
                      Search Now
                    </Button>
                    {filterTeam?.level ||
                    filterTeam?.lid ||
                    filterTeam?.dateFrom ||
                    filterTeam?.dateUpto ? (
                      <Button
                        style={{
                          minHeight: "38px",
                        }}
                        className="btn__clear me-0 me-md-2 ms-3 ms-md-0 btn btn-danger"
                        onClick={() => {
                          setFilterTeam({
                            level: "",
                            lid: "",
                            dateFrom: "",
                            dateUpto: "",
                          });
                          dispatch(getTeam({ offset: 0, limit: itemsPerPage }));
                        }}
                      >
                        Clear
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <Row>
            {teams?.downline?.length > 0 ? (
              <>
                {teams?.downline?.map((team: any, idx: number) => {
                  if (team?.uslid !== null) {
                    return (
                      <Col md="4" className="mt-2" key={idx}>
                        <div className="card h-100">
                          <div className="card__body border-radius-all">
                            <div className="d-flex align-items-center justify-content-between">
                              <p className="mb-0 filter__card-label">
                                USER ID-{team?.uslid ? team?.uslid : "N/A"}
                              </p>
                              <p className="mb-0 filter__card-label text-site-primary">
                                {team?.status}
                              </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <p className="mb-0 filter__card-label">
                                USER Name
                              </p>
                              <p className="mb-0 filter__card-label text-site-primary">
                                {team?.name ? team?.name : "N/A"}
                              </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <p className="mb-0 filter__card-label">
                                Sponsor: {team?.splid ? team?.splid : "N/A"}
                              </p>
                              <p className="mb-0 filter__card-label">
                                DOFS:{" "}
                                {team?.jdate
                                  ? moment(team?.jdate).format("YYYY-DD-MM")
                                  : "N/A"}
                              </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <p className="mb-0 filter__card-label">
                                Total Stake: $
                                {team?.totalInvest
                                  ? Number(team?.totalInvest)?.toFixed(3)
                                  : 0}
                              </p>
                              <p className="mb-0 filter__card-label">
                                Level - {team?.level ? team?.level : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  }
                  return null;
                })}
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <i className="fas fa-angle-right text-site-primary" />
                  }
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={1}
                  pageCount={pageCount}
                  previousLabel={
                    <i className="fas fa-angle-left text-site-primary" />
                  }
                  containerClassName="custom-pagination"
                  activeClassName="custom-pagination-active"
                  previousClassName="custom-pagination-prev"
                  nextClassName="custom-pagination-next"
                  pageClassName="custom-pagination-li"
                  disabledClassName="custom-pagination-disabled"
                />
              </>
            ) : (
              <NoDataFound isLoading={teamLoader} />
            )}
          </Row>
        </Container>
      </div>
    </LoadingOverlayWrapper>
  );
};

export default Team;
