import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { readContract } from "@wagmi/core";
import {
  linkdaoStakingABI,
  linkdaoTokenStakingContractAddress,
} from "../../utils/config";
import { fromWei, getPackage } from "../../utils/utils";
import Swal from "sweetalert2";

var userRepo = RepositoryFactory.get("user");

export const getUserTree = createAsyncThunk(
  "user/get_users_tree",
  async (_, thunkAPI: any) => {
    try {
      const data = await userRepo.getUserTree();
      if (data?.status === 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getBusiness = createAsyncThunk(
  "user/get_businesses",
  async ({ offset, limit }: any, thunkAPI: any) => {
    try {
      const data = await userRepo.getBusiness({ offset, limit });
      if (data?.status === 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getTeam = createAsyncThunk(
  "user/get_team",
  async ({ offset, limit }: any, thunkAPI: any) => {
    try {
      const data = await userRepo.getTeam({ offset, limit });
      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const filterDownline = createAsyncThunk(
  "user/filter_downline",
  async ({ payload }: any, thunkAPI: any) => {
    try {
      const data = await userRepo.filterDownline(payload);

      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getEarnings = createAsyncThunk(
  "user/get_eanings",
  async (_, thunkAPI: any) => {
    try {
      const data = await userRepo.getEarnings();
      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStakeInfo = createAsyncThunk(
  "user/get_stake_info",
  async (_, thunkAPI: any) => {
    try {
      const data = await userRepo.getStakeInfo();
      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWalletHistory = createAsyncThunk(
  "user/get_wallet_history",
  async (_, thunkAPI: any) => {
    try {
      const data = await userRepo.getWalletHistory();
      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWallet = createAsyncThunk(
  "user/get_wallet",
  async (_, thunkAPI: any) => {
    try {
      const data = await userRepo.getWallet();
      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getBalance = createAsyncThunk(
  "user/get_balance",
  async (_, thunkAPI: any) => {
    try {
      const data = await userRepo.getBalance();
      if (data?.status == 200) {
        return { data: data?.data?.info };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStakingReward = createAsyncThunk(
  "user/get_staking_reward",
  async ({ offset, limit }: any, thunkAPI: any) => {
    try {
      // console.log(offset);
      const data = await userRepo.getStakingReward({ offset, limit });
      // console.log(data)
      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDirectReferralReward = createAsyncThunk(
  "user/get_direct_reward",
  async ({ offset, limit }: any, thunkAPI: any) => {
    try {
      const data = await userRepo.getDirectReferralReward({ offset, limit });
      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPerformanceReward = createAsyncThunk(
  "user/get_performance_reward",
  async ({ offset, limit }: any, thunkAPI: any) => {
    try {
      const data = await userRepo.getPerformanceReward({ offset, limit });
      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const adminTopRef = createAsyncThunk(
  "user/admin_top_ref",
  async (_, thunkAPI: any) => {
    try {
      const data = await userRepo.adminTopRef();
      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getTopReferral = createAsyncThunk(
  "user/get_top_referral",
  async ({ offset, limit }: any, thunkAPI: any) => {
    try {
      const data = await userRepo.getTopReferral({ offset, limit });
      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPassiveReward = createAsyncThunk(
  "user/get_passive_reward",
  async (payload: any, thunkAPI: any) => {
    try {
      const data = await userRepo.getPassive(payload);
      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getVIPReward = createAsyncThunk(
  "user/get_vip_reward",
  async (payload: any, thunkAPI: any) => {
    try {
      const data = await userRepo.getVIP(payload);
      if (data?.status === 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStarRoyalty = createAsyncThunk(
  "user/get_star_royalty",
  async ({ offset, limit }: any, thunkAPI: any) => {
    try {
      const data = await userRepo.getStarRoyalty({ offset, limit });
      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAward = createAsyncThunk(
  "user/get_award",
  async ({ offset, limit }: any, thunkAPI: any) => {
    try {
      const data = await userRepo.getAward({ offset, limit });
      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateProfile = createAsyncThunk(
  "user/update_profile",
  async ({ payload, onSuccess }: any, thunkAPI: any) => {
    try {
      const data = await userRepo.updateProfile(payload);
      if (data?.status == 201) {
        Swal.fire({
          text: "Profile Updated Successfully",
          title: "Success",
          confirmButtonText: "Ok",
          icon: "success",
        });
        onSuccess();
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createTicket = createAsyncThunk(
  "user/create_ticket",
  async ({ payload, onSuccess }: any, thunkAPI: any) => {
    try {
      const data = await userRepo.createTicket(payload);
      if (data?.status == 201) {
        onSuccess();
        Swal.fire({
          text: "Ticket Created Successfully",
          title: "Success",
          confirmButtonText: "Ok",
          icon: "success",
        });
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStatement = createAsyncThunk(
  "user/get_statement",
  async (_, thunkAPI: any) => {
    try {
      const data = await userRepo.getStatement();
      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSupportTickets = createAsyncThunk(
  "user/get_support_tickets",
  async (_, thunkAPI: any) => {
    try {
      const data = await userRepo.getSupportTickets();
      if (data?.status == 200) {
        return { data: data?.data?.info };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const transferLiveBalanceToLeverage = createAsyncThunk(
  "user/transfer_live_balance_to_leverage",
  async ({ payload, onSuccess }: any, thunkAPI: any) => {
    try {
      const data = await userRepo.transferLiveBalanceToLeverage(payload);
      if (data?.status === 200) {
        onSuccess();
        Swal.fire({
          text: "Fund Transferred Successfully",
          title: "Success",
          confirmButtonText: "Ok",
          icon: "success",
        });
      }

      return { data: data?.data?.info };
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage?.clear();
      }
      if (error?.response?.data?.message) {
        Swal.fire({
          text: error?.response?.data?.message,
          title: "Error",
          confirmButtonText: "Ok",
          icon: "error",
        });
      }
      return thunkAPI.rejectWithValue();
    }
  }
);

export const reBuyLeverage = createAsyncThunk(
  "user/leverage_buy",
  async ({ payload, onSuccess }: any, thunkAPI: any) => {
    try {
      const data = await userRepo.leverageBuy(payload);
      if (data?.status === 201) {
        onSuccess();
        Swal.fire({
          text: "Leverage Bought Successfully",
          title: "Success",
          confirmButtonText: "Ok",
          icon: "success",
        });
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage?.clear();
      }
      if (error?.response?.data?.message) {
        Swal.fire({
          text: error?.response?.data?.message,
          title: "Error",
          confirmButtonText: "Ok",
          icon: "error",
        });
      }
      return thunkAPI.rejectWithValue();
    }
  }
);

export const stakeFromWallet = createAsyncThunk(
  "user/stake_from_wallet",
  async ({ referralValueInUSD, onSuccess }: any, thunkAPI: any) => {
    try {
      const data = await userRepo.stakeFromWallet({ referralValueInUSD });
      // console.log("sd");
      if (data?.status === 201) {
        onSuccess();
        Swal.fire({
          text: "Your stake will be done in 10 minutes",
          title: "Success",
          confirmButtonText: "Ok",
          icon: "success",
        });
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage?.clear();
      }
      if (error?.response?.data?.message) {
        Swal.fire({
          text: error?.response?.data?.message,
          title: "Error",
          confirmButtonText: "Ok",
          icon: "error",
        });
      }
      return thunkAPI.rejectWithValue();
    }
  }
);

export const withdrawFromWallet = createAsyncThunk(
  "user/withdraw_from_wallet",
  async ({ payload, onSuccess }: any, thunkAPI: any) => {
    try {
      const data = await userRepo.withdrawFromWallet(Number(payload));
      if (data?.status === 200) {
        onSuccess();
        Swal.fire({
          text: "Your withdrawal will be done in 10 minutes",
          title: "Success",
          confirmButtonText: "Ok",
          icon: "success",
        });
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage?.clear();
      }
      if (error?.response?.data?.message) {
        Swal.fire({
          text: error?.response?.data?.message,
          title: "Error",
          confirmButtonText: "Ok",
          icon: "error",
        });
      }
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStakingRewardEarning = createAsyncThunk(
  "user/get_staking_reward_earning",
  async ({ offset, limit }: any, thunkAPI: any) => {
    try {
      const data = await userRepo.getStakingRewardEarning({ offset, limit });
      // console.log(data)
      if (data?.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage?.clear();
      }
      if (error?.response?.data?.message) {
        Swal.fire({
          text: error?.response?.data?.message,
          title: "Error",
          confirmButtonText: "Ok",
          icon: "error",
        });
      }
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getLeverageData = createAsyncThunk(
  "user/get_leverage_data",
  async (_, thunkAPI: any) => {
    try {
      const data = await userRepo.getLeverageData();
      if (data?.status === 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getLkdPrice = createAsyncThunk(
  "dashboard/getLkdPrice",
  async (_, thunkAPI: any) => {
    try {
      const data = await userRepo.getLkdPrice();
      if (data?.status === 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getLivePrices = createAsyncThunk(
  "dashboard/get_live_prices",
  async (_, thunkAPI: any) => {
    try {
      const data = await userRepo.getLivePrices();
      if (data?.status === 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getUserStakeSummary = createAsyncThunk(
  "dashboard/getUserStakeSummary",
  async (address: string, thunkAPI: any) => {
    try {
      let [investorData, userStakeSummary]: any = await Promise.all([
        readContract({
          address: linkdaoTokenStakingContractAddress,
          abi: linkdaoStakingABI,
          functionName: "investors",
          args: [address],
        }),
        readContract({
          address: linkdaoTokenStakingContractAddress,
          abi: linkdaoStakingABI,
          functionName: "getUserTotalInvestments",
          args: [address],
        }),
      ]);

      userStakeSummary = userStakeSummary
        ?.map((item: any) => item.toString())
        ?.map((item: any) => fromWei(item));

      const userStakeSummaryData = {
        totalStaked: userStakeSummary.reduce((a: any, b: any) => a + b, 0),
        totalStakedPackage1: userStakeSummary[0],
        totalStakedPackage2: userStakeSummary[1],
        totalStakedPackage3: userStakeSummary[2],
        totalStakedPackage4: userStakeSummary[3],
        startDate: Number(investorData[2]),
      };
      // console.log(userStakeSummaryData, "userStakeSummary");
      return userStakeSummaryData;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const getStakesData = createAsyncThunk(
  "dashboard/getStakesData",
  async (address: string, thunkAPI: any) => {
    try {
      const userInvestmentsId: any = await readContract({
        address: linkdaoTokenStakingContractAddress,
        abi: linkdaoStakingABI,
        functionName: "getUserInvestments",
        args: [address],
      });

      let stakesData = await Promise.all(
        userInvestmentsId
          ?.map((item: any) => item.toString())
          .map(async (id: any) => {
            const stakeData = await readContract({
              address: linkdaoTokenStakingContractAddress,
              abi: linkdaoStakingABI,
              functionName: "investments",
              args: [id],
            });
            return stakeData;
          })
      );

      stakesData = stakesData?.map((item: any, index: number) => {
        return {
          id: Number(userInvestmentsId[index]),
          investor: item[0],
          totalInvestment: fromWei(Number(item[1])),
          totalReward: fromWei(Number(item[2])),
          roiPercentage: Number(item[3]) / 100,
          maxReward: fromWei(Number(item[4])),
          lkdPrice: Number(item[5]) / 100,
          startDate: Number(item[6]),
          lastCheckpoint: Number(item[7]),
          stakePackage: getPackage(fromWei(Number(item[1]))),
        };
      });
      // console.log(stakesData, "stakesData");
      return stakesData;
    } catch (error: any) {
      console.log(error);
    }
  }
);

const initialState = {
  fetchLoader: false,
  rewardLoader: false,
  updateProfileLoader: false,
  business: null,
  teams: undefined,
  statement: undefined,
  earnings: undefined,
  stake_info: null,
  wallet: undefined,
  userTreeLoader: false,
  user_tree: undefined,
  walletHistory: undefined,
  balance: undefined,
  staking_reward: [],
  staking_reward_earning: undefined,
  stakingRewardLoader: false,
  direct_reward: undefined,
  performance_reward: undefined,
  top_referral_reward: undefined,
  passive_reward: undefined,
  vip_reward: undefined,
  star_royalty: undefined,
  award: undefined,
  tickets: [],
  createTicketLoader: false,
  lkdPrice: 0,
  stakesData: [],
  userStakeSummary: {},
  leverageData: undefined,
  livePrices: [],
  reBuyLeverageLoader: false,
  stakeLoader: false,
  withdrawLoader: false,
  adminTop: undefined,
  fundTransferLoader: false,

  //Loaders
  teamLoader: false,
  businessLoader: false,
  performanceLoader: false,
  passiveLoader: false,
  vipLoader: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setStakesData: (state: any, action: any) => {
      state.stakesData = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get user stake summary
    builder.addCase(
      getUserStakeSummary.pending,
      (state: any, _action: any) => {}
    );
    builder.addCase(
      getUserStakeSummary.fulfilled,
      (state: any, action: any) => {
        state.userStakeSummary = action.payload;
      }
    );

    builder.addCase(getLkdPrice.pending, (state: any, _action: any) => {});
    builder.addCase(getLkdPrice.fulfilled, (state: any, _action: any) => {
      state.lkdPrice = _action?.payload?.data?.price;
    });
    // get stakes data
    builder.addCase(getStakesData.pending, (state: any, _action: any) => {});
    builder.addCase(getStakesData.fulfilled, (state: any, action: any) => {
      state.stakesData = action.payload;
    });
    // ReBuyLeverage ***********-***********
    builder.addCase(reBuyLeverage.pending, (state: any, _action: any) => {
      state.reBuyLeverageLoader = true;
    });
    builder.addCase(reBuyLeverage.fulfilled, (state: any, _action: any) => {
      state.reBuyLeverageLoader = false;
    });
    builder.addCase(reBuyLeverage.rejected, (state: any, _action: any) => {
      state.reBuyLeverageLoader = false;
    });
    // transferLiveBalanceToLeverage ***********-***********
    builder.addCase(
      transferLiveBalanceToLeverage.pending,
      (state: any, _action: any) => {
        state.fundTransferLoader = true;
      }
    );
    builder.addCase(
      transferLiveBalanceToLeverage.fulfilled,
      (state: any, _action: any) => {
        state.fundTransferLoader = false;
      }
    );
    builder.addCase(
      transferLiveBalanceToLeverage.rejected,
      (state: any, _action: any) => {
        state.fundTransferLoader = false;
      }
    );
    // getUserTree ***********-***********
    builder.addCase(getUserTree.pending, (state: any, _action: any) => {
      state.userTreeLoader = true;
    });
    builder.addCase(getUserTree.fulfilled, (state: any, _action: any) => {
      state.userTreeLoader = false;
      state.user_tree = _action?.payload?.data?.data;
    });
    builder.addCase(getUserTree.rejected, (state: any, _action: any) => {
      state.userTreeLoader = false;
    });
    // getStakingRewardEarning ***********-***********
    builder.addCase(
      getStakingRewardEarning.pending,
      (state: any, _action: any) => {
        state.stakingRewardLoader = true;
      }
    );
    builder.addCase(
      getStakingRewardEarning.fulfilled,
      (state: any, _action: any) => {
        state.stakingRewardLoader = false;
        state.staking_reward_earning = _action?.payload?.data;
      }
    );
    builder.addCase(
      getStakingRewardEarning.rejected,
      (state: any, _action: any) => {
        state.stakingRewardLoader = false;
      }
    );
    // Stake From Wallet ***********-***********
    builder.addCase(stakeFromWallet.pending, (state: any, _action: any) => {
      state.stakeLoader = true;
    });
    builder.addCase(stakeFromWallet.fulfilled, (state: any, _action: any) => {
      state.stakeLoader = false;
    });
    builder.addCase(stakeFromWallet.rejected, (state: any, _action: any) => {
      state.stakeLoader = false;
    });
    //withdraw from wallet
    builder.addCase(withdrawFromWallet.pending, (state: any, _action: any) => {
      state.withdrawLoader = true;
    });
    builder.addCase(
      withdrawFromWallet.fulfilled,
      (state: any, _action: any) => {
        state.withdrawLoader = false;
      }
    );
    builder.addCase(withdrawFromWallet.rejected, (state: any, _action: any) => {
      state.withdrawLoader = false;
    });
    // getStatement ***********-***********
    builder.addCase(getStatement.pending, (state: any, _action: any) => {
      state.fetchLoader = true;
    });
    builder.addCase(getStatement.fulfilled, (state: any, _action: any) => {
      state.fetchLoader = false;
      state.statement = _action?.payload?.data;
    });
    builder.addCase(getStatement.rejected, (state: any, _action: any) => {
      state.fetchLoader = false;
    });
    // getEarnings ***********-***********
    builder.addCase(getEarnings.pending, (state: any, _action: any) => {
      state.fetchLoader = true;
    });
    builder.addCase(getEarnings.fulfilled, (state: any, _action: any) => {
      state.fetchLoader = false;
      state.earnings = _action?.payload?.data;
    });
    builder.addCase(getEarnings.rejected, (state: any, _action: any) => {
      state.fetchLoader = false;
    });
    // getTeam ***********-***********
    builder.addCase(getTeam.pending, (state: any, _action: any) => {
      state.teamLoader = true;
    });
    builder.addCase(getTeam.fulfilled, (state: any, _action: any) => {
      state.teamLoader = false;
      state.teams = _action?.payload?.data;
    });
    builder.addCase(getTeam.rejected, (state: any, _action: any) => {
      state.teamLoader = false;
    });
    // filterDownline ***********-***********
    builder.addCase(filterDownline.pending, (state: any, _action: any) => {
      state.teamLoader = true;
    });
    builder.addCase(filterDownline.fulfilled, (state: any, _action: any) => {
      state.teamLoader = false;
      state.teams = _action?.payload?.data;
    });
    builder.addCase(filterDownline.rejected, (state: any, _action: any) => {
      state.teamLoader = false;
    });
    // getBusiness ***********-***********
    builder.addCase(getBusiness.pending, (state: any, _action: any) => {
      state.businessLoader = true;
    });
    builder.addCase(getBusiness.fulfilled, (state: any, _action: any) => {
      state.businessLoader = false;
      state.business = _action?.payload?.data;
    });
    builder.addCase(getBusiness.rejected, (state: any, _action: any) => {
      state.businessLoader = false;
    });
    // getAdminTopRef ***********-***********
    builder.addCase(adminTopRef.pending, (state: any, _action: any) => {
      state.fetchLoader = true;
    });
    builder.addCase(adminTopRef.fulfilled, (state: any, _action: any) => {
      state.fetchLoader = false;
      state.adminTop = _action?.payload?.data;
    });
    builder.addCase(adminTopRef.rejected, (state: any, _action: any) => {
      state.fetchLoader = false;
    });
    // getStakeInfo ***********-***********
    builder.addCase(getStakeInfo.pending, (state: any, _action: any) => {
      state.fetchLoader = true;
    });
    builder.addCase(getStakeInfo.fulfilled, (state: any, _action: any) => {
      state.fetchLoader = false;
      state.stake_info = _action?.payload?.data;
    });
    builder.addCase(getStakeInfo.rejected, (state: any, _action: any) => {
      state.fetchLoader = false;
    });
    // getLeverageData ***********-***********
    builder.addCase(getLeverageData.pending, (state: any, _action: any) => {
      state.fetchLoader = true;
    });
    builder.addCase(getLeverageData.fulfilled, (state: any, _action: any) => {
      state.fetchLoader = false;
      state.leverageData = _action?.payload?.data;
    });
    builder.addCase(getLeverageData.rejected, (state: any, _action: any) => {
      state.fetchLoader = false;
    });
    // getLivePrices ***********-***********
    builder.addCase(getLivePrices.pending, (state: any, _action: any) => {
      state.fetchLoader = true;
    });
    builder.addCase(getLivePrices.fulfilled, (state: any, _action: any) => {
      state.fetchLoader = false;
      state.livePrices = _action?.payload?.data;
      state.lkdPrice =
        _action?.payload?.data?.find((price: any) => price?.name === "linkdao")
          ?.price || 0;
    });
    builder.addCase(getLivePrices.rejected, (state: any, _action: any) => {
      state.fetchLoader = false;
    });
    // getWalletHistory ***********-***********
    builder.addCase(getWalletHistory.pending, (state: any, _action: any) => {
      state.fetchLoader = true;
    });
    builder.addCase(getWalletHistory.fulfilled, (state: any, _action: any) => {
      state.fetchLoader = false;
      state.walletHistory = _action?.payload?.data;
    });
    builder.addCase(getWalletHistory.rejected, (state: any, _action: any) => {
      state.fetchLoader = false;
    });
    // getWallet ***********-***********
    builder.addCase(getWallet.pending, (state: any, _action: any) => {
      state.fetchLoader = true;
    });
    builder.addCase(getWallet.fulfilled, (state: any, _action: any) => {
      state.fetchLoader = false;
      state.wallet = _action?.payload?.data;
    });
    builder.addCase(getWallet.rejected, (state: any, _action: any) => {
      state.fetchLoader = false;
    });
    // getBalance ***********-***********
    builder.addCase(getBalance.pending, (state: any, _action: any) => {
      state.fetchLoader = true;
    });
    builder.addCase(getBalance.fulfilled, (state: any, _action: any) => {
      state.fetchLoader = false;
      state.balance = _action?.payload?.data;
    });
    builder.addCase(getBalance.rejected, (state: any, _action: any) => {
      state.fetchLoader = false;
    });
    // getStakingReward ***********-***********
    builder.addCase(getStakingReward.pending, (state: any, _action: any) => {
      state.rewardLoader = true;
    });
    builder.addCase(getStakingReward.fulfilled, (state: any, _action: any) => {
      state.rewardLoader = false;
      state.staking_reward = _action?.payload?.data;
    });
    builder.addCase(getStakingReward.rejected, (state: any, _action: any) => {
      state.rewardLoader = false;
    });
    // getDirectReferralReward ***********-***********
    builder.addCase(
      getDirectReferralReward.pending,
      (state: any, _action: any) => {
        state.rewardLoader = true;
      }
    );
    builder.addCase(
      getDirectReferralReward.fulfilled,
      (state: any, _action: any) => {
        state.rewardLoader = false;
        state.direct_reward = _action?.payload?.data;
      }
    );
    builder.addCase(
      getDirectReferralReward.rejected,
      (state: any, _action: any) => {
        state.rewardLoader = false;
      }
    );
    // getPerformanceReward ***********-***********
    builder.addCase(
      getPerformanceReward.pending,
      (state: any, _action: any) => {
        state.performanceLoader = true;
      }
    );
    builder.addCase(
      getPerformanceReward.fulfilled,
      (state: any, _action: any) => {
        state.performanceLoader = false;
        state.performance_reward = _action?.payload?.data;
      }
    );
    builder.addCase(
      getPerformanceReward.rejected,
      (state: any, _action: any) => {
        state.performanceLoader = false;
      }
    );
    // getTopReferral ***********-***********
    builder.addCase(getTopReferral.pending, (state: any, _action: any) => {
      state.rewardLoader = true;
    });
    builder.addCase(getTopReferral.fulfilled, (state: any, _action: any) => {
      state.rewardLoader = false;
      state.top_referral_reward = _action?.payload?.data;
    });
    builder.addCase(getTopReferral.rejected, (state: any, _action: any) => {
      state.rewardLoader = false;
    });
    // getPassiveReward ***********-***********
    builder.addCase(getPassiveReward.pending, (state: any, _action: any) => {
      state.passiveLoader = true;
    });
    builder.addCase(getPassiveReward.fulfilled, (state: any, _action: any) => {
      state.passiveLoader = false;
      state.passive_reward = _action?.payload?.data;
    });
    builder.addCase(getPassiveReward.rejected, (state: any, _action: any) => {
      state.passiveLoader = false;
    });

    // getVIPReward ***********-***********
    builder.addCase(getVIPReward.pending, (state: any, _action: any) => {
      state.vipLoader = true;
    });
    builder.addCase(getVIPReward.fulfilled, (state: any, _action: any) => {
      state.vipLoader = false;
      state.vip_reward = _action?.payload?.data;
    });
    builder.addCase(getVIPReward.rejected, (state: any, _action: any) => {
      state.vipLoader = false;
    });
    // getStarRoyalty ***********-***********
    builder.addCase(getStarRoyalty.pending, (state: any, _action: any) => {
      state.rewardLoader = true;
    });
    builder.addCase(getStarRoyalty.fulfilled, (state: any, _action: any) => {
      state.rewardLoader = false;
      state.star_royalty = _action?.payload?.data;
    });
    builder.addCase(getStarRoyalty.rejected, (state: any, _action: any) => {
      state.rewardLoader = false;
    });
    // getAward ***********-***********
    builder.addCase(getAward.pending, (state: any, _action: any) => {
      state.rewardLoader = true;
    });
    builder.addCase(getAward.fulfilled, (state: any, _action: any) => {
      state.rewardLoader = false;
      state.award = _action?.payload?.data;
    });
    builder.addCase(getAward.rejected, (state: any, _action: any) => {
      state.rewardLoader = false;
    });
    // updateProfile ***********-***********
    builder.addCase(updateProfile.pending, (state: any, _action: any) => {
      state.updateProfileLoader = true;
    });
    builder.addCase(updateProfile.fulfilled, (state: any, _action: any) => {
      state.updateProfileLoader = false;
    });
    builder.addCase(updateProfile.rejected, (state: any, _action: any) => {
      state.updateProfileLoader = false;
    });
    // createTicket ***********-***********
    builder.addCase(createTicket.pending, (state: any, _action: any) => {
      state.createTicketLoader = true;
    });
    builder.addCase(createTicket.fulfilled, (state: any, _action: any) => {
      state.createTicketLoader = false;
    });
    builder.addCase(createTicket.rejected, (state: any, _action: any) => {
      state.createTicketLoader = false;
    });
    // getSupportTickets ***********-***********
    builder.addCase(getSupportTickets.pending, (state: any, _action: any) => {
      state.fetchLoader = true;
    });
    builder.addCase(getSupportTickets.fulfilled, (state: any, _action: any) => {
      state.fetchLoader = false;
      state.tickets = _action?.payload?.data;
    });
    builder.addCase(getSupportTickets.rejected, (state: any, _action: any) => {
      state.fetchLoader = false;
    });
  },
});

export default userSlice.reducer;
