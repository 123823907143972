import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Progress, Row, Spinner } from "reactstrap";
import { useAccount, useContractRead } from "wagmi";
import { readContract } from "@wagmi/core";
import {
  linkdaoStakingABI,
  linkdaoTokenStakingContractAddress,
} from "../../utils/config";
import { Stake } from "../../types";
import { fromWei } from "../../utils/utils";
// import { getStakingReward } from "../../store/slices/userSlice";
import NoDataFound from "../NoDataFound";
import moment from "moment";
import ReactPaginate from "react-paginate";
import {
  getStakeInfo,
  getStakingReward,
  getStakingRewardEarning,
} from "../../store/slices/userSlice";

type Props = {};

const StakingReward = (props: Props) => {
  const dispatch = useDispatch<any>();
  const { staking_reward, rewardLoader, staking_reward_earning, stake_info } =
    useSelector((state: any) => state.user);

  // const { stakesData , stacking_reward} = useSelector((state: any) => state.user);
  const { address } = useAccount();

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const { data: totalProfit } = useContractRead({
    abi: linkdaoStakingABI,
    address: linkdaoTokenStakingContractAddress,
    functionName: "getTotalProfit",
    args: [address],
    select: (data: any) => {
      return fromWei(data);
    },
  });

  const { data: totalWithdrawableProfit } = useContractRead({
    abi: linkdaoStakingABI,
    address: linkdaoTokenStakingContractAddress,
    functionName: staking_reward.sum,
    // functionName: staking_reward.sum,
    args: [address],
    select: (data: any) => {
      return fromWei(data);
    },
  });

  const getPackageName = (amount: any) => {
    if (amount > 10 && amount < 100) {
      return "Basic";
    } else if (amount > 99 && amount < 200) {
      return "Standard";
    } else if (amount >= 200 && amount <= 499) {
      return "Super";
    } else if (amount > 499) {
      return "Premium";
    } else {
      return "NA";
    }
  };

  const handlePageClick = (event: any) => {
    setCurrentPage(event?.selected);
  };

  useEffect(() => {
    let newItems = staking_reward_earning?.totalCount / itemsPerPage;
    setPageCount(newItems);
  }, [staking_reward_earning]);

  const fetchUpdatedData = () => {
    const startIndex = currentPage * itemsPerPage;
    dispatch(
      getStakingRewardEarning({ offset: startIndex, limit: itemsPerPage })
    );
  };

  useEffect(() => {
    fetchUpdatedData();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    setCurrentPage(0);
    dispatch(getStakeInfo());
  }, []);

  return (
    <div className=" main-p carousel-top bg-site-bg">
      <Container className="earning px-0 px-md-2">
        <div className="earning__title-card">
          <div className="earning__title-card__header mt-42">
            Staking Reward
          </div>
          <div className="earning__title-card__body d-flex justify-content-between align-items-center pointer">
            <div>
              {" "}
              <h2 className="dashboard__card__heading mb-1">Total Earnings</h2>
              <h2 className="dashboard__card__value mb-0 text-site-primary number">
                $
                {rewardLoader ? (
                  <Spinner size="sm" />
                ) : (
                  staking_reward_earning?.totalEarning?.toFixed(3) || 0
                )}
              </h2>
            </div>
            <div className="text-end">
              {" "}
              <h2 className="dashboard__card__heading mb-1">Today's Earning</h2>
              <h2 className="dashboard__card__value mb-0 text-site-primary number">
                $
                {rewardLoader ? (
                  <Spinner size="sm" />
                ) : (
                  staking_reward_earning?.todayEarning?.toFixed(3) || 0
                )}
              </h2>
            </div>
          </div>
        </div>
        <Row>
          <Col md="4" className="mt-2">
            <div className="card h-100">
              <div className="card__body border-radius-all">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <h2 className="card__body-label text-site-gray me-1 mb-0">
                      Date:
                    </h2>
                  </div>
                  <h2 className="card__body-date mb-0">
                    {moment().format("DD MMM YYYY")}
                  </h2>
                </div>
                <div className="d-flex mt-1 align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <h2 className="card__body-label text-site-gray me-1 mb-0">
                      Total Reward:
                    </h2>
                  </div>
                  <div className="d-flex align-items-center">
                    <h2 className="card__body-price mb-0">
                      {" "}
                      ${staking_reward_earning?.totalEarning?.toFixed(3) || 0}
                    </h2>
                  </div>
                </div>
                <div className="d-flex mt-1 align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <h2 className="card__body-label text-site-gray me-1 mb-0">
                      Today's Reward:
                    </h2>
                  </div>
                  <div className="d-flex align-items-center">
                    <h2 className="card__body-price mb-0">
                      {" "}
                      ${staking_reward_earning?.todayEarning?.toFixed(3) || 0}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {staking_reward_earning?.data?.map((item: any, index: number) => (
            <Col key={index} md="4" className="mt-2">
              <div className="card h-100">
                <div className="card__body border-radius-all">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <h2 className="card__body-label text-site-gray me-1 mb-0">
                        Date:
                      </h2>
                      {/* <h2 className="card__body-price mb-0">
                          ({item?.stakePackage?.message})
                        </h2> */}
                    </div>
                    <h2 className="card__body-date mb-0">
                      {item?.income_date
                        ? moment.utc(item?.income_date).format("DD MMM YYYY")
                        : "NA"}
                    </h2>
                  </div>
                  <div className="d-flex mt-1 align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <h2 className="card__body-label text-site-gray me-1 mb-0">
                        Amount:
                      </h2>
                    </div>
                    <div className="d-flex align-items-center">
                      <h2 className="card__body-price mb-0">
                        {" "}
                        ${item?.income_amount?.toFixed(3)}
                      </h2>
                    </div>
                  </div>
                  {/* <div className="d-flex align-items-center mt-1">
                      <Progress
                        value={
                          ((item?.lastCheckpoint - item?.startDate) /
                            item?.lastCheckpoint) *
                          100
                        }
                        className="w-100 me-2"
                      />
                      <h2 className="card__body-price mb-0">
                        {" "}
                        {((item?.lastCheckpoint - item?.startDate) /
                          item?.lastCheckpoint) *
                          100}
                        %
                      </h2>
                    </div> */}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default StakingReward;
