import React, { useRef, useEffect, useState } from "react";
import HeaderImg from "../assets/imgs/bg-cover.jpg";
import Logo from "../assets/imgs/logo.svg";
import BSCIco from "../assets/imgs/icons/22-BNB Icon.png";
import HomeIco from "../assets/imgs/icons/HOME.png";
import AcademyIco from "../assets/imgs/icons/ACADEMY.png";
import LaunchpadIco from "../assets/imgs/icons/LAUNCHPAD.png";
import BuyIco from "../assets/imgs/icons/buy-ico.png";
import SupportIco from "../assets/imgs/icons/SUPPORT.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { useAccount, useDisconnect } from "wagmi";
import { useDispatch } from "react-redux";
import { logout } from "../store/slices/authSlice";

type Props = {};

const Header = (props: Props) => {
  const navigate = useNavigate();
  const account = useAccount();
  const location = useLocation();
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const tabsContainerRef = useRef<HTMLDivElement>(null);
  const [scrollY, setScrollY] = useState(0);

  const checkHeader = () => {
    if (location?.pathname?.includes("profile")) {
      return "secondary";
    } else if (location?.pathname?.includes("stake")) {
      return "secondary";
    } else if (location?.pathname?.includes("business")) {
      return "secondary";
    } else if (location?.pathname?.includes("team")) {
      return "secondary";
    } else if (location?.pathname?.includes("earnings")) {
      return "secondary";
    } else if (location?.pathname?.includes("gallery")) {
      return "secondary";
    } else if (location?.pathname?.includes("genealogy")) {
      return "secondary";
    } else if (location?.pathname?.includes("download")) {
      return "support";
    } else if (location?.pathname?.includes("staking-reward")) {
      return "earnings";
    } else if (location?.pathname?.includes("direct-referral-reward")) {
      return "earnings";
    } else if (location?.pathname?.includes("performance-reward")) {
      return "earnings";
    } else if (location?.pathname?.includes("top-referral-reward")) {
      return "earnings";
    } else if (location?.pathname?.includes("passive-uni-level-reward")) {
      return "earnings";
    } else if (location?.pathname?.includes("vip-uni-level-reward")) {
      return "earnings";
    } else if (location?.pathname?.includes("linkdao-star-royalty")) {
      return "earnings";
    } else if (location?.pathname?.includes("linkdao-star-reward")) {
      return "earnings";
    } else if (location?.pathname?.includes("index")) {
      return "main";
    } else if (location?.pathname?.includes("support")) {
      return "support";
    } else if (location?.pathname?.includes("statement")) {
      return "support";
    } else if (location?.pathname?.includes("academy")) {
      return "support";
    } else if (location?.pathname?.includes("audit-list")) {
      return "main";
    } else {
      return "";
    }
  };

  const checkActivePage = (name: string) => {
    if (location?.pathname?.includes(name)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {checkHeader() == "main" && (
        <div className="header d-none d-md-block">
          <div className="header__wrapper header__wrapper__nav">
            <img
              src={Logo}
              alt="logo"
              className="header__wrapper-logo"
              onClick={() => navigate("/admin/index")}
            />

            <div
              className="d-block d-lg-flex text-center align-items-center me-4 pointer"
              onClick={() => navigate("/admin/index")}
            >
              <img src={HomeIco} alt="home" height="30px" className="me-2" />
              {/* <i
                  className={`fas fa-tv me-2 ${
                    location?.pathname?.includes("index") && "text-site-primary"
                  }`}
                ></i> */}
              Home
            </div>
            <div
              className="d-block d-lg-flex text-center align-items-center me-4 pointer"
              onClick={() => navigate("/admin/academy")}
            >
              <img
                src={AcademyIco}
                alt="academy"
                height="30px"
                className="me-2"
              />
              Academy
            </div>
            <div
              className="d-block d-lg-flex text-center align-items-center me-4 pointer"
              onClick={() =>
                window?.open(
                  "https://pancakeswap.finance/swap?outputCurrency=0xaf027427dc6d31a3e7e162a710a5fe27e63e275f&inputCurrency=0x55d398326f99059fF775485246999027B3197955",
                  "_blank"
                )
              }
            >
              <img src={BuyIco} alt="wallet" height="30px" className="me-2" />
              Buy LKD
            </div>
            <div
              className="d-block d-lg-flex text-center align-items-center me-4 pointer"
              onClick={() => navigate("/admin/launchpad")}
            >
              <img
                src={LaunchpadIco}
                alt="statement"
                height="30px"
                className="me-2"
              />
              Launchpad
            </div>

            <div
              className="d-block d-lg-flex text-center align-items-center me-4 pointer"
              onClick={() => navigate("/admin/support")}
            >
              <img
                src={SupportIco}
                alt="Support"
                height="30px"
                className="me-2"
              />
              Support
            </div>
            <div className="d-flex align-items-center">
              <img src={BSCIco} alt="bsc" height="40px" className="me-2" />
              <Button className="header-mbl-content-account">
                <i className="fa fa-user-check fa-sm me-1"></i>
                {account?.address?.replace(
                  account?.address?.slice(6, 37),
                  "***"
                )}
              </Button>
              <i
                className="fa fa-power-off pointer ms-2"
                onClick={() => {
                  localStorage.clear();
                  disconnect();
                  dispatch(logout());
                }}
              ></i>
            </div>
            {/* <div className="d-flex">
            <i className="fas fa-headset fa-lg me-4 pointer"></i>
            <i className="fas fa-comment-dots fa-lg pointer"></i>
          </div> */}
          </div>
        </div>
      )}
      <div className="d-block d-md-none">
        {checkHeader() == "main" && (
          <div className="header-mbl">
            <div className="header-mbl-bg">
              <div className="header-mbl-content">
                <img
                  src={Logo}
                  alt="logo"
                  className="header-mbl-content-logo"
                />
                <div className="d-flex align-items-center">
                  <img src={BSCIco} alt="bsc" height="30px" className="me-2" />
                  <Button className="header-mbl-content-account">
                    <i className="fa fa-user-check fa-sm me-1"></i>
                    {account?.address?.replace(
                      account?.address?.slice(6, 37),
                      "***"
                    )}
                  </Button>
                  <i
                    className="fa fa-power-off pointer ms-2"
                    onClick={() => {
                      localStorage.clear();
                      disconnect();
                      dispatch(logout());
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="footer-nav">
          <div
            className={`d-flex flex-column justify-content-center align-items-center pointer ${
              location?.pathname?.includes("index") && "text-site-primary"
            }`}
            onClick={() => navigate("/admin/index")}
          >
            <img src={HomeIco} alt="home" height="30px" className="mb-1" />
            {/* <div className="footer-nav-icon mb-1"></div> */}
            <p className="mb--1">Home</p>
          </div>
          <div
            className={`d-flex flex-column justify-content-center align-items-center pointer ${
              location?.pathname?.includes("academy") && "text-site-primary"
            }`}
            onClick={() => navigate("/admin/academy")}
          >
            {/* <div className="footer-nav-icon mb-1"></div> */}
            <img
              src={AcademyIco}
              alt="academy"
              height="30px"
              className="mb-1"
            />
            <p className="mb--1">Academy</p>
          </div>
          <div
            className={`d-flex flex-column justify-content-center align-items-center`}
            onClick={() =>
              window?.open(
                "https://pancakeswap.finance/swap?outputCurrency=0xaf027427dc6d31a3e7e162a710a5fe27e63e275f&inputCurrency=0x55d398326f99059fF775485246999027B3197955",
                "_blank"
              )
            }
          >
            {/* <div className="footer-nav-icon mb-1"></div> */}
            <img src={BuyIco} alt="buy" height="30px" className="mb-1" />
            <p className="mb--1">Buy LKD</p>
          </div>
          <div
            className={`d-flex flex-column justify-content-center align-items-center pointer ${
              location?.pathname?.includes("launchpad") && "text-site-primary"
            }`}
            onClick={() => navigate("/admin/launchpad")}
          >
            {/* <div className="footer-nav-icon mb-1"></div> */}
            <img
              src={LaunchpadIco}
              alt="launchapad"
              height="30px"
              className="mb-1"
            />
            <p className="mb--1">Launchpad</p>
          </div>

          <div
            className={`d-flex flex-column justify-content-center align-items-center pointer ${
              location?.pathname?.includes("support") && "text-site-primary"
            }`}
            onClick={() => navigate("/admin/support")}
          >
            {/* <div className="footer-nav-icon mb-1"></div> */}
            <img
              src={SupportIco}
              alt="support"
              height="30px"
              className="mb-1"
            />
            <p className="mb--1">Support</p>
          </div>
        </div>
      </div>
      {checkHeader() == "secondary" && (
        <div
          style={{
            backgroundImage: `url(${HeaderImg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          className="sub-page-header"
        >
          <div className="d-flex justify-content-center">
            <div className="d-flex text-center align-items-center justify-content-center sub-page-header__wrapper w-auto">
              <div
                className="me-3 pointer"
                onClick={() => {
                  navigate("/admin/index");
                }}
              >
                <img src={HomeIco} alt="home" height="30px" />
              </div>{" "}
              <p
                className={`mb-0 me-1 me-md-2 pointer sub-page-header__item ${
                  checkActivePage("stake") && "text-white"
                }`}
                onClick={() => {
                  navigate("/admin/stake");
                }}
              >
                Stake
              </p>
              <p className="mb-0 me-1 me-md-2 text-white">|</p>
              <p
                className={`mb-0 me-1 me-md-2 pointer sub-page-header__item ${
                  checkActivePage("team") && "text-white"
                }`}
                onClick={() => {
                  navigate("/admin/team");
                }}
              >
                Team
              </p>
              <p className="mb-0 me-1 me-md-2 text-white">|</p>
              <p
                className={`mb-0 me-1 me-md-2 pointer sub-page-header__item ${
                  checkActivePage("business") && "text-white"
                }`}
                onClick={() => {
                  navigate("/admin/business");
                }}
              >
                Business
              </p>
              <p className="mb-0 me-1 me-md-2 text-white">|</p>
              <p
                className={`mb-0 me-1 me-md-2 pointer sub-page-header__item ${
                  checkActivePage("earnings") && "text-white"
                }`}
                onClick={() => {
                  navigate("/admin/earnings");
                }}
              >
                Earnings
              </p>
              <p className="mb-0 me-1 me-md-2 text-white">|</p>
              <p
                className={`mb-0 me-1 me-md-2 pointer sub-page-header__item ${
                  checkActivePage("profile") && "text-white"
                }`}
                onClick={() => {
                  navigate("/admin/profile");
                }}
              >
                Profile
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
