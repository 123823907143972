import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Title from "../../components/Title";
import HeaderImg from "../../assets/imgs/bg-cover.jpg";
import { useDispatch, useSelector } from "react-redux";
import { createTicket, getSupportTickets } from "../../store/slices/userSlice";
import moment from "moment";

type Props = {};

export interface SupportProps {
  message: string;
  subject: string;
}

const Support = (props: Props) => {
  const dispatch = useDispatch<any>();
  const { createTicketLoader, tickets } = useSelector(
    (state: any) => state.user
  );

  const [scrollY, setScrollY] = useState(0);
  const [supportData, setSupportData] = useState<SupportProps>({
    subject: "",
    message: "",
  });
  useEffect(() => {
    dispatch(getSupportTickets());

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const headerHeight = Math.max(185 - scrollY, 50); // Adjust the initial and minimum header height

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${HeaderImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className="sub-page-header"
      ></div>
      <div className="support main-p bg-site-bg">
        <Container className="px-0">
          <Title title="Support" />
          <div className="card">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                dispatch(
                  createTicket({
                    payload: supportData,
                    onSuccess: () => {
                      setSupportData({
                        subject: "",
                        message: "",
                      });
                      dispatch(getSupportTickets());
                    },
                  })
                );
              }}
            >
              <div className="card__header border-radius-top">
                <h2 className=" mb-0 card__body-heading text-center">
                  New Ticket
                </h2>
              </div>
              <div className="card__body border-radius-bottom">
                <Row>
                  <Col md="6">
                    <Label className="mb-2">Subject</Label>
                    <Input
                      placeholder="Enter Subject"
                      required
                      value={supportData?.subject}
                      onChange={(e) =>
                        setSupportData({
                          ...supportData,
                          subject: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col xs="12" className="mt-2">
                    <Label className="mb-2">Message</Label>
                    <Input
                      placeholder="Enter Message"
                      type="textarea"
                      required
                      value={supportData?.message}
                      onChange={(e) =>
                        setSupportData({
                          ...supportData,
                          message: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col xs="12" className="mt-3 text-center mb-2">
                    <Button className="btn__primary-sm" type="submit">
                      {createTicketLoader ? (
                        <Spinner size="sm" />
                      ) : (
                        "Process Ticket"
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
          {tickets?.map((ticket: any, idx: number) => (
            <div className="card mt-2" key={idx}>
              <div className="card__header border-radius-top">
                <h2 className=" mb-0 card__body-heading text-center">
                  Response
                </h2>
              </div>
              <div className="card__body border-radius-bottom">
                <div className="mt-2 d-flex align-items-center justify-content-between">
                  <h2 className="mb-0 support-content">Date</h2>
                  <h2 className="mb-0 support-content">
                    {ticket?.adate
                      ? moment(ticket?.adate).format("DD MMM YYYY")
                      : "N/A"}
                  </h2>
                </div>
                <div className="mt-2 d-flex align-items-center justify-content-between">
                  <h2 className="mb-0 support-content me-5">Subject</h2>
                  <h2 className="mb-0 support-content text-end">
                    {ticket?.user_subject ? ticket?.user_subject : "N/A"}
                  </h2>
                </div>
                <div className="mt-2 d-flex align-items-center justify-content-between">
                  <h2 className="mb-0 support-content me-5">Message</h2>
                  <h2 className="mb-0 support-content text-end">
                    {" "}
                    {ticket?.user_message ? ticket?.user_message : "N/A"}
                  </h2>
                </div>
                <div className="mt-2 d-flex align-items-center justify-content-between">
                  <h2 className="mb-0 support-content">Status</h2>
                  <h2 className="mb-0 support-content">
                    {" "}
                    {ticket?.status ? ticket?.status : "N/A"}
                  </h2>
                </div>
                <div className="mt-2 d-flex align-items-center justify-content-between">
                  <h2 className="mb-0 support-content">Date of Response</h2>
                  <h2 className="mb-0 support-content">
                    {" "}
                    {ticket?.rdate
                      ? moment(ticket?.rdate).format("DD MMM YYYY")
                      : "N/A"}
                  </h2>
                </div>
                <div className="mt-2 d-flex align-items-center justify-content-between">
                  <h2 className="mb-0 support-content">Response</h2>
                  <h2 className="mb-0 support-content">
                    {" "}
                    {ticket?.reply ? ticket?.reply : "N/A"}
                  </h2>
                </div>
              </div>
            </div>
          ))}
        </Container>
      </div>
    </>
  );
};

export default Support;
