import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import Title from "../../components/Title";
import DollarIco from "../../assets/imgs/icons/19-$ Icon.png";
import HeaderImg from "../../assets/imgs/bg-cover.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getStatement } from "../../store/slices/userSlice";
import moment from "moment";
import NoDataFound from "../../components/NoDataFound";

type Props = {};

const Statement = (props: Props) => {
  const dispatch = useDispatch<any>();
  const { statement, fetchLoader } = useSelector((state: any) => state.user);
  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    dispatch(getStatement());
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${HeaderImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className="sub-page-header"
      ></div>
      <div className="statement main-p">
        <Container className="px-0 history">
          <div className="d-flex align-items-center history-tabs">
            <Button className={` history-tab text-nowrap`} block>
              Daily Total Earning
            </Button>
          </div>
          <Row className="mt-1">
            {statement?.todayIncomeList?.length > 0 ? (
              statement?.todayIncomeList?.map((state: any, idx: number) => {
                return (
                  <Col md="4" key={idx} className="mt-2">
                    <div className="card">
                      <div className="card__body border-radius-all">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="mb-0 filter__card-label">
                            Total Amount
                          </p>
                          <h6 className="mb-0  filter__card-label  text-site-primary">
                            {" "}
                            $
                            {fetchLoader ? (
                              <Spinner size="sm" />
                            ) : state?.user_income_income_amount ? (
                              Number(state?.user_income_income_amount)?.toFixed(
                                3
                              )
                            ) : (
                              0
                            )}
                          </h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="mb-0 filter__card-label">Date</p>
                          <h6 className="mb-0  filter__card-label text-site-primary">
                            {fetchLoader ? (
                              <Spinner size="sm" />
                            ) : state?.user_income_income_date ? (
                              moment(state.user_income_income_date).format(
                                "DD MMM YYYY"
                              )
                            ) : (
                              0
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <NoDataFound isLoading={fetchLoader} />
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Statement;
