import React from "react";
import { Col, Container, Row } from "reactstrap";
import Title from "./Title";
import { useNavigate } from "react-router-dom";
import ArrowIco from "../assets/imgs/icons/20-Arrow Icon.png";
import { useSelector } from "react-redux";

type Props = {};

const Earnings = (props: Props) => {
  const navigate = useNavigate();
  const { earnings } = useSelector((state: any) => state.user);
  return (
    <div className="earnings main-p carousel-top bg-site-bg">
      <Container className="px-0 px-md-2">
        <Title title="Earnings Statistics" />
        <div className="small-card d-flex justify-content-between align-items-center pointer">
          <div>
            {" "}
            <h2 className="dashboard__card__heading mb-1 ">Total Earning</h2>
            <h2 className="dashboard__card__value mb-0 number">
              $
              {earnings?.totalIncome
                ? Number(earnings?.totalIncome)?.toFixed(3)
                : 0}
            </h2>
          </div>
          <div className="text-end">
            <h2 className="dashboard__card__heading  mb-1">
              Today's Total Earning
            </h2>
            <h2 className="dashboard__card__value mb-0 number">
              $
              {earnings?.todayIncome
                ? Number(earnings?.todayIncome)?.toFixed(3)
                : 0}
            </h2>
          </div>
        </div>
        <Row>
          <Col md="4" className="mt-2">
            <div
              className="small-card d-flex justify-content-between align-items-center pointer"
              onClick={() => {
                navigate("/admin/earning-details?comp=staking-reward");
              }}
            >
              <div>
                {" "}
                <h2 className="dashboard__card__heading mb-1">
                  Staking Reward
                </h2>
                <h2 className="dashboard__card__value mb-0 number">
                  $
                  {earnings?.stacking_rewards
                    ? Number(earnings?.stacking_rewards)?.toFixed(3)
                    : 0}
                </h2>
              </div>
              <img src={ArrowIco} alt="arrow" height="35px" />
            </div>
          </Col>
          <Col md="4" className="mt-2">
            <div
              className="small-card d-flex justify-content-between align-items-center pointer"
              onClick={() => {
                navigate("/admin/earning-details?comp=direct-referral");
              }}
            >
              <div>
                {" "}
                <h2 className="dashboard__card__heading mb-1">
                  Direct Referral Reward
                </h2>
                <h2 className="dashboard__card__value mb-0 number">
                  $
                  {earnings?.direct_referal_rewards
                    ? Number(earnings?.direct_referal_rewards)?.toFixed(3)
                    : 0}
                </h2>
              </div>
              <img src={ArrowIco} alt="arrow" height="35px" />
            </div>
          </Col>
          <Col md="4" className="mt-2">
            <div
              className="small-card d-flex justify-content-between align-items-center pointer"
              onClick={() => {
                navigate("/admin/earning-details?comp=performance-reward");
              }}
            >
              <div>
                {" "}
                <h2 className="dashboard__card__heading mb-1">
                  Performance Reward
                </h2>
                <h2 className="dashboard__card__value mb-0 number">
                  $
                  {earnings?.Performance_Award
                    ? Number(earnings?.Performance_Award)?.toFixed(3)
                    : 0}
                </h2>
              </div>
              <img src={ArrowIco} alt="arrow" height="35px" />
            </div>
          </Col>
          <Col md="4" className="mt-2">
            <div
              className="small-card d-flex justify-content-between align-items-center pointer"
              onClick={() => {
                navigate("/admin/earning-details?comp=top-referral");
              }}
            >
              <div>
                {" "}
                <h2 className="dashboard__card__heading mb-1">
                  Top Referral Reward
                </h2>
                <h2 className="dashboard__card__value mb-0 number">
                  $
                  {earnings?.Top_Referral_Club
                    ? Number(earnings?.Top_Referral_Club)?.toFixed(3)
                    : 0}
                </h2>
              </div>
              <img src={ArrowIco} alt="arrow" height="35px" />
            </div>
          </Col>
          <Col md="4" className="mt-2 ">
            <div
              className="small-card d-flex justify-content-between align-items-center pointer"
              onClick={() => {
                navigate("/admin/earning-details?comp=passive-uni-level");
              }}
            >
              <div>
                {" "}
                <h2 className="dashboard__card__heading mb-1">
                  Passive Uni-Level Reward
                </h2>
                <h2 className="dashboard__card__value mb-0 number">
                  $
                  {earnings?.Passive_Unilevel_Reward
                    ? Number(earnings?.Passive_Unilevel_Reward)?.toFixed(3)
                    : 0}
                </h2>
              </div>
              <img src={ArrowIco} alt="arrow" height="35px" />
            </div>
          </Col>
          <Col md="4" className="mt-2">
            <div
              className="small-card d-flex justify-content-between align-items-center"
              onClick={() => {
                navigate("/admin/earning-details?comp=vip-uni-level");
              }}
            >
              <div>
                {" "}
                <h2 className="dashboard__card__heading mb-1">
                  VIP Uni-Level Reward
                </h2>
                <h2 className="dashboard__card__value mb-0 number">
                  $
                  {earnings?.VIP_Unilevel_Reward
                    ? Number(earnings?.VIP_Unilevel_Reward)?.toFixed(3)
                    : 0}
                </h2>
              </div>
              <img src={ArrowIco} alt="arrow" height="35px" />
            </div>
          </Col>
          <Col md="4" className="mt-2">
            <div
              className="small-card d-flex justify-content-between align-items-center pointer"
              onClick={() => {
                navigate("/admin/earning-details?comp=star-royalty");
              }}
            >
              <div>
                {" "}
                <h2 className="dashboard__card__heading mb-1">
                  LinkDao Star Royalty
                </h2>
                <h2 className="dashboard__card__value mb-0 number">
                  $
                  {earnings?.Royalty
                    ? Number(earnings?.Royalty)?.toFixed(3)
                    : 0}
                </h2>
              </div>
              <img src={ArrowIco} alt="arrow" height="35px" />
            </div>
          </Col>
          <Col md="4" className="mt-2">
            <div
              className="small-card d-flex justify-content-between align-items-center"
              onClick={() => {
                navigate("/admin/earning-details?comp=star-reward");
              }}
            >
              <div>
                {" "}
                <h2 className="dashboard__card__heading mb-1">
                  LinkDao Star Award
                </h2>
                <h2 className="dashboard__card__value mb-0 number">
                  ${earnings?.Award ? Number(earnings?.Award)?.toFixed(3) : 0}
                </h2>
              </div>
              <img src={ArrowIco} alt="arrow" height="35px" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Earnings;
