import React, { useEffect } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import Title from "../../components/Title";
import DollarIco from "../../assets/imgs/icons/19-$ Icon.png";
import HeaderImg from "../../assets/imgs/bg-cover.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getStatement } from "../../store/slices/userSlice";
import { getAllDownloads } from "../../store/slices/downloadSlice";
import NoDataFound from "../../components/NoDataFound";

type Props = {};

const Download = (props: Props) => {
  const dispatch = useDispatch<any>();
  const { downloads, fetchDownloadLoader } = useSelector(
    (state: any) => state.download
  );

  useEffect(() => {
    dispatch(getAllDownloads());
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${HeaderImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className="sub-page-header"
      ></div>
      <div className="statement main-p">
        <Container className="px-0">
          <Title title="Download" />
          <Row className="">
            {downloads?.length > 0 ? (
              downloads?.map((download: any, idx: number) => (
                <Col md="3" xs="12" className="mt-2" key={idx}>
                  <div className="download__card">
                    <div className="download__card-header">
                      {download?.title}
                    </div>
                    <div className="download__card-body"></div>
                  </div>
                </Col>
              ))
            ) : (
              <NoDataFound isLoading={fetchDownloadLoader} />
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Download;
