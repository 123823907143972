import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthLanding from "./pages/Auth/AuthLanding";
import Register from "./pages/Auth/Register";
import Dashboard from "./pages/Dashboard";
import AuthLayout from "./layout/Auth";
import AdminLayout from "./layout/Admin";
import Wallet from "./pages/Dashboard/Wallet";
import Academy from "./pages/Dashboard/Academy";
import Statement from "./pages/Dashboard/Statement";
import Support from "./pages/Dashboard/Support";
import EarningsDetails from "./pages/Dashboard/EarningsDetails";
import SubPages from "./pages/Dashboard/SubPages";
import { useAccount, useDisconnect } from "wagmi";
import AuditList from "./components/AuditList";
import BonanzaPages from "./pages/Dashboard/BonanzaPages";
import Download from "./pages/Dashboard/Download";
import Launchpad from "./pages/Dashboard/Launchpad";

function App() {
  document.documentElement.style.setProperty(
    "--bs-font-sans-serif",
    '"Public Sans" , -apple-system , BlinkMacSystemFont , "Segoe UI" , "Oxygen" , "Ubuntu" , "Cantarell" , "Fira Sans" , "Droid Sans" , "Helvetica Neue" , sans-serif'
  );
  document.documentElement.style.setProperty("--bs-body-font-weight", "500");
  document.documentElement.style.setProperty("--bs-btn-font-weight", "500");

  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  return (
    <div className="App">
      <Routes>
        <Route path="/*" element={<AuthLayout />}>
          <Route index element={<AuthLanding />} />
          <Route path="register" element={<Register />} />
        </Route>
        <Route path="/admin/*" element={<AdminLayout />}>
          <Route path="index" element={<Dashboard />} />
          <Route path="statement" element={<Statement />} />
          <Route path="launchpad" element={<Launchpad />} />
          <Route path="support" element={<Support />} />
          <Route path="wallet" element={<Wallet />} />
          {/* <Route path="bonanza" element={<BonanzaPages />} /> */}
          <Route path="download" element={<Download />} />
          <Route path="academy" element={<Academy />} />
          <Route path="earning-details" element={<EarningsDetails />} />
          <Route path="sub-pages" element={<SubPages />} />
          <Route path="audit-list" element={<AuditList />} />
          <Route path="*" element={<Navigate to="/admin/index" replace />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
