import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/scss/custom.scss";
import { BrowserRouter } from "react-router-dom";
import { WagmiConfig } from "wagmi";
import { PROJECT_ID, ethereumClient, wagmiConfig } from "./utils/config";
import { Web3Modal } from "@web3modal/react";
import { Provider } from "react-redux";
import { store } from "./store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CookiesProvider } from "react-cookie";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <BrowserRouter>
          <WagmiConfig config={wagmiConfig}>
            <ToastContainer />
            <App />
            <Web3Modal
              themeVariables={{
                "--w3m-accent-color": "#00e3c9",
                "--w3m-background-color": "#00e3c9",
                "--w3m-accent-fill-color": "#fff",
              }}
              projectId={PROJECT_ID}
              ethereumClient={ethereumClient}
            />
          </WagmiConfig>
        </BrowserRouter>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
