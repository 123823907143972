import React from "react";
import { Col, Container, Row, Spinner } from "reactstrap";

type Props = {
  isLoading: boolean;
};

const NoDataFound = (props: Props) => {
  return (
    <Container className="mt-2">
      <Row className="justify-content-center">
        <Col md="4" className="text-center">
          <div className="card">
            <div className="card__body border-radius-all text-site-primary">
              {props?.isLoading ? (
                <>
                  <Spinner size="sm" />
                  <h2 className="mb-0 filter__card-label">Please wait...</h2>
                </>
              ) : (
                <h2 className="mb-0 filter__card-label">No Data Found</h2>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NoDataFound;
