import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Spinner } from "reactstrap";
import { getAward } from "../../store/slices/userSlice";
import NoDataFound from "../NoDataFound";
import moment from "moment";

type Props = {};

const LinkDaoStarReward = (props: Props) => {
  const dispatch = useDispatch<any>();
  const { award, rewardLoader } = useSelector((state: any) => state.user);

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (event: any) => {
    setCurrentPage(event?.selected);
  };

  // console.log(award);

  useEffect(() => {
    setPageCount(0);
    dispatch(getAward({ offset: 0, limit: 20 }));
  }, []);

  return (
    <div className=" main-p carousel-top bg-site-bg">
      <Container className="px-0 px-md-2">
        <div className="earning__title-card">
          <div className="earning__title-card__header mt-42">
            LinkDao Star Award
          </div>
          <div className="earning__title-card__body d-flex justify-content-between align-items-center pointer">
            <div>
              {" "}
              <h2 className="dashboard__card__heading mb-1">Total Earning</h2>
              <h2 className="dashboard__card__value mb-0 text-site-primary number">
                $
                {rewardLoader ? (
                  <Spinner size="sm" />
                ) : award?.totalEarning ? (
                  Number(award?.totalEarning)?.toFixed(3)
                ) : (
                  0
                )}
              </h2>
            </div>
            <div className="text-end">
              {" "}
              <h2 className="dashboard__card__heading mb-1">Today's Earning</h2>
              <h2 className="dashboard__card__value mb-0 text-site-primary number">
                $
                {rewardLoader ? (
                  <Spinner size="sm" />
                ) : award?.todayEarning ? (
                  Number(award?.todayEarning)?.toFixed(3)
                ) : (
                  0
                )}
              </h2>
            </div>
          </div>
        </div>
        <Row>
          {award?.userRoyality?.length > 0 ? (
            award?.userRoyality?.map((item: any, idx: number) => (
              <Col md="4" className="mt-2" key={idx}>
                <div className="small-card h-100">
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="performance-value mb-0">
                      Linkdao {item?.name || 0}{" "}
                    </h2>
                    <h2 className="performance-value mb-0">
                      DOA:{" "}
                      {item?.ach_date
                        ? moment(item?.ach_date).format("DD MMM YYYY")
                        : "NA"}{" "}
                    </h2>
                  </div>
                  <div className="d-flex mt-1 align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <h2 className="performance-value mb-0">
                        Amount of Award
                      </h2>
                    </div>
                    <h2 className="performance-value mb-0 ">
                      ${item?.awards?.toFixed(3) || 0}
                    </h2>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <NoDataFound isLoading={rewardLoader} />
          )}
        </Row>
      </Container>
    </div>
  );
};

export default LinkDaoStarReward;
