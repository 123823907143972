import React from 'react'
import Header from './Header'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import {BiCalendar} from 'react-icons/bi'
import AuditCard from './AuditCard'

function AuditList() {
  return (
    <div className='audit-list'>
      <Container>
        <Row className='mt-4 mb-5'>
        <Col xs='12' lg='4' className='mb-3'>

          <AuditCard title='LinkDao' type='Linkdao_Token' date='2022-05-05' d1='1' d2='0' d3='0' d4='0' d5='0' imageUrl='https://dynamic-audit.blocksafu.com/images/logo/63b4f36d0c1751672803181.png' address='0xaF027427DC6d31A3e7e162A710a5Fe27e63E275F'/>
          </Col>
          <Col xs='12' lg='4' className='mb-3'>

          <AuditCard title='Linkdao - Staking' type='Linkdao_Defi' date='2023-05-20' d1='2' d2='0' d3='0' d4='0' d5='1' imageUrl='https://dynamic-audit.blocksafu.com/images/logo/6469ce8dea3371684655757.jpeg' address='0xaB289Db90A149A0a3257Cab7c996025D8D3b9598'/>
          </Col>
         
        </Row>
      </Container>
    </div>
  )
}

export default AuditList
