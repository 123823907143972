import React, { useEffect, useRef, useState } from "react";
import Llogo from "../../assets/imgs/landing-logo.png";
import NameLogo from "../../assets/imgs/name logo.png";
import { Button, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";
import { login } from "../../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useWeb3Modal } from "@web3modal/react";
import { bsc } from "viem/chains";
import Sheet from "react-modal-sheet";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import { getAllFaqs } from "../../store/slices/faqSlice";
import { getAllAnnoucements } from "../../store/slices/annoucementSlice";
import { getAllBanners } from "../../store/slices/bannerSlice";

const AuthLanding = () => {
  const navigate = useNavigate();
  const { disconnect } = useDisconnect();
  const { token } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [cookies, setCookie] = useCookies(["googtrans"]);
  const [isOpen, setOpen] = useState(false);
  const [pageLang, setPageLang] = useState("");
  const [lang, setLang] = useState("English");

  const { authLoading } = useSelector((state: any) => state.auth);
  const { open: connect, setDefaultChain } = useWeb3Modal();
  const { address, isConnected } = useAccount();

  const { signMessage } = useSignMessage({
    message: "this is signature message for linkdao",
    onSuccess: (res: any) => {
      dispatch(
        login({
          creds: { address: address, signature: res },
          onSuccess: () => {},
          onError: () => {
            disconnect();
            navigate("/register");
          },
        })
      );
    },
    onError: (err: any) => {
      Swal.fire({
        text: err?.message || "Something went wrong!",
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
    },
  });

  const account = useAccount({
    onConnect({ address }) {
      setDefaultChain(bsc);
      if (!token) {
        // console.log(address);
        // signMessageAsync()
        //   .then((res: string) => {
        //     // console.log(res);
        //     dispatch(
        //       login({
        //         creds: { address: address, signature: res },
        //         onSuccess: () => {},
        //         onError: () => {
        //           disconnect();
        //           navigate("/register");
        //         },
        //       })
        //     );
        //   })
        //   .catch((err: any) => {
        //     console.log(err);
        //     disconnect();
        //   });
      }
    },
  });

  const handlePageLangChange = (e: string) => {
    if (cookies?.googtrans) {
      setCookie("googtrans", decodeURI(e));
      setPageLang(e);
    } else {
      setCookie("googtrans", e);
      setPageLang(e);
    }
    window.location.reload();
  };

  const googleTranslateElementInit = () => {
    new (window as any).google.translate.TranslateElement(
      {
        pageLanguage: pageLang,
        includedLanguages: "en,ru,hi,ne,bn,fr,th",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    if (cookies?.googtrans) {
      setPageLang(cookies?.googtrans);
    } else {
      setPageLang("/auto/en");
    }
    const addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    (window as any).googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  useEffect(() => {
    dispatch(getAllAnnoucements());
    dispatch(getAllBanners());
    dispatch(getAllFaqs());
    if (!account?.isConnected) {
      localStorage.clear();
      connect();
    }
  }, []);

  return (
    <div className="auth">
      <div
        id="google_translate_element"
        style={{
          width: "0px",
          height: "0px",
          position: "absolute",
          left: "50%",
          zIndex: -99999,
          display: "none",
        }}
      ></div>
      <div className="lang-btn-wraper">
        <Button
          onClick={() => setOpen(!isOpen)}
          className="lang-btn"
          translate="no"
        >
          <div
            className="d-flex align-items-center gap-1"
            style={{
              color: "#fff !important",
            }}
          >
            <i className="fa-solid fa-globe fa-2x d-block"></i>
            <h6 className="m-0">{pageLang.slice(-2).toUpperCase()}</h6>
            <i className="fa-solid fa-caret-down d-block"></i>
          </div>
          {/* <i className="fa-solid fa-globe"></i>{" "}
          {pageLang.slice(-2).toUpperCase()}{" "}
          <i className="fa-solid fa-caret-down"></i> */}
          {/* {lang} <i className="fa-solid fa-caret-down"></i> */}
        </Button>
      </div>
      <div className="auth__wrapper text-center">
        <div className="auth__wrapper-logo">
          <img src={NameLogo} alt="logo" className="img-fluid" />
        </div>

        <Button
          block
          onClick={() => {
            if (isConnected) {
              signMessage();
            } else {
              connect();
            }
          }}
          disabled={authLoading}
          className="mt-8 mt-md-6 auth__wrapper__btn-filled"
        >
          {authLoading ? <Spinner size="sm" /> : isConnected ? "Sign" : "Login"}
        </Button>
        <Button
          onClick={() => navigate("/register")}
          block
          className="register-btn auth__wrapper__btn-outlined"
        >
          Register
        </Button>
      </div>
      <Sheet
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        detent="content-height"
      >
        <Sheet.Container>
          <Sheet.Header className="text-end mb-0">
            <Button size="sm" className="me-2" onClick={() => setOpen(false)}>
              <i className="fa fa-times fa-2x"></i>
            </Button>
          </Sheet.Header>
          <Sheet.Content className="text-center lang-wrapper">
            <Button
              className={` ${
                pageLang == "/auto/en" ? "btn__lang-active" : "btn__lang"
              } btn-primary`}
              block
              onClick={() => {
                handlePageLangChange("/auto/en");
                setLang("English");
              }}
            >
              English
            </Button>
            <Button
              className={` ${
                pageLang == "/auto/hi" ? "btn__lang-active" : "btn__lang"
              } mt-2`}
              block
              onClick={() => {
                handlePageLangChange("/auto/hi");
                setLang("हिंदी");
              }}
            >
              हिंदी
            </Button>
            <Button
              className={` ${
                pageLang == "/auto/ne" ? "btn__lang-active" : "btn__lang"
              } mt-2`}
              block
              onClick={() => {
                handlePageLangChange("/auto/ne");
                setLang("नेपाली");
              }}
            >
              नेपाली
            </Button>
            <Button
              className={` ${
                pageLang == "/auto/ru" ? "btn__lang-active" : "btn__lang"
              } mt-2`}
              block
              onClick={() => {
                handlePageLangChange("/auto/ru");
                setLang("Russian");
              }}
            >
              Russian
            </Button>
            <Button
              className={` ${
                pageLang == "/auto/bn" ? "btn__lang-active" : "btn__lang"
              } mt-2`}
              block
              onClick={() => {
                handlePageLangChange("/auto/bn");
                setLang("Bangladeshi");
              }}
            >
              Bangladeshi
            </Button>
            <Button
              className={` ${
                pageLang == "/auto/fr" ? "btn__lang-active" : "btn__lang"
              } mt-2`}
              block
              onClick={() => {
                setLang("French");
                handlePageLangChange("/auto/fr");
              }}
            >
              French
            </Button>
            <Button
              className={` ${
                pageLang == "/auto/th" ? "btn__lang-active" : "btn__lang"
              } mt-2`}
              block
              onClick={() => {
                setLang("Thai");
                handlePageLangChange("/auto/th");
              }}
            >
              Thai
            </Button>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </div>
  );
};
export default AuthLanding;
