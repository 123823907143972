// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import React from "react";
import Banner1 from "../assets/imgs/banner1.jpeg";
import Banner2 from "../assets/imgs/banner2.jpeg";
import { useSelector } from "react-redux";

type Props = {};

const DashboardBanner = (props: Props) => {
  const { banners } = useSelector((state: any) => state.banner);

  return (
    <Swiper
      className="text-center mt-2 mt-md-0"
      modules={[Pagination, Autoplay]}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
    >
      {banners?.map((banner: any, idx: number) => (
        <SwiperSlide key={idx}>
          {banner?.image ? (
            <img src={banner?.image} alt="banner" className="img-fluid" />
          ) : (
            <img src={Banner1} alt="banner" className="img-fluid" />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default DashboardBanner;
