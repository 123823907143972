import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getVIPReward } from "../../store/slices/userSlice";
import NoDataFound from "../NoDataFound";
import moment from "moment";
import ReactPaginate from "react-paginate";

type Props = {};

const VIPUniLevelReward = (props: Props) => {
  const dispatch = useDispatch<any>();
  const { vip_reward, vipLoader } = useSelector((state: any) => state.user);

  const [isFiltered, setIsFiltered] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(14);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterVIP, setFilterVIP] = useState({
    level: "",
  });

  const handlePageClick = (event: any) => {
    setCurrentPage(event?.selected);
  };

  useEffect(() => {
    let newItems = vip_reward?.totalCount / itemsPerPage;
    setPageCount(newItems);
  }, [vip_reward]);

  const fetchUpdatedData = () => {
    const startIndex = currentPage * itemsPerPage;
    dispatch(getVIPReward({ offset: startIndex, limit: itemsPerPage }));
  };

  useEffect(() => {
    fetchUpdatedData();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    setPageCount(0);
  }, []);

  console.log(vip_reward);

  return (
    <div className=" main-p carousel-top bg-site-bg">
      <Container className="px-0 px-md-2">
        <div className="earning__title-card">
          <div className="earning__title-card__header mt-42">
            VIP Uni-Level Reward
          </div>
          <div className="earning__title-card__body d-flex justify-content-between align-items-center pointer">
            <div>
              {" "}
              <h2 className="dashboard__card__heading mb-1">Total Earning</h2>
              <h2 className="dashboard__card__value mb-0 text-site-primary number">
                $
                {vipLoader ? (
                  <Spinner size="sm" />
                ) : vip_reward?.totalEarning ? (
                  vip_reward?.totalEarning?.toFixed(3)
                ) : (
                  0
                )}
              </h2>
            </div>
            <div className="text-end">
              {" "}
              <h2 className="dashboard__card__heading mb-1">Today's Earning</h2>
              <h2 className="dashboard__card__value mb-0 text-site-primary number">
                $
                {vipLoader ? (
                  <Spinner size="sm" />
                ) : vip_reward?.todayEarning ? (
                  vip_reward?.todayEarning?.toFixed(3)
                ) : (
                  0
                )}
              </h2>
            </div>
          </div>
        </div>
        {/* <div className="card mt-2">
          <div className="card__header border-radius-top">
            <h2 className="card__body-heading mb-0 text-center">
              Downline Search
            </h2>
          </div>
          <div className="card__body border-radius-bottom">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                dispatch(
                  getVIPReward({
                    offset: 0,
                    limit: itemsPerPage,
                    level: Number(filterVIP?.level),
                  })
                );
                setIsFiltered(true);
              }}
            >
              <Row className="align-items-end">
                <Col xs="4" md="6">
                  <Label>Compressed Level</Label>
                  <Input
                    placeholder="Select Level"
                    type="select"
                    value={filterVIP?.level}
                    onChange={(e: any) => {
                      setFilterVIP({ ...filterVIP, level: e.target.value });
                    }}
                  >
                    <option value="" selected disabled>
                      Select Level
                    </option>
                    {new Array(150).fill(1)?.map((opt, idx) => (
                      <option value={idx + 1} key={idx}>
                        Level {idx + 1}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col xs="8" md="6">
                  <Button
                    disabled={!filterVIP?.level}
                    className="btn__primary-sm me-0 me-md-2"
                    type="submit"
                    style={{
                      minHeight: "38px",
                    }}
                  >
                    Search Now
                  </Button>
                  {filterVIP?.level ? (
                    <Button
                      className="btn__clear ms-3 ms-md-0"
                      onClick={() => {
                        setFilterVIP({
                          level: "",
                        });
                        setIsFiltered(false);
                        dispatch(
                          getVIPReward({ offset: 0, limit: itemsPerPage })
                        );
                      }}
                      style={{
                        minHeight: "38px",
                      }}
                    >
                      Clear
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
                {isFiltered && (
                  <Col xs="12" className="mt-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <Label>Staking Reward at CL {filterVIP?.level}</Label>
                      <Label>
                        $
                        {vipLoader ? (
                          <Spinner size="sm" />
                        ) : (
                          vip_reward?.totalBusiness?.toFixed(3) || 0
                        )}{" "}
                      </Label>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <Label>Today's Passive Uni-Level Reward</Label>
                      <Label>
                        $
                        {vipLoader ? (
                          <Spinner size="sm" />
                        ) : (
                          vip_reward?.totalEarning?.toFixed(3) || 0
                        )}{" "}
                      </Label>
                    </div>
                  </Col>
                )}
              </Row>
            </Form>
          </div>
        </div> */}
        <Row>
          {vip_reward?.data?.length > 0 ? (
            <>
              {vip_reward?.data?.map((item: any, idx: number) => (
                <Col md="4" className="mt-2" key={idx}>
                  <div className="card h-100">
                    <div className="card__body border-radius-all">
                      {/* <div className="d-flex align-items-center justify-content-between mt-2">
                        <h2 className="earning-label mb-1">Date</h2>
                        <h2 className="earning-value mb-1">
                          {item?.income_date
                            ? moment(item?.income_date).format("YYYY-DD-MM")
                            : "NA"}
                        </h2>
                      </div> */}
                      <div className="d-flex align-items-center justify-content-between">
                        <h2 className="earning-label mb-1">Level</h2>
                        <h2 className="earning-value mb-1">
                          {/* {item?.user_user_lid || "NA"} /  */}
                          L-
                          {item?.level}
                        </h2>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <h2 className="earning-label mb-0">Total Reward</h2>
                        <h2 className="card__body-label earning-value mb-0">
                          ${item?.amount?.toFixed(3) || 0}
                        </h2>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <h2 className="earning-label mb-0">Today Reward</h2>
                        <h2 className="card__body-label earning-value mb-0">
                          ${item?.todayAmount?.toFixed(3) || 0}
                        </h2>
                      </div>
                      {/* <div className="d-flex align-items-center justify-content-between">
                        <h2 className="earning-label mb-0">Amount of Reward</h2>
                        <h2 className="earning-value  mb-0">
                          ${Number(item?.total_amount)?.toFixed(3) || 0}
                        </h2>
                      </div> */}
                    </div>
                  </div>
                </Col>
              ))}
              {/* <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <i className="fas fa-angle-right text-site-primary" />
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel={
                  <i className="fas fa-angle-left text-site-primary" />
                }
                containerClassName="custom-pagination"
                activeClassName="custom-pagination-active"
                previousClassName="custom-pagination-prev"
                nextClassName="custom-pagination-next"
                pageClassName="custom-pagination-li"
                disabledClassName="custom-pagination-disabled"
              /> */}
            </>
          ) : (
            <NoDataFound isLoading={vipLoader} />
          )}
        </Row>
      </Container>
    </div>
  );
};

export default VIPUniLevelReward;
