import React, { useState } from "react";
import { Button, Col, Container, Form, Input, Row, Spinner } from "reactstrap";
import Title from "../Title";
import DollarIco from "../../assets/imgs/icons/19-$ Icon.png";
import { useDispatch, useSelector } from "react-redux";
import SpinnerImg from "../../assets/imgs/spinner.svg";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import Swal from "sweetalert2";
import {
  getWallet,
  getWalletHistory,
  reBuyLeverage,
} from "../../store/slices/userSlice";

type Props = {};

const ReBuyLeverage = (props: Props) => {
  const dispatch = useDispatch<any>();
  const { wallet, lkdPrice, reBuyLeverageLoader, fetchLoader, leverageData } =
    useSelector((state: any) => state.user);

  const [buyLeverage, setReBuyLeverage] = useState(0);

  return (
    <LoadingOverlayWrapper
      active={reBuyLeverageLoader}
      text="Please Wait"
      spinner={<img src={SpinnerImg} alt="spinner" />}
    >
      <div className="main-p carousel-top bg-site-bg">
        <Container className="px-0">
          <Title title="Re-Buy Leverage" />
          <Row className="mt-2">
            <Col md="6">
              <div className="small-card-ns d-flex justify-content-between align-items-center pointer">
                <div>
                  {" "}
                  <h2 className="dashboard__card__heading mb-1">
                    Total Leverage
                  </h2>
                  <h2 className="dashboard__card__value mb-0">
                    $
                    {fetchLoader ? (
                      <Spinner size="sm" />
                    ) : leverageData?.mlimit ? (
                      Number(leverageData?.mlimit)?.toFixed(3)
                    ) : (
                      "0.000"
                    )}
                  </h2>
                </div>
                <img src={DollarIco} alt="dollar" height="35px" />
              </div>
            </Col>
            <Col md="6" className="mt-2 mt-md-0">
              <div className="small-card-ns d-flex justify-content-between align-items-center pointer">
                <div>
                  {" "}
                  <h2 className="dashboard__card__heading mb-1">
                    Leverage Used
                  </h2>
                  <h2 className="dashboard__card__value mb-0">
                    $
                    {fetchLoader ? (
                      <Spinner size="sm" />
                    ) : leverageData?.income ? (
                      Number(leverageData?.income)?.toFixed(3)
                    ) : (
                      "0.000"
                    )}
                  </h2>
                </div>
                <img src={DollarIco} alt="dollar" height="35px" />
              </div>
            </Col>
            <Col xs="12" className="mt-2">
              <div className="small-card-ns d-flex justify-content-between align-items-center pointer">
                <div>
                  {" "}
                  <h2 className="dashboard__card__heading mb-1">
                    Leverage Wallet Balance
                  </h2>
                  <h2 className="dashboard__card__value mb-0">
                    $
                    {wallet?.leverage
                      ? Number(wallet?.leverage)?.toFixed(3)
                      : 0}
                  </h2>
                </div>
                <img src={DollarIco} alt="dollar" height="35px" />
              </div>
            </Col>
            {/* <Col md="6" className="mt-2 mt-md-0">
              <div className="small-card-ns pointer">
                {" "}
                <h2 className="dashboard__card__heading mb-1">
                  My DApp Wallet Balance
                </h2>
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="dashboard__card__value mb-0">
                    ${wallet?.balance ? Number(wallet?.balance)?.toFixed(3) : 0}
                  </h2>
                  <h2 className="dashboard__card__value mb-0">
                    {wallet?.balance
                      ? (wallet?.balance * lkdPrice)?.toFixed(3)
                      : 0}{" "}
                    LKD
                  </h2>
                </div>
              </div>
            </Col> */}
          </Row>
          <div className="with mt-2 text-center small-card-ns">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                let payload = {
                  amount: Number(buyLeverage),
                };
                if (Number(buyLeverage) > 99) {
                  dispatch(
                    reBuyLeverage({
                      payload,
                      onSuccess: () => {
                        setReBuyLeverage(0);
                        dispatch(getWallet());
                        dispatch(getWalletHistory());
                      },
                    })
                  );
                } else {
                  Swal.fire({
                    text: "Minimum Buy Leverage is $100",
                    title: "Warning",
                    confirmButtonText: "Ok",
                    icon: "warning",
                  });
                }
              }}
            >
              <div className="d-flex align-items-center justify-content-between mt-2">
                <Input
                  placeholder="0"
                  className="me-2"
                  required
                  min={1}
                  step={1}
                  value={buyLeverage}
                  onChange={(e: any) => {
                    setReBuyLeverage(e.target.value);
                  }}
                />
              </div>
              <div className="text-center mt-3 mb-2">
                <Button className="btn__primary-sm px-5" type="submit">
                  {reBuyLeverageLoader ? <Spinner size="sm" /> : "Buy Leverage"}
                </Button>
                <p className="mt-2 mb-0 withdraw-card__body-note">
                  Note: Minimum Leverage Buy - $100
                </p>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </LoadingOverlayWrapper>
  );
};

export default ReBuyLeverage;
