import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Repository from "../../repository/Repository";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import Swal from "sweetalert2";

var authRepo = RepositoryFactory.get("auth");
export const logout = createAction("auth/logout");

export const register = createAsyncThunk(
  "auth/register",
  async ({ creds, onSuccess }: any, thunkAPI: any) => {
    try {
      const data = await authRepo.register(creds);

      if (data.status == 201) {
        Swal.fire({
          text: "User Registered Successfully",
          title: "Success",
          confirmButtonText: "Ok",
          icon: "success",
        });
        Repository.defaults.headers.Authorization =
          data?.data?.token?.access_token;
        onSuccess();
        return { data: data?.data };
      }
    } catch (error: any) {
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);
export const login = createAsyncThunk(
  "auth/login",
  async ({ creds, onSuccess, onError }: any, thunkAPI: any) => {
    try {
      const data = await authRepo.login(creds);
      if (data.status == 201) {
        Repository.defaults.headers.Authorization =
          data?.data?.token?.access_token;
        onSuccess();
        return { data: data?.data };
      }
    } catch (error: any) {
      onError();
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getProfileData = createAsyncThunk(
  "auth/get_profile_data",
  async (_, thunkAPI: any) => {
    try {
      const data = await authRepo.getProfileData();
      if (data.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      if (error?.response?.status == 401) {
        localStorage?.clear();
      }
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  authLoading: false,
  user: null,
  token: localStorage?.getItem("token"),
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state, action) => {
      state.authLoading = true;
    });
    builder.addCase(register.fulfilled, (state, action: any) => {
      state.authLoading = false;
      localStorage.setItem("token", action?.payload?.data?.token?.access_token);
      state.user = action?.payload?.data;
      state.token = action?.payload?.data?.token?.access_token;
    });
    builder.addCase(register.rejected, (state, action) => {
      state.authLoading = false;
      localStorage.clear();
    });

    builder.addCase(login.pending, (state, action) => {
      state.authLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action: any) => {
      state.authLoading = false;
      localStorage.setItem("token", action?.payload?.data?.token?.access_token);
      state.user = action?.payload?.data;
      state.token = action?.payload?.data?.token?.access_token;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.authLoading = false;
      localStorage.clear();
    });

    builder.addCase(getProfileData.pending, (state, action) => {
      state.authLoading = true;
    });
    builder.addCase(getProfileData.fulfilled, (state, action: any) => {
      state.authLoading = false;
      state.user = action?.payload?.data?.data;
    });
    builder.addCase(getProfileData.rejected, (state, action) => {
      state.authLoading = false;
    });
    builder.addCase(logout, (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("token"); // Use removeItem to clear token
    });
  },
});

export default authSlice.reducer;
