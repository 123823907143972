import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Spinner } from "reactstrap";
import { getDirectReferralReward } from "../../store/slices/userSlice";
import NoDataFound from "../NoDataFound";
import ReactPaginate from "react-paginate";
import moment from "moment";

type Props = {};

const DirectReferralReward = (props: Props) => {
  const dispatch = useDispatch<any>();
  const { direct_reward, rewardLoader } = useSelector(
    (state: any) => state.user
  );

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (event: any) => {
    setCurrentPage(event?.selected);
  };

  useEffect(() => {
    let newItems = direct_reward?.totalCount / itemsPerPage;
    setPageCount(newItems);
  }, [direct_reward]);

  const fetchUpdatedData = () => {
    const startIndex = currentPage * itemsPerPage;
    dispatch(
      getDirectReferralReward({ offset: startIndex, limit: itemsPerPage })
    );
  };

  useEffect(() => {
    fetchUpdatedData();
  }, [currentPage, itemsPerPage]);

  return (
    <div className=" main-p carousel-top bg-site-bg">
      <Container className="earning px-0 px-md-2">
        <div className="earning__title-card">
          <div className="earning__title-card__header mt-42">
            Direct Referral Reward
          </div>
          <div className="earning__title-card__body d-flex justify-content-between align-items-center pointer">
            <div>
              {" "}
              <h2 className="dashboard__card__heading mb-1">Total Earning</h2>
              <h2 className="dashboard__card__value mb-0 text-site-primary number">
                $
                {rewardLoader ? (
                  <Spinner size="sm" />
                ) : direct_reward?.totalEarning ? (
                  Number(direct_reward?.totalEarning)?.toFixed(3)
                ) : (
                  0
                )}
              </h2>
            </div>
            <div className="text-end">
              {" "}
              <h2 className="dashboard__card__heading mb-1">Today's Earning</h2>
              <h2 className="dashboard__card__value mb-0 text-site-primary number">
                $
                {rewardLoader ? (
                  <Spinner size="sm" />
                ) : direct_reward?.todayEarning ? (
                  Number(direct_reward?.todayEarning)?.toFixed(3)
                ) : (
                  0
                )}
              </h2>
            </div>
          </div>
        </div>
        <Row>
          {direct_reward?.data?.length > 0 ? (
            <>
              {direct_reward?.data?.map((reward: any, idx: number) => (
                <Col md="4" className="mt-2" key={idx}>
                  <div className="card h-100">
                    <div className="card__body border-radius-all">
                      <div className="d-flex align-items-center justify-content-between">
                        <h2 className="mb-0 card__body-label">
                          Direct Partner {idx + 1}:{" "}
                          <span>{reward?.user_user_lid || "NA"}</span>
                        </h2>
                        <h2 className="mb-0 card__body-label">
                          DOS:{" "}
                          {reward?.income_date
                            ? moment(reward?.income_date).format("YYYY-MM-DD")
                            : "NA"}
                        </h2>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-1">
                        <h2 className="mb-0 card__body-label text-site-gray">
                          Staked Amount:{" "}
                          <span className="text-site-gray">
                            ${reward?.income_on_total?.toFixed(3) || 0}
                          </span>
                        </h2>
                        <h2 className="mb-0 card__body-label ">
                          Reward:{" "}
                          <span className="text-site-gray">
                            ${reward?.income_amount?.toFixed(3) || 0}
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <i className="fas fa-angle-right text-site-primary" />
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel={
                  <i className="fas fa-angle-left text-site-primary" />
                }
                containerClassName="custom-pagination"
                activeClassName="custom-pagination-active"
                previousClassName="custom-pagination-prev"
                nextClassName="custom-pagination-next"
                pageClassName="custom-pagination-li"
                disabledClassName="custom-pagination-disabled"
              />
            </>
          ) : (
            <NoDataFound isLoading={rewardLoader} />
          )}
        </Row>
      </Container>
    </div>
  );
};

export default DirectReferralReward;
