import React, { useEffect, useRef, useState } from "react";
import HeaderImg from "../../assets/imgs/bg-cover.jpg";
import HomeIco from "../../assets/imgs/icons/HOME.png";
import { Carousel } from "antd";

import Withdrawal from "../../components/wallet/Withdrawal";
import FundTransfer from "../../components/wallet/FundTransfer";
import ReBuyLeverage from "../../components/wallet/ReBuyLeverage";
import History from "../../components/wallet/History";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getBalance,
  getLeverageData,
  getWallet,
  getWalletHistory,
} from "../../store/slices/userSlice";

type Props = {};

const Wallet = (props: Props) => {
  const navigate = useNavigate();
  const sliderRef = useRef<any>(null);
  const dispatch = useDispatch<any>();
  const [activeSlide, setActiveSlide] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  const NavigateTo = (index: number) => {
    window?.scrollTo(0, 0);
    if (sliderRef.current) {
      sliderRef.current?.goTo(index);
    }
  };

  const beforeChange = (current: number, next: number) => {
    setActiveSlide(next);
  };

  useEffect(() => {
    dispatch(getWallet());
    dispatch(getBalance());
    dispatch(getWalletHistory());
    dispatch(getLeverageData());

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerHeight = Math.max(185 - scrollY, 50); // Adjust the initial and minimum header height

  return (
    <div className="wallet">
      <div
        className="custom-slider__nav"
        style={{
          backgroundImage: `url(${HeaderImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>
      <div
        className="custom-slider__nav__wrapper justify-content-center"
        style={{
          backgroundImage: `${scrollY >= 70 ? `url(${HeaderImg})` : ""}`,
        }}
      >
        <div
          className="me-3 pointer d-none d-md-block"
          onClick={() => {
            navigate("/admin/index");
          }}
        >
          <img src={HomeIco} alt="home" height="30px" />
        </div>
        <div
          className={` ${
            activeSlide == 0 && "active"
          } custom-slider__nav__wrapper-item custom-slider__nav__wrapper-item-bl me-2`}
          onClick={() => NavigateTo(0)}
        >
          Withdrawal
        </div>
        <div
          className={` ${
            activeSlide == 1 && "active"
          } custom-slider__nav__wrapper-item custom-slider__nav__wrapper-item-bl me-2`}
          onClick={() => NavigateTo(1)}
        >
          Manage Leverage Wallet
        </div>
        <div
          className={` ${
            activeSlide == 2 && "active"
          } custom-slider__nav__wrapper-item custom-slider__nav__wrapper-item-bl me-2`}
          onClick={() => NavigateTo(2)}
        >
          Re-Buy Leverage
        </div>
        <div
          className={` ${
            activeSlide == 3 && "active"
          } custom-slider__nav__wrapper-item`}
          onClick={() => NavigateTo(3)}
        >
          History
        </div>
      </div>
      <Carousel
        swipe
        dots={false}
        ref={sliderRef}
        beforeChange={beforeChange}
        onSwipe={() => {
          window?.scrollTo(0, 0);
        }}
        adaptiveHeight={true}
      >
        <div>
          <Withdrawal />
        </div>
        <div>
          <FundTransfer />
        </div>
        <div>
          <ReBuyLeverage />
        </div>
        <div>
          <History />
        </div>
      </Carousel>
    </div>
  );
};

export default Wallet;
