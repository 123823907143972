import annoucementRepository from "./annoucementRepository";
import articleRepository from "./articleRepository";
import authRepository from "./authRepository";
import bannerRepository from "./bannerRepository";
import downloadRepository from "./downloadRepository";
import userRepository from "./userRepository";
import faqRepository from "./faqRepository";
import bonanzaRepository from "./bonanzaRepository";

const repositories: any = {
  auth: authRepository,
  user: userRepository,
  annoucement: annoucementRepository,
  banner: bannerRepository,
  article: articleRepository,
  download: downloadRepository,
  faqs: faqRepository,
  bonanza: bonanzaRepository,
};
// eslint-disable-next-line import/prefer-default-export
export const RepositoryFactory = {
  get: (name: any) => repositories[name],
};
