import React, { useState, useEffect } from "react";
import Title from "../Title";
import { Col, Container, Progress, Row, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getPerformanceReward } from "../../store/slices/userSlice";
import NoDataFound from "../NoDataFound";
import ReactPaginate from "react-paginate";
import moment from "moment";
type Props = {};

const PerformanceReward = (props: Props) => {
  const dispatch = useDispatch<any>();
  const { performance_reward, performanceLoader } = useSelector(
    (state: any) => state.user
  );
  console.log(performance_reward);

  const [itemsPerPage, setItemsPerPage] = useState(16);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (event: any) => {
    setCurrentPage(event?.selected);
  };

  useEffect(() => {
    let newItems = performance_reward?.totalCount / itemsPerPage;
    setPageCount(newItems);
  }, [performance_reward]);

  const fetchUpdatedData = () => {
    const startIndex = currentPage * itemsPerPage;
    dispatch(getPerformanceReward({ offset: startIndex, limit: itemsPerPage }));
  };

  useEffect(() => {
    fetchUpdatedData();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    setPageCount(0);
  }, []);

  return (
    <div className="performance  main-p carousel-top bg-site-bg">
      <Container className="px-0 px-md-2">
        <div className="earning__title-card">
          <div className="earning__title-card__header mt-42">
            Performance Reward
          </div>
          <div className="earning__title-card__body d-flex justify-content-between align-items-center pointer">
            <div>
              {" "}
              <h2 className="dashboard__card__heading mb-1">Total Earning</h2>
              <h2 className="dashboard__card__value mb-0 text-site-primary">
                $
                {performanceLoader ? (
                  <Spinner size="sm" />
                ) : performance_reward?.totalEarning ? (
                  performance_reward?.totalEarning?.toFixed(3)
                ) : (
                  0
                )}
              </h2>
            </div>
            <div className="text-end">
              {" "}
              <h2 className="dashboard__card__heading mb-1">Today's Earning</h2>
              <h2 className="dashboard__card__value mb-0 text-site-primary">
                $
                {performanceLoader ? (
                  <Spinner size="sm" />
                ) : performance_reward?.todayEarning ? (
                  performance_reward?.todayEarning?.toFixed(3)
                ) : (
                  0
                )}
              </h2>
            </div>
          </div>
        </div>
        <Row>
          <Col md="4" className="mt-2">
            {performance_reward?.adminPerformance && (
              <div className="small-card h-100">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <h2 className="performance-value mb-0">Date</h2>
                  </div>
                  <h2 className="performance-value mb-0">
                    {performance_reward?.adminPerformance
                      ?.admin_proform_datetime
                      ? moment(
                          performance_reward?.adminPerformance
                            ?.admin_proform_datetime
                        ).format("DD MMM YYYY")
                      : "N/A"}
                  </h2>
                </div>
                <div className="d-flex mt-1 align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <h2 className="performance-value mb-0">Total Deposit</h2>
                  </div>
                  <h2 className="performance-value mb-0">
                    $
                    {Number(
                      performance_reward?.adminPerformance?.admin_proform_turn
                    )?.toFixed(3) || 0}
                  </h2>
                </div>
                <div className="d-flex mt-1 align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <h2 className="performance-value mb-0">
                      Allocated Funds @ 2%
                    </h2>
                  </div>
                  <h2 className="performance-value mb-0 text-site-primary">
                    $
                    {Number(
                      performance_reward?.adminPerformance?.admin_proform_walker
                    )?.toFixed(3) || 0}
                  </h2>
                </div>
              </div>
            )}
          </Col>
          {performance_reward?.data?.length > 0 ? (
            <>
              {performance_reward?.data?.map((reward: any, idx: number) => (
                <Col md="4" className="mt-2">
                  <div className="card h-100">
                    <div className="card__body border-radius-all">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <h2 className="card__body-label performance-value mb-0">
                            Date:{" "}
                          </h2>
                        </div>
                        <h2 className="performance-value mb-0">
                          {reward?.income_date
                            ? moment(reward?.income_date).format("DD MMM YYYY")
                            : "NA"}
                        </h2>
                      </div>
                      <div className="d-flex mt-1 align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <h2 className="card__body-label performance-value mb-0">
                            Performance Reward:
                          </h2>
                        </div>
                        <h2 className="performance-value mb-0 text-site-primary">
                          ${reward?.income_amount?.toFixed(3) || 0}
                        </h2>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <i className="fas fa-angle-right text-site-primary" />
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel={
                  <i className="fas fa-angle-left text-site-primary" />
                }
                containerClassName="custom-pagination"
                activeClassName="custom-pagination-active"
                previousClassName="custom-pagination-prev"
                nextClassName="custom-pagination-next"
                pageClassName="custom-pagination-li"
                disabledClassName="custom-pagination-disabled"
              />
            </>
          ) : (
            <NoDataFound isLoading={performanceLoader} />
          )}
        </Row>
      </Container>
    </div>
  );
};

export default PerformanceReward;
