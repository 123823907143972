import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderImg from "../../assets/imgs/bg-cover.jpg";
import HomeIco from "../../assets/imgs/icons/HOME.png";
import { Carousel } from "antd";
import Stakes from "../../components/Stakes";
import Team from "../../components/Team";
import Business from "../../components/Business";
import Earnings from "../../components/Earnings";
import Profile from "../../components/Profile";
import { useDispatch } from "react-redux";
import { getEarnings, getStakeInfo } from "../../store/slices/userSlice";

type Props = {};

const SubPages = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const sliderRef = useRef<any>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  const NavigateTo = (index: number) => {
    window?.scrollTo(0, 0);
    setActiveSlide(index);
    if (sliderRef.current) {
      sliderRef.current?.goTo(index);
    }
  };

  const beforeChange = (current: number, next: number) => {
    setActiveSlide(next);
  };

  useEffect(() => {
    dispatch(getStakeInfo());
    dispatch(getEarnings());

    const currentComp = location?.search?.split("=")?.[1];
    if (currentComp == "stake") {
      NavigateTo(0);
    } else if (currentComp == "team") {
      NavigateTo(1);
    } else if (currentComp == "business") {
      NavigateTo(2);
    } else if (currentComp == "earnings") {
      NavigateTo(3);
    } else if (currentComp == "profile") {
      NavigateTo(4);
    }
    // else if (currentComp == "genealogy") {
    //   NavigateTo(4);
    // }
    else {
      NavigateTo(0);
    }

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerHeight = Math.max(185 - scrollY, 50);
  return (
    <div>
      <div
        className="custom-slider__nav"
        style={{
          backgroundImage: `url(${HeaderImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>
      <div
        className="custom-slider__nav__wrapper justify-content-center"
        style={{
          backgroundImage: `${scrollY >= 70 ? `url(${HeaderImg})` : ""}`,
        }}
      >
        <div
          className="me-3 pointer d-none d-md-block"
          onClick={() => {
            navigate("/admin/index");
          }}
        >
          <img src={HomeIco} alt="home" height="30px" />
        </div>
        <div
          className={` ${
            activeSlide == 0 && "active"
          } custom-slider__nav__wrapper-item custom-slider__nav__wrapper-item-bl me-2`}
          onClick={() => NavigateTo(0)}
        >
          Stake
        </div>
        <div
          className={` ${
            activeSlide == 1 && "active"
          } custom-slider__nav__wrapper-item custom-slider__nav__wrapper-item-bl me-2`}
          onClick={() => NavigateTo(1)}
        >
          Team
        </div>
        <div
          className={` ${
            activeSlide == 2 && "active"
          } custom-slider__nav__wrapper-item custom-slider__nav__wrapper-item-bl me-2`}
          onClick={() => NavigateTo(2)}
        >
          Business
        </div>
        <div
          className={` ${
            activeSlide == 3 && "active"
          } custom-slider__nav__wrapper-item custom-slider__nav__wrapper-item-bl me-2`}
          onClick={() => NavigateTo(3)}
        >
          Earnings
        </div>

        {/* <div
          className={` ${
            activeSlide == 4 && "active"
          } custom-slider__nav__wrapper-item custom-slider__nav__wrapper-item-bl me-2`}
          onClick={() => NavigateTo(4)}
        >
          Genealogy
        </div> */}
        <div
          className={` ${
            activeSlide == 4 && "active"
          } custom-slider__nav__wrapper-item`}
          onClick={() => NavigateTo(4)}
        >
          Profile
        </div>
      </div>
      <Carousel
        swipe
        dots={false}
        ref={sliderRef}
        beforeChange={beforeChange}
        onSwipe={() => {
          window?.scrollTo(0, 0);
        }}
        adaptiveHeight={true}
      >
        <div>
          <Stakes />
        </div>
        <div>
          <Team />
        </div>
        <div>
          <Business />
        </div>
        <div>
          <Earnings />
        </div>
        {/* <div>
          <Genealogy />
        </div> */}
        <div>
          <Profile />
        </div>
      </Carousel>
    </div>
  );
};

export default SubPages;
