import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Spinner } from "reactstrap";
import { adminTopRef, getTopReferral } from "../../store/slices/userSlice";
import NoDataFound from "../NoDataFound";
import ReactPaginate from "react-paginate";
import moment from "moment";
type Props = {};

const TopReferralReward = (props: Props) => {
  const dispatch = useDispatch<any>();
  const { top_referral_reward, rewardLoader, adminTop } = useSelector(
    (state: any) => state.user
  );

  const [itemsPerPage, setItemsPerPage] = useState(13);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (event: any) => {
    setCurrentPage(event?.selected);
  };

  useEffect(() => {
    let newItems = top_referral_reward?.totalCount / itemsPerPage;
    setPageCount(newItems);
  }, [top_referral_reward]);

  const fetchUpdatedData = () => {
    const startIndex = currentPage * itemsPerPage;
    dispatch(getTopReferral({ offset: startIndex, limit: itemsPerPage }));
  };

  useEffect(() => {
    fetchUpdatedData();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    setPageCount(0);
    dispatch(adminTopRef());
  }, []);

  // console.log(top_referral_reward)
  console.log(adminTop);

  return (
    <div className=" main-p carousel-top bg-site-bg">
      <Container className="px-0 px-md-2">
        <div className="earning__title-card">
          <div className="earning__title-card__header mt-42">
            Top Referral Reward
          </div>
          <div className="earning__title-card__body d-flex justify-content-between align-items-center pointer">
            <div>
              {" "}
              <h2 className="dashboard__card__heading mb-1">Total Earning</h2>
              <h2 className="dashboard__card__value mb-0 text-site-primary number">
                $
                {rewardLoader ? (
                  <Spinner size="sm" />
                ) : top_referral_reward?.totalEarning ? (
                  top_referral_reward?.totalEarning?.toFixed(3)
                ) : (
                  0
                )}
              </h2>
            </div>
            <div className="text-end">
              {" "}
              <h2 className="dashboard__card__heading mb-1">Today's Earning</h2>
              <h2 className="dashboard__card__value mb-0 text-site-primary number">
                $
                {rewardLoader ? (
                  <Spinner size="sm" />
                ) : top_referral_reward?.todayEarning ? (
                  top_referral_reward?.todayEarning?.toFixed(3)
                ) : (
                  0
                )}
              </h2>
            </div>
          </div>
        </div>
        <Row>
          <Col md="4" className="mt-2">
            <div className="card h-100">
              <div className="card__body border-radius-all">
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="performance-value  mb-0">Date</h2>
                  <h2 className="performance-value  mb-0">
                    {adminTop?.datetime
                      ? moment(adminTop?.datetime).format("DD MMM YYYY")
                      : "NA"}
                  </h2>
                </div>
                <div className="d-flex mt-1 align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <h2 className="performance-value mb-0">Allocated Fund</h2>
                  </div>
                  <h2 className="performance-value mb-0">
                    ${adminTop?.allocated?.toFixed(3) || 0}
                  </h2>
                </div>
                <div className="d-flex mt-1 align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <h2 className="performance-value mb-0">
                      Last day rolled over
                    </h2>
                  </div>
                  <h2 className="performance-value mb-0">
                    ${adminTop?.lastday?.toFixed(3) || 0}
                  </h2>
                </div>
                <div className="d-flex mt-1 align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <h2 className="performance-value mb-0">
                      Funds Available for distribution
                    </h2>
                  </div>
                  <h2 className="performance-value mb-0">
                    ${adminTop?.available?.toFixed(3) || 0}
                  </h2>
                </div>
                <div className="d-flex mt-1 align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <h2 className="performance-value mb-0">
                      Roll over for next day
                    </h2>
                  </div>
                  <h2 className="performance-value mb-0">
                    ${adminTop?.role?.toFixed(3) || 0}
                  </h2>
                </div>
              </div>
            </div>
          </Col>
          {top_referral_reward?.data?.length > 0 ? (
            <>
              {top_referral_reward?.data?.map((reward: any, idx: number) => (
                <Col md="4" className="mt-2" key={idx}>
                  <div className="small-card h-100">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <h2 className="performance-label mb-0">Date</h2>
                      <h2 className="card__body-label performance-value mb-0">
                        {reward?.income_date
                          ? moment(reward?.income_date).format("DD MMM YYYY")
                          : "NA"}
                      </h2>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <h2 className="performance-value mb-0">Position</h2>
                      <h2 className="card__body-label performance-value mb-0">
                        {reward?.income_level || 0}
                      </h2>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <h2 className="performance-value mb-0">
                        Amount of Reward
                      </h2>
                      <h2 className=" card__body-label performance-value mb-0 ">
                        ${reward?.income_amount?.toFixed(3) || 0}
                      </h2>
                    </div>
                  </div>
                </Col>
              ))}
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <i className="fas fa-angle-right text-site-primary" />
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel={
                  <i className="fas fa-angle-left text-site-primary" />
                }
                containerClassName="custom-pagination"
                activeClassName="custom-pagination-active"
                previousClassName="custom-pagination-prev"
                nextClassName="custom-pagination-next"
                pageClassName="custom-pagination-li"
                disabledClassName="custom-pagination-disabled"
              />
            </>
          ) : (
            <NoDataFound isLoading={rewardLoader} />
          )}
        </Row>
      </Container>
    </div>
  );
};

export default TopReferralReward;
