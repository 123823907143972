import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Label, Spinner } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../store/slices/authSlice";
import { ThunkDispatch } from "redux-thunk";
import { useAccount, useSignMessage } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import Logo from "../../assets/imgs/logo.svg";
import Swal from "sweetalert2";

type Props = {};

export interface RegisterData {
  name: string;
  email: string;
  sponsorId: string;
  phone: string;
}

export interface RegisterPayload {
  creds: RegisterData;
  onSuccess: () => {};
}

const Register = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const { open: connect, setDefaultChain } = useWeb3Modal();
  const account = useAccount();

  const { signMessage } = useSignMessage({
    message: "this is signature message for linkdao",
    onSuccess: (res: any) => {
      let obj = {
        creds: { ...registerData, address: account?.address, signature: res },
        onSuccess: () => {},
      };
      if (obj?.creds?.phone) {
        dispatch(register(obj));
      }
    },
    onError: (err: any) => {
      Swal.fire({
        text: err?.message || "Something went wrong!",
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
    },
  });

  const { authLoading } = useSelector((state: any) => state.auth);

  const [registerData, setRegisterData] = useState<RegisterData>({
    name: "",
    email: "",
    sponsorId: "",
    phone: "",
  });

  useEffect(() => {
    // Parse the URL to get the invitation code
    const urlParams = new URLSearchParams(window.location.search);
    const invitationCode = urlParams.get("invitation-code");

    // If an invitation code is present in the URL, update the state
    if (invitationCode) {
      setRegisterData({ ...registerData, sponsorId: invitationCode });
    }
  }, []);

  return (
    <div className="auth-form flex-column">
      <div className="auth-form-header text-center">
        <img src={Logo} alt="logo" height="40px" />
      </div>
      <div className="auth-form__wrapper">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (account?.isConnected) {
              if (registerData?.phone) {
                signMessage();
              } else {
                Swal.fire({
                  text: "Phone is required!",
                  title: "Error",
                  confirmButtonText: "Ok",
                  icon: "error",
                });
              }
            } else {
              connect();
            }
          }}
        >
          {/* <i
            className="fas fa-chevron-left fa-lg text-site-primary pointer"
            onClick={() => {
              navigate("/");
            }}
          ></i> */}
          {/* <h2 className="auth-form__wrapper-title mt-5">Register</h2> */}
          <div className="">
            <Label className="auth-form__wrapper-label">Sponsor Id</Label>
            <Input
              placeholder="Please enter sponsor id"
              required
              id="sponsorId"
              value={registerData.sponsorId}
              onChange={(e) => {
                setRegisterData({ ...registerData, sponsorId: e.target.value });
              }}
            />
            <Label className="auth-form__wrapper-label mt-4">Name</Label>
            <Input
              placeholder="Please enter your name"
              required
              value={registerData.name}
              onChange={(e) => {
                setRegisterData({ ...registerData, name: e.target.value });
              }}
            />
            <Label className="auth-form__wrapper-label mt-4">Email</Label>
            <Input
              placeholder="Please enter your email"
              required
              type="email"
              value={registerData.email}
              onChange={(e) => {
                setRegisterData({ ...registerData, email: e.target.value });
              }}
            />
            <Label className="auth-form__wrapper-label mt-4">
              Contact Number
            </Label>
            <PhoneInput
              buttonClass="button-class"
              inputClass="input-class"
              country={"in"}
              value={registerData.phone}
              onChange={(phone) =>
                setRegisterData({
                  ...registerData,
                  phone: phone,
                })
              }
            />
            <Button
              block
              className="mt-5 auth__wrapper__btn-filled"
              type="submit"
              disabled={
                !registerData?.email ||
                !registerData?.sponsorId ||
                !registerData?.name ||
                !registerData?.phone ||
                authLoading
              }
            >
              {authLoading ? (
                <Spinner size="sm" />
              ) : account?.isConnected ? (
                "Sign"
              ) : (
                "Register"
              )}
            </Button>
            <p className="text-center mb-0 mt-2">
              <small className="pointer" onClick={() => navigate("/")}>
                Go Back
              </small>
            </p>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Register;
