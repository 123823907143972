import React from "react";
import { useLocation } from "react-router-dom";
import { Button } from "reactstrap";

type Props = {
  title: string;
};

const Title = (props: Props) => {
  return (
    <div className="title-wrapper mb-2">
      <Button className="bg-site-primary" block>
        {props.title}
      </Button>
    </div>
  );
};

export default Title;
