import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import Swal from "sweetalert2";
import { isJsonString } from "../../utils/utils";

var faqRepo = RepositoryFactory.get("faqs");

export const getAllFaqs = createAsyncThunk(
  "faqs/get_all_faqs",
  async (_, thunkAPI: any) => {
    try {
      const data = await faqRepo.getAllFaqs();
      if (data.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  fetchFaqLoader: false,
  faqsData: isJsonString(localStorage?.getItem("faqs") || "") ? JSON.parse(localStorage?.getItem("faqs") || "[]") : [],
};
const FaqSlice = createSlice({
  name: "faqs",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllFaqs.pending, (state: any, _action: any) => {
      state.fetchFaqLoader = true;
    });
    builder.addCase(getAllFaqs.fulfilled, (state: any, _action: any) => {
      state.fetchFaqLoader = false;
      state.faqsData = _action?.payload?.data?.data;
      localStorage.setItem(
        "faqs",
        JSON.stringify(_action?.payload?.data?.data)
      );
    });
    builder.addCase(getAllFaqs.rejected, (state: any, _action: any) => {
      state.fetchFaqLoader = false;
    });
  },
  reducers: {},
});

export default FaqSlice.reducer;
