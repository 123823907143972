import React, { useEffect, useRef, useState } from "react";
import HeaderImg from "../../assets/imgs/bg-cover.jpg";
import HomeIco from "../../assets/imgs/icons/HOME.png";
import { Carousel } from "antd";

import { useLocation, useNavigate } from "react-router-dom";
import StakingReward from "../../components/Earnings/StakingReward";
import DirectReferralReward from "../../components/Earnings/DirectReferralReward";
import PerformanceReward from "../../components/Earnings/PerformanceReward";
import TopReferralReward from "../../components/Earnings/TopReferralReward";
import PassiveUniLevelReward from "../../components/Earnings/PassiveUniLevelReward";
import VIPUniLevelReward from "../../components/Earnings/VIPUniLevelReward";
import LinkDaoStarRoyalty from "../../components/Earnings/LinkDaoStarRoyalty";
import LinkDaoStarReward from "../../components/Earnings/LinkDaoStarReward";
import { useDispatch } from "react-redux";
import {
  getAward,
  getDirectReferralReward,
  getPassiveReward,
  getPerformanceReward,
  getStakingRewardEarning,
  getStarRoyalty,
  getTopReferral,
  getVIPReward,
} from "../../store/slices/userSlice";

type Props = {};

const EarningsDetails = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const sliderRef = useRef<any>(null);
  const navRef = useRef<HTMLDivElement>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  const scrollDiv = (shift: number) => {
    let el = document.getElementById("scrollDiv");
    if (el) {
      el.scrollLeft += shift;
    }
  };

  const NavigateTo = (index: number) => {
    window?.scrollTo(0, 0);
    setActiveSlide(index);
    if (sliderRef.current) {
      sliderRef.current?.goTo(index);
    }
  };

  const beforeChange = (current: number, next: number) => {
    setActiveSlide(next);

    const navigationContainer = navRef.current;
    if (navigationContainer) {
      const navigationItemWidth = navigationContainer.offsetWidth / 3; // Divide by the number of slides
      const navigationScrollLeft =
        next * navigationItemWidth -
        navigationContainer.offsetWidth / 2 +
        navigationItemWidth / 2;
      const maxScrollLeft =
        navigationContainer.scrollWidth - navigationContainer.offsetWidth;
      const finalScrollLeft = Math.min(navigationScrollLeft, maxScrollLeft);
      navigationContainer.scrollTo({
        left: finalScrollLeft,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const currentComp = location?.search?.split("=")?.[1];
    if (currentComp == "staking-reward") {
      NavigateTo(0);
    } else if (currentComp == "direct-referral") {
      NavigateTo(1);
    } else if (currentComp == "performance-reward") {
      NavigateTo(2);
    } else if (currentComp == "top-referral") {
      NavigateTo(3);
    } else if (currentComp == "passive-uni-level") {
      NavigateTo(4);
    } else if (currentComp == "vip-uni-level") {
      NavigateTo(5);
    } else if (currentComp == "star-royalty") {
      NavigateTo(6);
    } else if (currentComp == "star-reward") {
      NavigateTo(7);
    } else {
      NavigateTo(0);
    }
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerHeight = Math.max(185 - scrollY, 50);
  return (
    <div>
      <div
        className="custom-slider__nav"
        style={{
          backgroundImage: `url(${HeaderImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>
      <div
        id="scrollDiv"
        className="custom-slider__nav__wrapper justify-content-md-center"
        style={{
          backgroundImage: `${scrollY >= 70 ? `url(${HeaderImg})` : ""}`,
        }}
        ref={navRef}
      >
        <div
          className="me-3 pointer d-none d-md-block"
          onClick={() => {
            navigate("/admin/index");
          }}
        >
          <img src={HomeIco} alt="home" height="30px" />
        </div>
        <div
          className={` ${
            activeSlide == 0 && "active"
          } custom-slider__nav__wrapper-item custom-slider__nav__wrapper-item-bl me-2`}
          onClick={() => NavigateTo(0)}
        >
          Staking Reward
        </div>
        <div
          className={` ${
            activeSlide == 1 && "active"
          } custom-slider__nav__wrapper-item custom-slider__nav__wrapper-item-bl me-2`}
          onClick={() => NavigateTo(1)}
        >
          Direct Referral
        </div>
        <div
          className={` ${
            activeSlide == 2 && "active"
          } custom-slider__nav__wrapper-item custom-slider__nav__wrapper-item-bl me-2`}
          onClick={() => NavigateTo(2)}
        >
          Performance Reward
        </div>
        <div
          className={` ${
            activeSlide == 3 && "active"
          } custom-slider__nav__wrapper-item custom-slider__nav__wrapper-item-bl me-2`}
          onClick={() => NavigateTo(3)}
        >
          Top Referral
        </div>
        <div
          className={` ${
            activeSlide == 4 && "active"
          } custom-slider__nav__wrapper-item custom-slider__nav__wrapper-item-bl me-2`}
          onClick={() => NavigateTo(4)}
        >
          Passive Uni-Level
        </div>
        <div
          className={` ${
            activeSlide == 5 && "active"
          } custom-slider__nav__wrapper-item custom-slider__nav__wrapper-item-bl me-2`}
          onClick={() => NavigateTo(5)}
        >
          VIP Uni-Level
        </div>
        <div
          className={` ${
            activeSlide == 6 && "active"
          } custom-slider__nav__wrapper-item custom-slider__nav__wrapper-item-bl me-2`}
          onClick={() => NavigateTo(6)}
        >
          Star Royalty
        </div>
        <div
          className={` ${
            activeSlide == 7 && "active"
          } custom-slider__nav__wrapper-item`}
          onClick={() => NavigateTo(7)}
        >
          Star Award
        </div>
      </div>
      <Carousel
        swipe
        dots={false}
        ref={sliderRef}
        beforeChange={beforeChange}
        onSwipe={() => {
          window?.scrollTo(0, 0);
        }}
        adaptiveHeight={true}
      >
        <div>
          <StakingReward />
        </div>
        <div>
          <DirectReferralReward />
        </div>
        <div>
          <PerformanceReward />
        </div>
        <div>
          <TopReferralReward />
        </div>
        <div>
          <PassiveUniLevelReward />
        </div>
        <div>
          <VIPUniLevelReward />
        </div>
        <div>
          <LinkDaoStarRoyalty />
        </div>
        <div>
          <LinkDaoStarReward />
        </div>
      </Carousel>
    </div>
  );
};

export default EarningsDetails;
