import axios from "axios";
// const baseDomain = "https://linkdao-api.cryptosuite.finance/";
// const baseDomain = "https://linkdao-defi-api.linkdao.cc/";
const baseDomain = "https://user.linkdao.cc/";
const baseURL = `${baseDomain}`;
const getToken = () => {
  let ls = localStorage.getItem("token");
  if (ls) {
    return ls;
  } else {
    return "";
  }
};
let axiosObj: any;
axiosObj = axios.create({
  baseURL,
  headers: {
    Authorization: getToken(),
  },
});

export default axiosObj;
