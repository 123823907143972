import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Container, Spinner } from "reactstrap";
const FAQs = () => {
  const [activeFAQ, setActiveFAQ] = useState(-1);
  const { faqsData, fetchFaqLoader } = useSelector((state: any) => state.faqs);

  const toggleFAQ = (faq: number) => {
    if (faq === activeFAQ) {
      setActiveFAQ(-1);
    } else {
      setActiveFAQ(faq);
    }
  };

  return (
    <Container className="px-0 mb-3">
      {faqsData &&
        faqsData
          ?.slice()
          ?.sort((a: any, b: any) => {
            const id1 = a?.id;
            const id2 = b?.id;
            return id1 - id2;
          })
          ?.map((faq: any, index: number) => (
            <div className="card mt-2">
              <div
                className="card__header d-flex align-items-center justify-content-between border-radius-top pointer"
                onClick={() => toggleFAQ(index)}
              >
                <p className="mb-0 filter__card-label">
                  {fetchFaqLoader ? <Spinner /> : faq?.question}
                </p>
                {activeFAQ === index ? (
                  <i className="fa fa-chevron-up"></i>
                ) : (
                  <i className="fa fa-chevron-down"></i>
                )}
              </div>
              <Collapse isOpen={activeFAQ == index}>
                <div className="card__body border-radius-bottom html-label">
                  <small
                    className="filter__card-label mb-0"
                    dangerouslySetInnerHTML={{
                      __html: fetchFaqLoader ? <Spinner /> : faq?.answer,
                    }}
                  ></small>
                </div>
              </Collapse>
            </div>
          ))}
    </Container>
  );
};

export default FAQs;
