import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import HeaderImg from "../../assets/imgs/bg-cover.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllArticles,
  getAllCategories,
} from "../../store/slices/articleSlice";
import moment from "moment";
import NoDataFound from "../../components/NoDataFound";
import Swal from "sweetalert2";

type Props = {};

const Academy = (props: Props) => {
  const dispatch = useDispatch<any>();
  const { articles, categories, fetchArticleLoader } = useSelector(
    (state: any) => state.article
  );

  const [activeTab, setActiveTab] = useState(1);
  const [activeArticle, setActiveArticle] = useState(-1);

  const getSortedBlogs = () => {
    return articles
      ?.filter(
        (article: any) =>
          !article?.isDeleted && article?.categoryId == activeTab
      )
      ?.slice()
      ?.sort((a: any, b: any) => {
        const id1 = a?.id;
        const id2 = b?.id;
        return id1 - id2;
      });
  };

  const handleArticleChange = (idx: number) => {
    window?.scrollTo(0, 0);
    if (idx == 1) {
      const activeArticleIndex = getSortedBlogs()?.findIndex(
        (obj: any) => obj?.id == activeArticle
      );
      if (activeArticleIndex < getSortedBlogs()?.length - 1) {
        setActiveArticle(getSortedBlogs()[activeArticleIndex + 1]?.id);
      } else {
        setActiveArticle(-1);
      }
    } else {
      const activeArticleIndex = getSortedBlogs()?.findIndex(
        (obj: any) => obj?.id == activeArticle
      );
      if (activeArticleIndex > 0) {
        setActiveArticle(getSortedBlogs()[activeArticleIndex - 1]?.id);
      } else {
        setActiveArticle(-1);
      }
    }
  };

  const getSelectedArticle = () => {
    let obj: any = articles?.find(
      (article: any) => article?.id == activeArticle
    );
    if (obj) {
      return obj;
    } else {
      return null;
    }
  };

  useEffect(() => {
    setActiveTab(categories?.[0]?.id);
  }, [categories]);

  useEffect(() => {
    dispatch(getAllArticles());
    dispatch(getAllCategories());
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${HeaderImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className="sub-page-header"
      ></div>
      <div className="history main-p">
        <Container className="px-0">
          <Row className="history-tabs">
            {categories?.length > 0 ? (
              categories?.map((cat: any, index: number) => (
                <Col xs="4" className="history-tabs-col1" key={index}>
                  <Button
                    style={{ fontSize: "17px" }}
                    className={`${
                      activeTab === cat?.id ? "active" : "history-tab"
                    }`}
                    block
                    onClick={() => {
                      setActiveTab(cat?.id);
                      setActiveArticle(-1);
                    }}
                  >
                    {cat?.title ? cat?.title : "N/A"}
                  </Button>
                </Col>
              ))
            ) : (
              <NoDataFound isLoading={fetchArticleLoader} />
            )}
          </Row>

          {activeArticle === -1 ? (
            <Row>
              {getSortedBlogs()?.map((article: any, idx: number) => (
                <Col md="4" className="mt-2">
                  <Row>
                    <Col xs="4" className="pe-0">
                      <div
                        className="card h-100"
                        style={{
                          backgroundImage: `url(${article?.imageUrl})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <div className="border-radius-all"></div>
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="card h-100">
                        <div className="card__body border-radius-all">
                          <p className="mb-0 filter__card-label">
                            <strong>
                              {article?.title?.length > 30
                                ? article?.title?.slice(0, 30) + "..."
                                : article?.title}
                            </strong>
                          </p>
                          {/* <p className="mb-0 filter__card-label">
                              Content By:
                            </p> */}
                          <p className="mb-1 filter__card-label">
                            {moment(article?.created_at).format(
                              "DD.MM.YYYY - hh:mm:ss A"
                            )}
                          </p>
                          <p
                            className="mb-0 filter__card-label text-site-primary pointer"
                            onClick={() => {
                              setActiveArticle(article?.id);
                            }}
                          >
                            Click to View
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          ) : (
            <div className="card mt-2">
              <div className="card__header border-radius-top">
                <h2 className=" mb-0 card__body-heading text-center">
                  {getSelectedArticle()?.title}
                </h2>
              </div>
              <div className="card__body border-radius-bottom html-label">
                <div className="text-center">
                  {getSelectedArticle()?.imageUrl && (
                    <img
                      src={getSelectedArticle()?.imageUrl}
                      alt="blog_img"
                      className="img-fluid rounded mb-2"
                    />
                  )}
                </div>
                <p
                  className="filter__card-label mb-0"
                  dangerouslySetInnerHTML={{
                    __html: getSelectedArticle()?.content,
                  }}
                ></p>
                <div className="text-end mt-2">
                  <Button
                    className="btn__primary-circle me-2"
                    onClick={() => handleArticleChange(-1)}
                  >
                    <i className="fa fa-chevron-left"></i>
                  </Button>
                  <Button
                    className="btn__primary-circle"
                    onClick={() => handleArticleChange(1)}
                  >
                    <i className="fa fa-chevron-right"></i>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default Academy;
