import Repository from "./Repository";
const REGISTER = "/auth/register";
const LOGIN = "/auth/login";
const GET_PROFILE_DATA = "/user/getProfileData";

export default {
  register(payload: any) {
    return Repository.post(REGISTER, payload);
  },

  login(payload: any) {
    return Repository.post(LOGIN, payload);
  },

  getProfileData() {
    return Repository.get(GET_PROFILE_DATA);
  },
};
