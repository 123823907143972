import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { ConnectorData, useAccount, useDisconnect } from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../store/slices/authSlice";
import {
  getLivePrices,
  getStakesData,
  getUserStakeSummary,
} from "../store/slices/userSlice";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import SpinnerImg from "../assets/imgs/spinner.svg";

type Props = {};

const AdminLayout = (props: Props) => {
  const account = useAccount();
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { token } = useSelector((state: any) => state.auth);
  const { fetchLoader } = useSelector((state: any) => state.user);

  useEffect((): any => {
    const handleConnectorUpdate = ({ account, chain }: ConnectorData) => {
      if (account) {
        localStorage.clear();
        disconnect();
      } else if (chain) {
        localStorage.clear();
        disconnect();
      }
    };

    if (account?.connector) {
      account?.connector.on("change", handleConnectorUpdate);
    }

    return () => account?.connector?.off("change", handleConnectorUpdate);
  }, [account?.connector]);

  useEffect(() => {
    dispatch(getProfileData());
    dispatch(getLivePrices());
    dispatch(getUserStakeSummary(account?.address || ""));
    dispatch(getStakesData(account?.address || ""));
  }, []);

  if (!account?.isConnected || !token) {
    navigate("/");
  }
  return (
    <div>
      <Header />
      <div
        className="mb-6 mb-md-0"
        style={{
          overflowY: "auto",
          marginBottom: "50px",
        }}
      >
        <LoadingOverlayWrapper
          active={fetchLoader}
          text="Please Wait"
          spinner={<img src={SpinnerImg} alt="spinner" />}
        >
          <Outlet />
        </LoadingOverlayWrapper>
      </div>
    </div>
  );
};

export default AdminLayout;
