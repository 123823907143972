import Repository from "./Repository";

const GET_ALL_ARTICLES = "/article/getAllArticles";
const GET_ALL_CATEGORIES = "/category/getAllCategories";
const GET_ARTICLE_BY_ID = "/article/getArticleById";

export default {
  getAllArticles() {
    return Repository.get(GET_ALL_ARTICLES);
  },

  getAllCategories() {
    return Repository.get(GET_ALL_CATEGORIES);
  },

  getArticleById(id: number) {
    return Repository.get(`${GET_ARTICLE_BY_ID}/${id}`);
  },
};
