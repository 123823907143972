export const toWei = (value: number) => {
    return value * 10 ** 18;
};

export const fromWei = (value: number) => {
    return value / 10 ** 18;
}

export const getPackage = (value: number) => {
    try {
        if (value <= 99) {
            return {
                name: "Basic",
                message: "$25 - $99",
            }
        }
        if (value <= 199) {
            return {
                name: "Standard",
                message: "$100 - $199",
            }
        }
        if (value <= 499) {
            return {
                name: "Super",
                message: "$200 - $499",
            }
        }

        if (value >= 500) {
            return {
                name: "Premium",
                message: "$500 & Above",
            }
        }

    } catch (err) {
        console.log(err)

    }
}

export function isJsonString(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}