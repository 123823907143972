import React from 'react'
import Header from './Header'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import {BiCalendar} from 'react-icons/bi'

function AuditCard(props: any) {
  return (
    <div>
            <Card>
              <CardBody className='d-flex mx-2'>
                <Col xs='9' md='8' lg='7' className='d-flex flex-column gap-2'>
                  <Row><p className='blue-p'>BSC</p></Row>
                  <Row><h3 >{props.title}</h3></Row>
                  <Row>
                    <p className='gray-p'>{props.type}</p>
                  </Row>
                  <Row>
                    <p className='date-p d-flex align-items-center gap-1'><span><BiCalendar /></span>{props.date}</p>
                  </Row>
                  <Row className='mb-2'>
                    <Col className='d-flex align-items-center gap-2 p-0'>
                      <span className='rounded gray'></span>
                      <span>{props.d1}</span>
                    </Col>
                    <Col className='d-flex align-items-center gap-2 p-0'>
                      <span className='rounded yellow'></span>
                      <span>{props.d2}</span>
                    </Col>
                    <Col className='d-flex align-items-center gap-2 p-0'>
                      <span className='rounded orange'></span>
                      <span>{props.d3}</span>
                    </Col>
                    <Col className='d-flex align-items-center gap-2 p-0'>
                      <span className='rounded red'></span>
                      <span>{props.d4}</span>
                    </Col>
                    <Col className='d-flex align-items-center gap-2 p-0'>
                      <span className='rounded green'></span>
                      <span>{props.d5}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Button onClick={() => {
                      window?.open(
                  `https://blocksafu.com/audit/${props.address}`
                );
                    }}>Detail Audit</Button>
                  </Row>
                </Col>
                <Col md='6' className='d-flex justify-content-center align-items-center'>
                  <img src={props.imageUrl} width={100} className='border' alt="product designer" />
                </Col>
              </CardBody>
            </Card>
          {/* </Col> */}
    </div>
  )
}

export default AuditCard
