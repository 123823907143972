import Repository from "./otherBaseRepository";

const CHECK_ELEGIBILITY = "/";
const SUBMIT_DOCS = "/getData/setImages";
const GET_BUSINESS_DATA_BY_ID = "/getData/GetDataofBusinessById";
const SET_VISA_URL = "/getData/SetVisaUrl";
const GET_ALL_VISA_URL_DATA = "/getData/getAllVisaUrlData";
const GET_VISA_URL_BY_ID = "/getData/getVisaUrlbyId";
const SET_TICKET_URL = "/getData/setTicketUrl";
const GET_ALL_TICKET_URL = "/getData/getAllTicketUrl";
const GET_TICKET_URL_BY_ID = "/getData/getTicketUrlById";
const GET_ALL_IMAGES = "/getData/getAllImages";
const GET_IMAGE_BY_LEGAL_NAME = "/getData/getImageByLegalName";
const GET_IMAGE_BY_ID = "/getData/getImageById";
const GET_IMAGE_BY_USER_ID = "/getData/getImageByUserId";

export default {
  checkEligibility() {
    return Repository.get(CHECK_ELEGIBILITY);
  },
  submitDocs(payload: any) {
    return Repository.post(SUBMIT_DOCS, payload);
  },
  getDataOfBusinessById({ userId, limit, offset }: any) {
    return Repository.get(
      `${GET_BUSINESS_DATA_BY_ID}/${userId}?limit=${limit}&offset=${offset}`
    );
  },
  setVisaUrl(payload: any) {
    return Repository.post(SET_VISA_URL, payload);
  },
  getAllVisaUrl() {
    return Repository.get(GET_ALL_VISA_URL_DATA);
  },
  getVisaUrlById(userId: any) {
    return Repository.get(`${GET_VISA_URL_BY_ID}/${userId}`);
  },
  setTicketUrl(payload: any) {
    return Repository.post(SET_TICKET_URL, payload);
  },
  getAllTicketUrl() {
    return Repository.get(GET_ALL_TICKET_URL);
  },
  getTicketUrlById(userId: any) {
    return Repository.get(`${GET_TICKET_URL_BY_ID}/${userId}`);
  },
  getAllIImages() {
    return Repository.get(GET_ALL_IMAGES);
  },
  getImageByLegalName(legalName: any) {
    return Repository.get(`${GET_IMAGE_BY_LEGAL_NAME}/${legalName}`);
  },
  getImageById(id: any) {
    return Repository.get(`${GET_IMAGE_BY_ID}/${id}`);
  },
  getImageByUserId(userId: any) {
    return Repository.get(`${GET_IMAGE_BY_USER_ID}/${userId}`);
  },
};
