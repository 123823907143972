import { Col, Container, Row, Spinner } from "reactstrap";
import Title from "../../components/Title";
import DollarIco from "../../assets/imgs/icons/19-$ Icon.png";
import HeaderImg from "../../assets/imgs/bg-cover.jpg";

type Props = {};

const Launchpad = (props: Props) => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${HeaderImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className="sub-page-header"
      ></div>
      <div className="statement main-p">
        <Container className="px-0">
          <Title title="LAUNCHPAD" />
          <Row>
            <Col>
              <div className="card h-100">
                <div className="card__header border-radius-top">
                  <h2 className="text-center card__body-heading mb-0">
                    Launchpad
                  </h2>
                </div>
                <div className="card__body border-radius-bottom">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/linkdao-defi.appspot.com/o/my_images%2F1696446942542-.jpg?alt=media&token=6396c8ef-4b3e-483e-882d-e72421edccf4"
                    alt="blog_img"
                    className="img-fluid rounded mb-2"
                  />
                  <p className="card__body-note text-muted mt-2 mb-2">
                    In the context of cryptocurrencies and blockchain, a
                    "launchpad" typically refers to a platform or service that
                    facilitates the launch of new cryptocurrency projects,
                    including Initial Coin Offerings (ICOs) or token sales.
                    LaunchPad serve as a platform for fundraising and token
                    distribution, often providing a range of services to assist
                    projects in launching successfully. These platforms serve as
                    a launchpad for cryptocurrency startups, helping them raise
                    capital and gain exposure to potential investors and users.
                    Here are the key components and functions of a crypto
                    launchpad:
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Token Sales:{" "}
                    </strong>
                    Launchpads enable cryptocurrency projects to raise capital
                    by conducting token sales or ICOs. These sales involve
                    offering a new digital token to investors and supporters in
                    exchange for other cryptocurrencies (such as Bitcoin or
                    Ethereum) or fiat currency.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Smart Contracts:{" "}
                    </strong>
                    Many launchpads use smart contracts on blockchain platforms
                    like Ethereum to automate the token sale process, including
                    the issuance and distribution of tokens to contributors.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Due Diligence:{" "}
                    </strong>
                    Some launchpads conduct due diligence on projects before
                    allowing them to launch on their platform. This helps reduce
                    the likelihood of fraudulent or low-quality projects.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Community Building:{" "}
                    </strong>
                    Launchpads often provide tools and resources to help
                    cryptocurrency projects build and engage with their
                    communities. This can include marketing, social media, and
                    community management support.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Token Distribution:{" "}
                    </strong>
                    After a successful token sale, launchpads assist in
                    distributing tokens to investors and supporters. This
                    process may include vesting schedules or other mechanisms to
                    align the interests of project teams with those of token
                    holders.{" "}
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">Liquidity: </strong>
                    Some launchpads also provide liquidity solutions, allowing
                    token holders to trade their tokens on cryptocurrency
                    exchanges shortly after the token sale concludes.{" "}
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Governance:{" "}
                    </strong>
                    Launchpads may integrate governance features that allow
                    token holders to participate in project decisions, such as
                    protocol upgrades or changes to token economics.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Token Utility:{" "}
                    </strong>
                    The tokens issued through launchpads typically have a
                    specific utility within the project's ecosystem, such as
                    providing access to services or participating in network
                    governance.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">Examples: </strong>
                    Examples of cryptocurrency launchpads include Binance
                    Launchpad, Polkastarter, TrustPad, and others, each with its
                    own set of features and criteria for project selection.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-site-secondary">
                    Benefits of early access to participate in crypto launchpad:
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    Participating in a crypto launchpad early access sale can
                    offer several potential benefits to investors and
                    supporters. Early access typically means getting in on a
                    token sale before it becomes available to the broader
                    public. Here are some advantages of early access to
                    participate in a crypto launchpad:
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Discounted Token Prices:{" "}
                    </strong>
                    Many crypto projects offer early backers discounted token
                    prices compared to the public sale or the market price after
                    listing on exchanges. Early access participants may receive
                    tokens at a lower cost, potentially allowing for greater
                    returns if the token's value appreciates.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Limited Supply:{" "}
                    </strong>
                    Early access sales often have a limited allocation of tokens
                    available. This scarcity can create a sense of urgency, as
                    participants compete for a relatively small number of
                    tokens, potentially driving up demand and value.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Early Investment:{" "}
                    </strong>
                    Being among the first to invest in a project through a
                    launchpad can provide the opportunity to support and
                    contribute to the development of a promising blockchain
                    project from its early stages.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Exclusivity:{" "}
                    </strong>
                    Early access participants often receive a sense of
                    exclusivity and participation in a project's community. They
                    may have access to exclusive updates, events, and
                    communication channels with project teams.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Potential for Higher Returns:{" "}
                    </strong>
                    If a project gains traction and its token appreciates in
                    value, early access participants may benefit from
                    substantial returns on their investment, especially if they
                    purchased tokens at a lower price.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Participation in Governance:{" "}
                    </strong>
                    Some projects grant early backers additional privileges,
                    such as participation in governance decisions, allowing them
                    to have a say in the project's future development and
                    direction.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Reduced Risk of Missing Out:{" "}
                    </strong>
                    Early access helps reduce the risk of missing out on a
                    potentially successful project. In the competitive crypto
                    space, valuable tokens can sell out quickly during public
                    sales.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Early Adoption:{" "}
                    </strong>
                    Early access participants can use and interact with a
                    project's products or services before they become widely
                    available. This firsthand experience can be valuable for
                    understanding a project's utility and potential.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    <strong className="text-site-secondary">
                      Community Building:{" "}
                    </strong>
                    Early backers often form the core community of a project.
                    Being part of this community can provide networking
                    opportunities and access to like-minded individuals who
                    share an interest in the project's success.
                  </p>
                  <p className="card__body-note mt-2 mb-2 text-muted">
                    It's important to note that while early access to a crypto
                    launchpad can offer these benefits, it also comes with
                    risks. Investing in early-stage projects, especially in the
                    cryptocurrency space, carries inherent risks, including the
                    potential for loss of capital if the project does not
                    succeed or if it turns out to be fraudulent. Due diligence
                    and research are critical before participating in any early
                    access token sale, and it's essential to understand the
                    project's fundamentals, team, technology, and market
                    potential. Additionally, investors should be aware of and
                    comply with relevant legal and regulatory requirements in
                    their jurisdiction.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Launchpad;
