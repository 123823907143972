import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import Swal from "sweetalert2";

var bonanzaRepo = RepositoryFactory.get("bonanza");

export const checkEligibility = createAsyncThunk(
  "bonanza/check_eligibility",
  async (_, thunkAPI: any) => {
    try {
      const data = await bonanzaRepo.checkEligibility();
      if (data.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const submitDocs = createAsyncThunk(
  "bonanza/submit_docs",
  async ({ payload, onSuccess }: any, thunkAPI: any) => {
    try {
      const data = await bonanzaRepo.submitDocs(payload);
      if (data.status == 201) {
        Swal.fire({
          text: "Your Request Submitted!",
          title: "Success",
          confirmButtonText: "Ok",
          icon: "success",
        });
        onSuccess();
        // return { data: data?.data };
      }
    } catch (error: any) {
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDataOfBusinessById = createAsyncThunk(
  "bonanza/get_data_by_id",
  async (payload: any, thunkAPI: any) => {
    try {
      const data = await bonanzaRepo.getDataOfBusinessById(payload);
      console.log(data);
      if (data.status === 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const setVisaUrl = createAsyncThunk(
  "bonanza/set_visa_url",
  async (payload, thunkAPI: any) => {
    try {
      const data = await bonanzaRepo.setVisaUrl(payload);
      if (data.status === 201) {
        // Perform any necessary actions on success
        return { data: data?.data };
      }
    } catch (error: any) {
      // Handle the error appropriately
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAllVisaUrl = createAsyncThunk(
  "bonanza/get_all_visa_url",
  async (_, thunkAPI: any) => {
    try {
      const data = await bonanzaRepo.getAllVisaUrl();
      if (data.status === 200) {
        // Perform any necessary actions on success
        return { data: data?.data };
      }
    } catch (error: any) {
      // Handle the error appropriately
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAllTicketUrl = createAsyncThunk(
  "bonanza/get_all_ticket_url",
  async (_, thunkAPI: any) => {
    try {
      const data = await bonanzaRepo.getAllTicketUrl();
      if (data.status === 200) {
        // Perform any necessary actions on success
        return { data: data?.data };
      }
    } catch (error: any) {
      // Handle the error appropriately
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getImagesByUserId = createAsyncThunk(
  "bonanza/get_images_by_user_id",
  async (userId: any, thunkAPI: any) => {
    try {
      const data = await bonanzaRepo.getImageByUserId(userId);
      if (data.status === 200) {
        // Perform any necessary actions on success
        return { data: data?.data };
      }
    } catch (error: any) {
      // Handle the error appropriately
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  bonanzaLoader: false,
  isEligible: undefined,
  businessData: null,
  imagesById: null,
};
const BonanzaSlice = createSlice({
  name: "bonanza",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(checkEligibility.pending, (state: any, _action: any) => {
      state.bonanzaLoader = true;
    });
    builder.addCase(checkEligibility.fulfilled, (state: any, _action: any) => {
      state.bonanzaLoader = false;
      state.isEligible = _action?.payload?.data?.data;
    });
    builder.addCase(checkEligibility.rejected, (state: any, _action: any) => {
      state.bonanzaLoader = false;
    });
    builder.addCase(submitDocs.pending, (state: any, _action: any) => {
      state.bonanzaLoader = true;
    });
    builder.addCase(submitDocs.fulfilled, (state: any, _action: any) => {
      state.bonanzaLoader = false;
    });
    builder.addCase(submitDocs.rejected, (state: any, _action: any) => {
      state.bonanzaLoader = false;
    });
    builder.addCase(getDataOfBusinessById.pending, (state) => {
      state.bonanzaLoader = true;
    });
    builder.addCase(getDataOfBusinessById.fulfilled, (state, action) => {
      state.bonanzaLoader = false;
      state.businessData = action?.payload?.data?.response;
    });
    builder.addCase(getDataOfBusinessById.rejected, (state) => {
      state.bonanzaLoader = false;
    });
    builder.addCase(setVisaUrl.pending, (state) => {
      state.bonanzaLoader = true;
    });
    builder.addCase(setVisaUrl.fulfilled, (state, action) => {
      state.bonanzaLoader = false;
      // Perform any necessary state updates on success
    });
    builder.addCase(setVisaUrl.rejected, (state) => {
      state.bonanzaLoader = false;
    });
    builder.addCase(getAllVisaUrl.pending, (state) => {
      state.bonanzaLoader = true;
    });
    builder.addCase(getAllVisaUrl.fulfilled, (state, action) => {
      state.bonanzaLoader = false;
      // Perform any necessary state updates on success
    });
    builder.addCase(getAllVisaUrl.rejected, (state) => {
      state.bonanzaLoader = false;
    });

    builder.addCase(getAllTicketUrl.pending, (state) => {
      state.bonanzaLoader = true;
    });
    builder.addCase(getAllTicketUrl.fulfilled, (state, action) => {
      state.bonanzaLoader = false;
      // Perform any necessary state updates on success
    });
    builder.addCase(getAllTicketUrl.rejected, (state) => {
      state.bonanzaLoader = false;
    });
    builder.addCase(getImagesByUserId.pending, (state) => {
      state.bonanzaLoader = true;
    });
    builder.addCase(getImagesByUserId.fulfilled, (state, action) => {
      state.bonanzaLoader = false;
      state.imagesById = action?.payload?.data;
      // Perform any necessary state updates on success
    });
    builder.addCase(getImagesByUserId.rejected, (state) => {
      state.bonanzaLoader = false;
    });
  },
  reducers: {},
});

export default BonanzaSlice.reducer;
