import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import Swal from "sweetalert2";
import { isJsonString } from "../../utils/utils";

var bannerRepo = RepositoryFactory.get("banner");

export const getAllBanners = createAsyncThunk(
  "banner/get_all_banners",
  async (_, thunkAPI: any) => {
    try {
      const data = await bannerRepo.getAllBanners();
      if (data.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  fetchBannerLoader: false,
  banners: isJsonString(localStorage?.getItem("banners") || "")
    ? JSON.parse(localStorage?.getItem("banners") || "[]")
    : [],
};
const bannerSlice = createSlice({
  name: "banner",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllBanners.pending, (state: any, _action: any) => {
      state.fetchBannerLoader = true;
    });
    builder.addCase(getAllBanners.fulfilled, (state: any, _action: any) => {
      state.fetchBannerLoader = false;
      state.banners = _action?.payload?.data?.data;
      localStorage?.setItem(
        "banners",
        JSON.stringify(_action?.payload?.data?.data)
      );
    });
    builder.addCase(getAllBanners.rejected, (state: any, _action: any) => {
      state.fetchBannerLoader = false;
    });
  },
  reducers: {},
});

export default bannerSlice.reducer;
