import React, { useState, useEffect } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getStarRoyalty } from "../../store/slices/userSlice";
import NoDataFound from "../NoDataFound";
import moment from "moment";

type Props = {};

const LinkDaoStarRoyalty = (props: Props) => {
  const dispatch = useDispatch<any>();
  const { star_royalty, rewardLoader } = useSelector(
    (state: any) => state.user
  );

  console.log(star_royalty);

  const [itemsPerPage, setItemsPerPage] = useState(14);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (event: any) => {
    setCurrentPage(event?.selected);
  };

  useEffect(() => {
    setPageCount(0);
    dispatch(getStarRoyalty({ offset: 0, limit: 14 }));
  }, []);

  return (
    <div className=" main-p carousel-top bg-site-bg">
      <Container className="px-0 px-md-2">
        <div className="earning__title-card">
          <div className="earning__title-card__header mt-42">
            LinkDao Star Royalty
          </div>
          <div className="earning__title-card__body d-flex justify-content-between align-items-center pointer">
            <div>
              {" "}
              <h2 className="dashboard__card__heading mb-1">Total Earning</h2>
              <h2 className="dashboard__card__value mb-0 text-site-primary number">
                $
                {rewardLoader ? (
                  <Spinner size="sm" />
                ) : star_royalty?.totalEarning ? (
                  Number(star_royalty?.totalEarning)?.toFixed(3)
                ) : (
                  0
                )}
              </h2>
            </div>
            <div className="text-end">
              {" "}
              <h2 className="dashboard__card__heading mb-1">Today's Earning</h2>
              <h2 className="dashboard__card__value mb-0 text-site-primary number">
                $
                {rewardLoader ? (
                  <Spinner size="sm" />
                ) : star_royalty?.todayEarning ? (
                  Number(star_royalty?.todayEarning)?.toFixed(3)
                ) : (
                  0
                )}
              </h2>
            </div>
          </div>
        </div>
        <Row>
          {star_royalty?.userRoyality?.length > 0 ? (
            star_royalty?.userRoyality?.map((item: any, idx: number) => (
              <Col md="4" className="mt-2" key={idx}>
                <div className="small-card h-100">
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="performance-value mb-0">
                      Linkdao {item?.name}
                    </h2>
                    {/* <h2 className="performance-value mb-0">
                      {item?.ach_date
                        ? moment(item?.user_royalty_ach_date).format(
                            "YYYY-DD-MM hh:mm:ss A"
                          )
                        : "NA"}
                    </h2> */}
                  </div>
                  <div className="d-flex mt-1 align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <h2 className="performance-value mb-0">Total Royalty</h2>
                    </div>
                    <h2 className="performance-value mb-0">
                      ${item?.amt?.toFixed(3) || 0}
                    </h2>
                  </div>
                  <div className="d-flex mt-1 align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <h2 className="performance-value mb-0">
                        Today's Royalty
                      </h2>
                    </div>
                    <h2 className="performance-value mb-0 ">
                      ${item?.todayAmt?.toFixed(3) || 0}
                    </h2>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <NoDataFound isLoading={rewardLoader} />
          )}
        </Row>
      </Container>
    </div>
  );
};

export default LinkDaoStarRoyalty;
