import React, { useState } from "react";
import { Button, Col, Container, Form, Input, Row, Spinner } from "reactstrap";
import Title from "../Title";
import DollarIco from "../../assets/imgs/icons/19-$ Icon.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getWallet,
  transferLiveBalanceToLeverage,
} from "../../store/slices/userSlice";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import SpinnerImg from "../../assets/imgs/spinner.svg";
import Swal from "sweetalert2";
import {
  lindaoTokenContractAddress,
  linkdaoOracleABI,
  linkdaoOracleAddress,
  linkdaoTokenABI,
  linkdaoWalletABI,
  linkdaoWalletAddress,
} from "../../utils/config";
import { useAccount, useContractRead, useContractWrite } from "wagmi";
import { writeContract, readContract, waitForTransaction } from "@wagmi/core";
import { formatUnits } from "viem";
import { fromWei, toWei } from "../../utils/utils";
import userRepository from "../../repository/userRepository";

type Props = {};

const FundTransfer = (props: Props) => {
  const { address } = useAccount();
  const [buyLeverageInLkd, setReBuyLeverageInLkd] = useState(0);
  const [topupLeverage, setTopupLeverage] = useState<any>(0);
  const [stakeLoader, setStakeLoader] = useState(false);
  const [approveLoader, setApproveLoader] = useState(false);
  const { lkdPrice, wallet, fundTransferLoader, reBuyLeverageLoader } =
    useSelector((state: any) => state?.user);
  const [fundTransfer, setFundTransfer] = useState(0);

  const { data: lkdBalance, isLoading: lkdLoading } = useContractRead({
    address: lindaoTokenContractAddress,
    abi: linkdaoTokenABI,
    functionName: "balanceOf",
    args: [address],
  });

  const dispatch = useDispatch<any>();

  const { data: lkdPriceInOracle } = useContractRead({
    address: linkdaoOracleAddress,
    abi: linkdaoOracleABI,
    functionName: "getPrice",
    args: [0],
    watch: true,
  });

  const approveAmount = async (stakeValue: any, stakeValueInUSD: any) => {
    try {
      if (!stakeValue) {
        Swal.fire({
          text: "Please enter stake amount",
          title: "Error",
          confirmButtonText: "Ok",
          icon: "error",
        });
        return;
      }
      setApproveLoader(true);

      if (Number(lkdPriceInOracle) / 100 !== Number(lkdPrice?.toFixed(2))) {
        console.log("updating price from", lkdPriceInOracle, "to", lkdPrice);
        userRepository.updateLkdPrice().catch((err: any) => {
          console.log("err", err);
        });
        const priceUpdated = await userRepository.updatePrice().catch((err) => {
          console.log("err", err);
        });
        console.log("priceUpdated", priceUpdated);

        stakeValue = Number(stakeValueInUSD) / Number(lkdPrice?.toFixed(2));
        setReBuyLeverageInLkd(stakeValue);
      } else {
        console.log("Price is already updated");
      }

      const allowanceData: any = await readContract({
        address: lindaoTokenContractAddress,
        abi: linkdaoTokenABI,
        functionName: "allowance",
        args: [address, linkdaoWalletAddress],
      });

      const stakeValueInEth = Number(stakeValue + stakeValue * 0.1) * 10 ** 18;
      if (Number(formatUnits(allowanceData, 18)) < Number(stakeValue)) {
        const { hash } = await writeContract({
          address: lindaoTokenContractAddress,
          abi: linkdaoTokenABI,
          functionName: "approve",
          args: [linkdaoWalletAddress, stakeValueInEth],
        });

        const receipt = await waitForTransaction({
          confirmations: 20,
          hash: hash,
        });

        console.log("Approve hash", hash);
        console.log("Approve receipt", receipt);

        refetchAllowance();
      }
      setApproveLoader(false);
      Swal.fire({
        text: "Approve successful",
        title: "Success",
        confirmButtonText: "Ok",
        icon: "success",
      });
    } catch (err: any) {
      setApproveLoader(false);
      console.log(err);
      Swal.fire({
        text: "Something went wrong!",
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
    }
  };

  const topupLeverageHandlerFunction = async (
    lkdValue: any,
    valueInUsd: any
  ) => {
    try {
      if (!lkdValue) {
        Swal.fire({
          text: "Please enter linkdao amount",
          title: "Error",
          confirmButtonText: "Ok",
          icon: "error",
        });
        return;
      }

      // const priceUpdateRes = await fetch(
      //   "https://oracal.linkdaodefi.network/updatePrice"
      // );
      setStakeLoader(true);

      if (Number(lkdPriceInOracle) / 100 !== Number(lkdPrice?.toFixed(2))) {
        console.log("updating price from", lkdPriceInOracle, "to", lkdPrice);
        userRepository.updateLkdPrice().catch((err: any) => {
          console.log("err", err);
        });
        const priceUpdated = await userRepository.updatePrice().catch((err) => {
          console.log("err", err);
        });
        console.log("priceUpdated", priceUpdated);

        lkdValue = Number(valueInUsd) / Number(lkdPrice?.toFixed(2));
        setReBuyLeverageInLkd(lkdValue);
      } else {
        console.log("Price is already updated");
      }

      const allowanceData: any = await readContract({
        address: lindaoTokenContractAddress,
        abi: linkdaoTokenABI,
        functionName: "allowance",
        args: [address, linkdaoWalletAddress],
      });
      const stakeValueInEth = Number(lkdValue) * 10 ** 18;

      // if (Number(formatUnits(allowanceData, 18)) < Number(stakeValue)) {
      // throw new Error("Please approve first");
      // }

      const { hash } = await writeContract({
        address: linkdaoWalletAddress,
        abi: linkdaoWalletABI,
        functionName: "topupLeverage",
        args: [toWei(lkdValue.toString())],
      });

      console.log("Stake hash", hash);

      const receipt = await waitForTransaction({
        confirmations: 10,
        hash: hash,
      });
      console.log("Stake receipt", receipt);

      refetchAllowance();
      dispatch(getWallet());
      setTopupLeverage(0);
      setReBuyLeverageInLkd(0);

      setStakeLoader(false);
    } catch (err: any) {
      console.log(err);
      Swal.fire({
        text: "Something went wrong!",
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      setStakeLoader(false);
      throw err;
    }
  };

  const { data: totalAllowance, refetch: refetchAllowance }: any =
    useContractRead({
      address: lindaoTokenContractAddress,
      abi: linkdaoTokenABI,
      functionName: "allowance",
      args: [address, linkdaoWalletAddress],
    });

  const { write: topupLeverageHandler, isLoading: topupLeverageLoading } =
    useContractWrite({
      address: linkdaoWalletAddress,
      abi: linkdaoWalletABI,
      functionName: "topupLeverage",
      args: [toWei(topupLeverage.toString())],
    });

  return (
    <LoadingOverlayWrapper
      active={
        topupLeverageLoading ||
        approveLoader ||
        fundTransferLoader ||
        stakeLoader
      }
      text="Please Wait"
      spinner={<img src={SpinnerImg} alt="spinner" />}
    >
      <div className="fund-transfer main-p carousel-top bg-site-bg">
        <Container className="px-0">
          <Title title="Fund Transfer to Leverage Wallet" />
          <Row className="mt-2">
            <Col md="6">
              <div className="small-card-ns d-flex justify-content-between align-items-center pointer ">
                <div>
                  {" "}
                  <h2 className="dashboard__card__heading mb-1">
                    Leverage Wallet Balance
                  </h2>
                  <h2 className="dashboard__card__value mb-0 number">
                    $
                    {wallet?.leverage
                      ? Number(wallet?.leverage)?.toFixed(3)
                      : 0 || 0}
                  </h2>
                </div>
                <img src={DollarIco} alt="dollar" height="35px" />
              </div>
            </Col>
            <Col md="6" className="mt-2 mt-md-0">
              <div className="small-card-ns d-flex justify-content-between align-items-center pointer ">
                <div>
                  {" "}
                  <h2 className="dashboard__card__heading mb-1">
                    Withdrawal Wallet Balance
                  </h2>
                  <h2 className="dashboard__card__value mb-0 number">
                    $
                    {wallet?.balance
                      ? Number(wallet?.balance)?.toFixed(3)
                      : 0 || 0}
                  </h2>
                </div>
                <img src={DollarIco} alt="dollar" height="35px" />
              </div>
            </Col>
          </Row>

          <div className="with mt-2 text-center small-card-ns">
            <div className="d-flex align-items-center justify-content-between mt-2">
              <Input
                placeholder="0"
                className="me-2"
                required
                max={Number(wallet?.balance)}
                value={fundTransfer}
                onChange={(e: any) => {
                  setFundTransfer(e.target.value);
                }}
              />
            </div>
            <div className="text-center mt-3 mb-2">
              <Button
                className="btn__primary-sm px-5"
                disabled={fundTransfer < 100}
                onClick={() => {
                  dispatch(
                    transferLiveBalanceToLeverage({
                      payload: fundTransfer,
                      onSuccess: () => {
                        setFundTransfer(0);
                        dispatch(getWallet());
                      },
                    })
                  );
                }}
              >
                Transfer
              </Button>
              <p className="mt-2 mb-0 withdraw-card__body-note">
                Note: Minimum Transfer $100
              </p>
            </div>
          </div>
          <Row className="mt-2">
            <Col className="">
              <div className="small-card-ns pointer">
                {" "}
                <h2 className="dashboard__card__heading mb-1">
                  My Dapp Wallet Balance
                </h2>
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="dashboard__card__value mb-0">
                    ${(fromWei(Number(lkdBalance)) * lkdPrice)?.toFixed(3) || 0}
                  </h2>
                  <h2 className="dashboard__card__value mb-0">
                    {fromWei(Number(lkdBalance))?.toFixed(3) || 0} LKD
                  </h2>
                </div>
              </div>
            </Col>
          </Row>
          <div className="with mt-2 text-center small-card-ns">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                fromWei(totalAllowance?.toString()) >= topupLeverage
                  ? topupLeverageHandlerFunction(
                      buyLeverageInLkd,
                      topupLeverage
                    )
                  : approveAmount(topupLeverage * lkdPrice, topupLeverage);
              }}
            >
              <div className="d-flex align-items-center justify-content-between mt-2">
                <Input
                  placeholder="0"
                  className="me-2"
                  type="number"
                  min={1}
                  step={1}
                  value={topupLeverage}
                  onChange={(e) => {
                    setTopupLeverage(e?.target?.value);
                    setReBuyLeverageInLkd(Number(e?.target?.value) / lkdPrice);
                  }}
                  required
                />
              </div>
              <div className="text-center mt-3 mb-2">
                <Button
                  className="btn__primary-sm px-5"
                  disabled={
                    topupLeverageLoading || approveLoader || stakeLoader
                  }
                  type="submit"
                >
                  {topupLeverageLoading || approveLoader || stakeLoader ? (
                    <Spinner size="sm" />
                  ) : fromWei(totalAllowance?.toString()) >= topupLeverage ? (
                    "Topup Leverage Wallet"
                  ) : (
                    "Approve "
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </LoadingOverlayWrapper>
  );
};

export default FundTransfer;
