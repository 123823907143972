import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import userReducer from "./slices/userSlice";
import bannerSlice from "./slices/bannerSlice";
import annoucementSlice from "./slices/annoucementSlice";
import articleSlice from "./slices/articleSlice";
import downloadSlice from "./slices/downloadSlice";
import faqSlice from "./slices/faqSlice";
import bonanzaSlice from "./slices/bonanzaSlice";
const combinedReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  banner: bannerSlice,
  annoucement: annoucementSlice,
  article: articleSlice,
  download: downloadSlice,
  faqs: faqSlice,
  bonanza: bonanzaSlice,
});
const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout/fulfilled") {
    state = undefined;
  }
  return combinedReducer(state, action);
};
export const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
});
