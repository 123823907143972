import Repository from "./Repository";

const GET_ALL_BANNERS = "/banner/getAllBanners";
const GET_BANNER_BY_ID = "/banner/getBannerById";

export default {
  getAllBanners() {
    return Repository.get(GET_ALL_BANNERS);
  },

  getBannerById(id: number) {
    return Repository.get(`${GET_BANNER_BY_ID}/${id}`);
  },
};
