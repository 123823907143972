import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import Swal from "sweetalert2";

var downloadRepo = RepositoryFactory.get("download");

export const getAllDownloads = createAsyncThunk(
  "download/get_all_downloads",
  async (_, thunkAPI: any) => {
    try {
      const data = await downloadRepo.getAllDownloads();
      if (data.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { fetchDownloadLoader: false, downloads: [] };
const downloadSlice = createSlice({
  name: "download",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllDownloads.pending, (state: any, _action: any) => {
      state.fetchDownloadLoader = true;
    });
    builder.addCase(getAllDownloads.fulfilled, (state: any, _action: any) => {
      state.fetchDownloadLoader = false;
      state.downloads = _action?.payload?.data?.data;
    });
    builder.addCase(getAllDownloads.rejected, (state: any, _action: any) => {
      state.fetchDownloadLoader = false;
    });
  },
  reducers: {},
});

export default downloadSlice.reducer;
