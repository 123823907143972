import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

type Props = {};

const AuthLayout = (props: Props) => {
  const account = useAccount();
  const navigate = useNavigate();
  const { token } = useSelector((state: any) => state.auth);
  if (account?.status == "connected" && token) {
    navigate("/admin/index");
  }
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default AuthLayout;
