import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import Swal from "sweetalert2";

var article = RepositoryFactory.get("article");

export const getAllArticles = createAsyncThunk(
  "article/get_all_articles",
  async (_, thunkAPI: any) => {
    try {
      const data = await article.getAllArticles();
      if (data.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAllCategories = createAsyncThunk(
  "article/get_all_categories",
  async (_, thunkAPI: any) => {
    try {
      const data = await article.getAllCategories();
      if (data.status == 200) {
        return { data: data?.data };
      }
    } catch (error: any) {
      Swal.fire({
        text: error?.response?.data?.message,
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  fetchArticleLoader: false,
  articles: [],
  categories: [],
};
const articleSlice = createSlice({
  name: "article",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllArticles.pending, (state: any, _action: any) => {
      state.fetchArticleLoader = true;
    });
    builder.addCase(getAllArticles.fulfilled, (state: any, _action: any) => {
      state.fetchArticleLoader = false;
      state.articles = _action?.payload?.data?.data;
    });
    builder.addCase(getAllArticles.rejected, (state: any, _action: any) => {
      state.fetchArticleLoader = false;
    });
    //getAllCategories
    builder.addCase(getAllCategories.pending, (state: any, _action: any) => {
      state.fetchArticleLoader = true;
    });
    builder.addCase(getAllCategories.fulfilled, (state: any, _action: any) => {
      state.fetchArticleLoader = false;
      state.categories = _action?.payload?.data?.data;
    });
    builder.addCase(getAllCategories.rejected, (state: any, _action: any) => {
      state.fetchArticleLoader = false;
    });
  },
  reducers: {},
});

export default articleSlice.reducer;
