import React, { useState, useEffect } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import Title from "./Title";
import DollorIco from "../assets/imgs/icons/19-$ Icon.png";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "./NoDataFound";
import { getBusiness } from "../store/slices/userSlice";
import ReactPaginate from "react-paginate";

import SpinnerImg from "../assets/imgs/spinner.svg";
import LoadingOverlayWrapper from "react-loading-overlay-ts";

type Props = {};

const Business = (props: Props) => {
  const dispatch = useDispatch<any>();
  const { business, businessLoader } = useSelector((state: any) => state.user);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (event: any) => {
    setCurrentPage(event?.selected);
  };

  useEffect(() => {
    let newItems = Number(business?.response?.directTeamMember) / itemsPerPage;
    setPageCount(newItems);
  }, [business]);

  const fetchUpdatedData = () => {
    const startIndex = currentPage * itemsPerPage;
    dispatch(getBusiness({ offset: startIndex, limit: itemsPerPage }));
  };

  useEffect(() => {
    fetchUpdatedData();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    setPageCount(0);
  }, []);

  return (
    <LoadingOverlayWrapper
      active={businessLoader}
      text="Please Wait"
      spinner={<img src={SpinnerImg} alt="spinner" />}
    >
      <div className="business main-p carousel-top bg-site-bg">
        <Container className="px-0 px-md-2">
          <Title title="Business Statistics" />

          <Row className="mt-2">
            <Col md="4">
              <div className="card">
                <div className="card__body border-radius-all">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0 filter__card-label">
                        Direct Team Partners
                      </p>
                      <h6 className="mb-0  text-site-primary number">
                        {" "}
                        {businessLoader ? (
                          <Spinner size="sm" />
                        ) : business?.response?.directTeamMember ? (
                          business?.response?.directTeamMember
                        ) : (
                          0
                        )}
                      </h6>
                    </div>
                    <img src={DollorIco} alt="dollar" height="35px" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="card mt-2 mt-md-0">
                <div className="card__body border-radius-all">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0 filter__card-label">
                        Direct Inactive Team Partners
                      </p>
                      <h6 className="mb-0  text-site-primary number">
                        {" "}
                        {/* {businessLoader ? (
                        <Spinner size="sm" />
                      ) : business?.response?.total_business ? (
                        "$" +
                        Number(business?.response?.total_business)?.toFixed(3)
                      ) : (
                        0
                      )} */}
                        {businessLoader ? (
                          <Spinner size="sm" />
                        ) : business?.response?.totalInActiveUsers ? (
                          +Number(business?.response?.totalInActiveUsers)
                        ) : (
                          0
                        )}
                      </h6>
                    </div>
                    <img src={DollorIco} alt="dollar" height="35px" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="card mt-2 mt-md-0">
                <div className="card__body border-radius-all">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0 filter__card-label">
                        Today's Business
                      </p>
                      <h6 className="mb-0  text-site-primary number">
                        {" "}
                        $
                        {businessLoader ? (
                          <Spinner size="sm" />
                        ) : business?.response?.today ? (
                          Number(business?.response?.today)?.toFixed(3)
                        ) : (
                          0
                        )}
                      </h6>
                    </div>
                    <img src={DollorIco} alt="dollar" height="35px" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="card mt-2">
                <div className="card__body border-radius-all">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0 filter__card-label">
                        Top Team Business
                      </p>
                      <h6 className="mb-0  text-site-primary number">
                        {" "}
                        $
                        {businessLoader ? (
                          <Spinner size="sm" />
                        ) : business?.response?.top ? (
                          Number(business?.response?.top)?.toFixed(3)
                        ) : (
                          0
                        )}
                      </h6>
                    </div>
                    <img src={DollorIco} alt="dollar" height="35px" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="card mt-2">
                <div className="card__body border-radius-all">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0 filter__card-label">
                        2nd Top Team Business
                      </p>
                      <h6 className="mb-0  text-site-primary number">
                        {" "}
                        $
                        {businessLoader ? (
                          <Spinner size="sm" />
                        ) : business?.response?.secondTop ? (
                          Number(business?.response?.secondTop)?.toFixed(3)
                        ) : (
                          0
                        )}
                      </h6>
                    </div>
                    <img src={DollorIco} alt="dollar" height="35px" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="card mt-2">
                <div className="card__body border-radius-all">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0 filter__card-label">
                        Other Team Business
                      </p>
                      <h6 className="mb-0  text-site-primary number">
                        {" "}
                        $
                        {businessLoader ? (
                          <Spinner size="sm" />
                        ) : business?.response?.others ? (
                          Number(business?.response?.others)?.toFixed(3)
                        ) : (
                          0
                        )}
                      </h6>
                    </div>
                    <img src={DollorIco} alt="dollar" height="35px" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {business?.response?.arrayofuser?.length > 0 ? (
              <>
                {business?.response?.arrayofuser?.map(
                  (buss: any, idx: number) => (
                    <Col md="4" className="mt-2" key={idx}>
                      <div className="small-card h-100">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="mb-0 filter__card-label ">
                            Direct Associate {idx + 1}:{" "}
                            {buss?.user_lid ? buss?.user_lid : "N/A"}
                          </p>
                          <p className="mb-0 filter__card-label ">
                            Stake: $
                            {buss?.totalStake
                              ? Number(buss?.totalStake)?.toFixed(3)
                              : 0}
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <p className="mb-0 filter__card-label">
                            Total Team Business
                          </p>
                          <p className="mb-0 filter__card-label">
                            $
                            {buss?.totalBusiness
                              ? Number(buss?.totalBusiness)?.toFixed(3)
                              : 0}
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <p className="mb-0 filter__card-label">User ID</p>
                          <p className="mb-0 filter__card-label">
                            {buss?.user_lid ? buss?.user_lid : "N/A"}
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <p className="mb-0 filter__card-label">
                            Name of Associate
                          </p>
                          <p className="mb-0 filter__card-label">
                            {buss?.user_name ? buss?.user_name : "N/A"}
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <p className="mb-0 filter__card-label">
                            Contact Number
                          </p>
                          <p className="mb-0 filter__card-label">
                            {buss?.user_mobile ? buss?.user_mobile : "N/A"}
                          </p>
                        </div>
                      </div>
                    </Col>
                  )
                )}
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <i className="fas fa-angle-right text-site-primary" />
                  }
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={1}
                  pageCount={pageCount}
                  previousLabel={
                    <i className="fas fa-angle-left text-site-primary" />
                  }
                  containerClassName="custom-pagination"
                  activeClassName="custom-pagination-active"
                  previousClassName="custom-pagination-prev"
                  nextClassName="custom-pagination-next"
                  pageClassName="custom-pagination-li"
                  disabledClassName="custom-pagination-disabled"
                />
              </>
            ) : (
              <NoDataFound isLoading={businessLoader} />
            )}
          </Row>
        </Container>
      </div>
    </LoadingOverlayWrapper>
  );
};

export default Business;
