import { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import Title from "./Title";
import { useDispatch, useSelector } from "react-redux";
import { useAccount, useContractRead, useContractWrite } from "wagmi";
import { writeContract, readContract, waitForTransaction } from "@wagmi/core";
import { getStakingReward, getWallet } from "../store/slices/userSlice";
import { AiOutlineCheckCircle } from "react-icons/ai";
import {
  lindaoTokenContractAddress,
  linkdaoOracleAddress,
  linkdaoOracleABI,
  linkdaoStakingABI,
  linkdaoTokenABI,
  linkdaoTokenStakingContractAddress,
} from "../utils/config";
import { formatUnits } from "viem";
import { fromWei, toWei } from "../utils/utils";
import {
  getStakesData,
  getUserStakeSummary,
  stakeFromWallet,
} from "../store/slices/userSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import StakingReward from "./Earnings/StakingReward";
import userRepository from "../repository/userRepository";
import moment from "moment";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import SpinnerImg from "../assets/imgs/spinner.svg";
import Swal from "sweetalert2";

type Props = {};

const getPackageName = (amount: any) => {
  if (amount > 10 && amount < 100) {
    return "Basic";
  } else if (amount > 99 && amount < 200) {
    return "Standard";
  } else if (amount >= 200 && amount <= 499) {
    return "Super";
  } else if (amount > 499) {
    return "Premium";
  } else {
    return "NA";
  }
};

const Stakes = (props: Props) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const {
    lkdPrice,
    stakeLoader,
    staking_reward,
    earnings,
    fetchLoader,
    withdrawLoader,
  } = useSelector((state: any) => state?.user);

  const stakesData = useSelector(
    (state: any) => state?.user?.stake_info?.newStakeData
  );

  const { wallet } = useSelector((state: any) => state?.user);

  const { address } = useAccount();
  // const [approveLoader, setApproveLoader] = useState(false);
  // const [stakeIniLoader, setStakeIniLoader] = useState(false);

  const handleModalOpen = (hash: string) => {
    setMyHash(hash);
    setStakeModalOpen(true);
  };

  useEffect(() => {
    dispatch(getWallet());
    dispatch(getStakingReward({ offset: 0, limit: 50 }));
  }, []);
  const { data: lkdBalance, isLoading: lkdLoading } = useContractRead({
    address: lindaoTokenContractAddress,
    abi: linkdaoTokenABI,
    functionName: "balanceOf",
    args: [address],
  });

  const { data: totalWithdrawableProfit } = useContractRead({
    address: linkdaoTokenStakingContractAddress,
    abi: linkdaoStakingABI,
    functionName: "getWithdrawableTotalProfit",
    // functionName: staking_reward.sum,
    args: [address],
  });

  const { data: lkdPriceInOracle } = useContractRead({
    address: linkdaoOracleAddress,
    abi: linkdaoOracleABI,
    functionName: "getPrice",
    args: [0],
    watch: true,
  });

  const [stakeValue, setStakeValue] = useState<number>();
  const [stakeValueInUSD, setStakeValueInUSD] = useState<number>();
  const [referralValueInUSD, setReferalValueInUSD] = useState<number>();
  const [approveLoader, setApproveLoader] = useState<boolean>(false);
  const [stakeIniLoader, setStakeLoader] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [message, setMessage] = useState("Please wait...");
  const [stakeModalOpen, setStakeModalOpen] = useState<boolean>(false);
  const [myHash, setMyHash] = useState("");

  // console.log("refferralValueInUsd", referralValueInUSD);

  const { data: totalAllowance, refetch: refetchAllowance }: any =
    useContractRead({
      address: lindaoTokenContractAddress,
      abi: linkdaoTokenABI,
      functionName: "allowance",
      args: [address, linkdaoTokenStakingContractAddress],
    });

  const { write: withdrawReward, isLoading: withdrawRewardLoader } =
    useContractWrite({
      address: linkdaoTokenStakingContractAddress,
      abi: linkdaoStakingABI,
      functionName: "withdrawReward",
      args: [],
    });

  const { write: reinvestReward, isLoading: reinvestRewardLoader } =
    useContractWrite({
      address: linkdaoTokenStakingContractAddress,
      abi: linkdaoStakingABI,
      functionName: "reinvestReward",
      args: [],
    });

  const approveAmount = async (stakeValue: any, stakeValueInUSD: any) => {
    try {
      if (!stakeValue) {
        Swal.fire({
          text: "Please enter stake amount",
          title: "Error",
          confirmButtonText: "Ok",
          icon: "error",
        });
        return;
      }
      setApproveLoader(true);
      setModalOpen(true);

      if (Number(lkdPriceInOracle) / 100 !== Number(lkdPrice?.toFixed(2))) {
        setMessage("Fetching price");
        console.log("updating price from", lkdPriceInOracle, "to", lkdPrice);
        userRepository.updateLkdPrice().catch((err: any) => {
          console.log("err", err);
        });
        const priceUpdated = await userRepository.updatePrice().catch((err) => {
          console.log("err", err);
        });
        console.log("priceUpdated", priceUpdated);

        stakeValue = Number(stakeValueInUSD) / Number(lkdPrice?.toFixed(2));
        setStakeValue(stakeValue);
      } else {
        console.log("Price is already updated");
      }
      setMessage("Approving");

      const allowanceData: any = await readContract({
        address: lindaoTokenContractAddress,
        abi: linkdaoTokenABI,
        functionName: "allowance",
        args: [address, linkdaoTokenStakingContractAddress],
      });

      const stakeValueInEth = Number(stakeValue + stakeValue * 0.1) * 10 ** 18;
      if (Number(formatUnits(allowanceData, 18)) < Number(stakeValue)) {
        const { hash } = await writeContract({
          address: lindaoTokenContractAddress,
          abi: linkdaoTokenABI,
          functionName: "approve",
          args: [linkdaoTokenStakingContractAddress, stakeValueInEth],
        });

        const receipt = await waitForTransaction({
          confirmations: 20,
          hash: hash,
        });

        console.log("Approve hash", hash);
        console.log("Approve receipt", receipt);

        refetchAllowance();
      }
      setApproveLoader(false);
      setModalOpen(false);
      setMessage("please wait...");
      Swal.fire({
        text: "Approve successful",
        title: "Success",
        confirmButtonText: "Ok",
        icon: "success",
      });
    } catch (err: any) {
      setApproveLoader(false);
      setModalOpen(false);
      setMessage("please wait...");
      console.log(err);
      Swal.fire({
        text: "Something went wrong",
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
    }
  };

  const stakeAmount = async (stakeValue: any, stakeValueInUSD: any) => {
    try {
      if (!stakeValue) {
        Swal.fire({
          text: "Please enter stake amount",
          title: "Error",
          confirmButtonText: "Ok",
          icon: "error",
        });
        return;
      }
      setStakeLoader(true);

      // const priceUpdateRes = await fetch(
      //   "https://oracal.linkdaodefi.network/updatePrice"
      // );

      if (Number(lkdPriceInOracle) / 100 !== Number(lkdPrice?.toFixed(2))) {
        setMessage("Fetching price");

        console.log("updating price from", lkdPriceInOracle, "to", lkdPrice);
        userRepository.updateLkdPrice().catch((err: any) => {
          console.log("err", err);
        });
        const priceUpdated = await userRepository.updatePrice().catch((err) => {
          console.log("err", err);
        });
        console.log("priceUpdated", priceUpdated);

        stakeValue = Number(stakeValueInUSD) / Number(lkdPrice?.toFixed(2));
        setStakeValue(stakeValue);
      } else {
        console.log("Price is already updated");
      }

      setMessage("Staking");
      // console.log("priceUpdateRes", priceUpdateRes);

      setModalOpen(true);
      const allowanceData: any = await readContract({
        address: lindaoTokenContractAddress,
        abi: linkdaoTokenABI,
        functionName: "allowance",
        args: [address, linkdaoTokenStakingContractAddress],
      });

      const stakeValueInEth = Number(stakeValue) * 10 ** 18;

      if (Number(formatUnits(allowanceData, 18)) < Number(stakeValue)) {
        throw new Error("Please approve first");
      }

      const { hash } = await writeContract({
        address: linkdaoTokenStakingContractAddress,
        abi: linkdaoStakingABI,
        functionName: "investAmount",
        args: [toWei(stakeValueInUSD)],
      });

      console.log("Stake hash", hash);

      const receipt = await waitForTransaction({
        confirmations: 10,
        hash: hash,
      });
      console.log("Stake receipt", receipt);

      refetchAllowance();

      dispatch(getUserStakeSummary(address || ""));
      dispatch(getStakesData(address || ""));

      setStakeLoader(false);
      setModalOpen(false);
      setMessage("Please wait...");
      handleModalOpen(hash);
    } catch (err: any) {
      console.log(err);
      Swal.fire({
        text: "Something went wrong!",
        title: "Error",
        confirmButtonText: "Ok",
        icon: "error",
      });
      setStakeLoader(false);
      setMessage("Please wait...");
      setModalOpen(false);
      throw err;
    }
  };

  return (
    <LoadingOverlayWrapper
      active={reinvestRewardLoader}
      text="Please Wait"
      spinner={<img src={SpinnerImg} alt="spinner" />}
    >
      <div className="main-p carousel-top bg-site-bg">
        <Container className="stake px-0 px-md-2 ">
          <Title title="Stake Statistics" />

          <div className="card mt-2">
            <div className="card__body border-radius-all">
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="mb-0  header-text">LKD Live price</h6>
                <h6 className="mb-0  text-site-primary number">
                  ${Number(lkdPrice)?.toFixed(3)}
                </h6>
              </div>
            </div>
          </div>

          <div className="card mt-2">
            <div className="card__header border-radius-top">
              <h2 className="mb-0 card__body-heading text-center">
                My Address Balance
              </h2>
            </div>
            <div className="card__body border-radius-all">
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="mb-0  text-site-primary number">
                  {lkdLoading
                    ? "Loading..."
                    : lkdBalance
                    ? fromWei(Number(lkdBalance))?.toFixed(3)
                    : 0 + " LKD"}
                </h6>
                <h6 className="mb-0  text-site-primary number">
                  $
                  {lkdBalance && lkdPrice
                    ? (fromWei(Number(lkdBalance)) * Number(lkdPrice))?.toFixed(
                        3
                      )
                    : 0 || 0}
                </h6>
              </div>
            </div>
          </div>
          <Row className="mt-2">
            <Col md="6">
              <div className="card h-100">
                <div className="card__header border-radius-top">
                  <h2 className="text-center card__body-heading mb-0">
                    Stake Now
                  </h2>
                </div>
                <div className="card__body border-radius-bottom">
                  <div className="mt-2 d-flex align-items-center ">
                    <Input
                      placeholder="0"
                      className="me-3"
                      type="number"
                      value={stakeValueInUSD}
                      onChange={(e) => {
                        setStakeValueInUSD(Number(e?.target?.value));
                        setStakeValue(
                          Number(e?.target?.value) / Number(lkdPrice)
                        );
                      }}
                    />
                    <Input
                      placeholder="0.000 LKD (Approx.)"
                      disabled
                      type="number"
                      value={stakeValue?.toFixed(3)}
                    />
                  </div>
                  <div className="mt-3 text-center me-3">
                    {/* <Button
                      className="btn__primary-sm me-3"
                      onClick={() => {
                        approveAmount(
                          Number(stakeValue?.toFixed(3)) || 0,
                          stakeValueInUSD
                        );
                      }}
                      disabled={
                        Number(fromWei(Number(totalAllowance) || 0)) >=
                          Number(
                            (stakeValue || 0) + (stakeValue || 0) * 0.08
                          ) ||
                        approveLoader ||
                        !stakeValue ||
                        stakeIniLoader
                      }
                    >
                      {approveLoader ? "Approving..." : "Approve"}
                    </Button>
                    <Button
                      className="btn__primary-sm"
                      onClick={() => {
                        stakeAmount(
                          Number(stakeValue?.toFixed(3)) || 0,
                          stakeValueInUSD
                        );
                      }}
                      disabled={
                        Number(fromWei(Number(totalAllowance) || 0)) <
                          Number(
                            (stakeValue || 0) + (stakeValue || 0) * 0.08
                          ) ||
                        !stakeValue ||
                        stakeIniLoader
                      }
                    >
                      {stakeIniLoader ? "Staking..." : "Stake"}
                    </Button> */}
                    <p>
                      Please try again later. Staking is disabled for now.
                    </p>
                  </div>

                  <Row className="mt-3 mb-2">
                    <Col xs="3" className="stake-desc__wrapper text-center">
                      <p className="stake-desc__wrapper-title mb-0">Basic</p>
                      <p className="stake-desc__wrapper-price mb-0">$25-$99</p>
                      <p className="stake-desc__wrapper-reward mb-0">
                        6% Monthly
                      </p>
                    </Col>
                    <Col xs="3" className="stake-desc__wrapper text-center">
                      <p className="stake-desc__wrapper-title mb-0">Standard</p>
                      <p className="stake-desc__wrapper-price mb-0">
                        $100-$199
                      </p>
                      <p className="stake-desc__wrapper-reward mb-0">
                        8% Monthly
                      </p>
                    </Col>
                    <Col xs="3" className="stake-desc__wrapper text-center">
                      <p className="stake-desc__wrapper-title mb-0">Super</p>
                      <p className="stake-desc__wrapper-price mb-0">
                        $200-$499
                      </p>
                      <p className="stake-desc__wrapper-reward mb-0">
                        10% Monthly
                      </p>
                    </Col>
                    <Col
                      xs="3"
                      className="stake-desc__wrapper border-0 text-center"
                    >
                      <p className="stake-desc__wrapper-title mb-0">Premium</p>
                      <p className="stake-desc__wrapper-price mb-0">
                        $500 & Above
                      </p>
                      <p className="stake-desc__wrapper-reward mb-0">
                        12% Monthly
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            {/* <Col md="6" className="mt-2 mt-md-0">
              <div className="card h-100">
                <div className="card__header border-radius-top">
                  <h2 className="text-center card__body-heading mb-0">
                    Available Staking Reward
                  </h2>
                </div>
                <div className="card__body border-radius-bottom">
                  <div className="d-flex mt-2 align-items-center justify-content-between">
                    <h2 className="card__body-label">
                      Available Staking Reward
                    </h2>
                    <h2 className="card__body-label">
                      ${Number(earnings?.stacking_rewards)?.toFixed(3)} Approx{" "}
                    </h2>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <Button
                      className="btn__primary-sm"
                      block
                      disabled={Number(earnings?.stacking_rewards) < 10}
                      onClick={() => reinvestReward()}
                    >
                      {reinvestRewardLoader ? "Re-Staking..." : "Re-Stake Now"}
                    </Button>
                  </div>
                  <p className="mb-0 card__body-note text-center mt-2 mb-2">
                    Note: Re-Stake has no Service Fee, Min. Re-Stake $10
                  </p>
                </div>
              </div>
            </Col> */}
            {/* <Col md="6" className="mt-2">
              <div className="card h-100">
                <div className="card__header border-radius-top">
                  <h2 className="text-center card__body-heading mb-0">
                    Available Referral Reward
                  </h2>
                </div>
                <div className="card__body border-radius-bottom">
                  <div className="d-flex mt-2 align-items-center justify-content-between">
                    <h2 className="card__body-label">Referral Reward Stake</h2>
                    <h2 className="card__body-label">
                      $
                      {wallet?.balance
                        ? Number(wallet?.balance)?.toFixed(3)
                        : 0}
                    </h2>
                  </div>

                  <div className="mt-2 d-flex align-items-center ">
                    <Input
                      placeholder="0"
                      className="me-3"
                      type="number"
                      value={referralValueInUSD}
                      onChange={(e) => {
                        setReferalValueInUSD(Number(e?.target?.value));
                      }}
                    />
                  </div>
                 
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <Button
                      className="btn__primary-sm"
                      block
                      disabled={
                        stakeLoader ||
                        (referralValueInUSD !== undefined &&
                          referralValueInUSD > Number(wallet?.balance)) ||
                        Number(wallet?.balance) < 25
                      }
                      onClick={() =>
                        dispatch(
                          stakeFromWallet({
                            referralValueInUSD,
                            onSuccess: () => {
                              dispatch(getWallet());
                            },
                          })
                        )
                      }
                    >
                      {stakeLoader ? "Staking Now..." : "Stake Now"}
                    </Button>
                  </div>
                  <p className="mb-0 card__body-note text-center mt-2 mb-2">

                  </p>
                </div>
              </div>
            </Col> */}
            {stakesData?.map((item: any, index: number) => (
              <Col key={index} md="6" className="mt-2">
                <div className="card">
                  <div className="card__body border-radius-all">
                    <div className="d-flex mt-2 align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <h2 className="card__body-label me-1 mb-0">
                          {getPackageName(item?.busd_amount || 0)}
                        </h2>
                        <h2 className="card__body-price mb-0">
                          {/* ({item?.stakePackage?.message}) */}
                        </h2>
                      </div>
                      <h2 className="card__body-date mb-0">
                        {item?.date_time
                          ? moment
                              .utc(item?.date_time)
                              .format("DD MMM YYYY hh:mm:ss A")
                          : "NA"}
                      </h2>
                    </div>
                    <div className="d-flex mt-1 align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <h2 className="card__body-label text-site-gray me-1 mb-0">
                          Deposit:
                        </h2>
                        <h2 className="card__body-price mb-0">
                          ${item?.busd_amount?.toFixed(3)}
                        </h2>
                      </div>
                      <div className="d-flex align-items-center">
                        <h2 className="card__body-label text-site-gray me-1 mb-0">
                          Max Reward:
                        </h2>
                        <h2 className="card__body-price mb-0">
                          {" "}
                          ${(item?.busd_amount * 2)?.toFixed(3)}
                        </h2>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <Progress
                        value={(item?.income / (item?.busd_amount * 2)) * 100}
                        className="w-100 me-2"
                      />
                      <h2 className="card__body-price mb-0">
                        {" "}
                        {(
                          (item?.income / (item?.busd_amount * 2)) *
                          100
                        ).toFixed(2)}
                        %
                      </h2>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
        <Modal isOpen={modalOpen} centered>
          <LoadingOverlayWrapper
            active={stakeIniLoader || approveLoader}
            text={message}
            spinner={<img src={SpinnerImg} alt="spinner" />}
          ></LoadingOverlayWrapper>
        </Modal>
        <Modal isOpen={stakeModalOpen} centered>
          <Card>
            <CardBody>
              <Col className="d-flex justify-content-center">
                <AiOutlineCheckCircle size={50} color="green" />
              </Col>
              <Col className="d-flex justify-content-center">
                <h2>Transaction Successful</h2>
              </Col>
              <Col className="d-flex justify-content-center">
                <p className="viewTransaction">
                  {stakeValueInUSD} USDT deposited
                </p>
              </Col>
              <Col className="d-flex justify-content-center">
                <Button
                  color="primary"
                  className="stakeButton"
                  onClick={(e) => {
                    e.preventDefault();
                    setStakeModalOpen(false);
                    setStakeValueInUSD(0);
                    setStakeValue(0);
                    window.location.reload();
                  }}
                >
                  Ok
                </Button>
              </Col>
            </CardBody>
            <CardFooter className="d-flex justify-content-center">
              <p className="viewTransaction">
                <a
                  href={`https://bscscan.com/tx/${myHash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Transaction
                </a>
              </p>
            </CardFooter>
          </Card>
        </Modal>
      </div>
    </LoadingOverlayWrapper>
  );
};

export default Stakes;
