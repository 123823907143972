import Repository from "./Repository";

const GET_ALL_FAQS = "/faqs/getAllFaqs";
const CREATE_FAQ = "/faqs/createFaq";
const UPDATE_FAQ = "/faqs/updateFaq";
const DELETE_FAQ = "/faqs/deleteFaq";

export default {
  getAllFaqs() {
    return Repository.get(`${GET_ALL_FAQS}`);
  },

  createFaq(payload: any) {
    return Repository.post(`${CREATE_FAQ}`, payload);
  },

  updateFaq(id: any, payload: any) {
    return Repository.post(`${UPDATE_FAQ}/${id}`, payload);
  },

  deleteFaq(id: any) {
    return Repository.get(`${DELETE_FAQ}/${id}`);
  },
};
