import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import {
  Badge,
  Button,
  Col,
  Collapse,
  Container,
  Input,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

import DashboardBanner from "../../components/DashboardBanner";
import Logo from "../../assets/imgs/logo.svg";
import InviteIco from "../../assets/imgs/icons/INVITE.png";
import StakeIco from "../../assets/imgs/icons/STAKE.png";
import TeamIco from "../../assets/imgs/icons/TEAM.png";
import BusinessIco from "../../assets/imgs/icons/BUSINESS.png";
import EarningsIcon from "../../assets/imgs/icons/EARNING.png";
import ProfileIco from "../../assets/imgs/icons/PROFILE.png";
import NoficationIco from "../../assets/imgs/icons/16-Notification Icon.png";
import MoreIco from "../../assets/imgs/icons/MORE-1.png";
import AuditIco from "../../assets/imgs/icons/AUDIT.png";
import YTIco from "../../assets/imgs/icons/YOUTUBE.png";
import TwitterIco from "../../assets/imgs/icons/TWITTER.png";
import FbIco from "../../assets/imgs/icons/FACEBOOK.png";
import TgIco from "../../assets/imgs/icons/TELEGRAM.png";
import bon from "../../assets/imgs/icons/BONANZA.png";
import StatementIco from "../../assets/imgs/icons/STATEMENT.png";
import WalletIco from "../../assets/imgs/icons/WALLET.png";
import AreYouReady from "../../assets/imgs/are-you-ready.jpg";
import BTCIcon from "../../assets/imgs/icons/bitcoin.png";
import LKDIcon from "../../assets/imgs/L logo.png";
import BNBIcon from "../../assets/imgs/icons/binance.svg";
import EthIcon from "../../assets/imgs/icons/ethereum.png";
import html2canvas from "html2canvas";
import {
  getLeverageData,
  getLivePrices,
  getLkdPrice,
  getStakeInfo,
} from "../../store/slices/userSlice";
import moment from "moment";
import Marquee from "react-fast-marquee";

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import FAQs from "../../components/FAQs";
import Swal from "sweetalert2";
type Props = {};

const Dashboard = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { user, authLoading } = useSelector((state: any) => state.auth);
  const { articles, fetchArticleLoader, categories } = useSelector(
    (state: any) => state.article
  );
  const { banners } = useSelector((state: any) => state.banner);
  const { annoucements, fetchAnnoucementLoader } = useSelector(
    (state: any) => state.annoucement
  );
  const {
    leverageData,
    userStakeSummary,
    livePrices,
    fetchLoader,
    stake_info,
  } = useSelector((state: any) => state.user);
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [isMore, setIsMore] = useState(false);

  const InviteLink =
    window?.location?.href?.split("/admin/index")?.[0] +
    "?invitation-code=" +
    user?.user?.user_lid;

  const toggleInvite = () => {
    setIsInviteOpen(!isInviteOpen);
  };

  const toggleIsShare = () => {
    setIsShare(!isShare);
  };

  const toggleIsMore = () => {
    setIsMore(!isMore);
  };

  const coinSymbols: any = {
    binancecoin: "BNB",
    bitcoin: "BTC",
    ethereum: "ETH",
    linkdao: "LKD",
  };

  const getCoinSymbol = (name: string) => {
    return coinSymbols[name] || "";
  };

  const findCategory = (id: any) => {
    let obj = categories?.find((cat: any) => cat?.id == id);
    if (obj) {
      return obj?.title;
    } else {
      return "N/A";
    }
  };

  const sharePoster = () => {
    const input: any = document.getElementById("lkd-invitation");
    html2canvas(input, {
      // backgroundColor: null
    }).then(async (canvas: any) => {
      await saveAs(canvas.toDataURL(), "LKD Invitation.png");
    });
  };
  const saveAs = (uri: any, filename: string) => {
    var link = document.createElement("a");
    if (typeof link.download === "string") {
      link.href = uri;
      link.download = filename;
      //Firefox requires the link to be in the body
      document.body.appendChild(link);
      //simulate click
      link.click();
      //remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  };

  useEffect(() => {
    dispatch(getLeverageData());
    dispatch(getLivePrices());
    dispatch(getStakeInfo());
  }, [user]);

  return (
    <div className="dashboard mb-5 mb-md-0 main-p">
      <Container className="dashboard__container mt--1 mt-md--3 mb-1 px-0">
        <DashboardBanner />
      </Container>
      <Container className="dashboard__card shadow-sm mt--4 d-flex align-items-center w-100">
        <img
          src={NoficationIco}
          alt="notification"
          height="15"
          className="me-4"
        />
        {fetchAnnoucementLoader ? (
          <Spinner size="sm" className="mx-auto" />
        ) : (
          <Marquee>
            {annoucements?.map((announcement: any, idx: number) => (
              <h2
                className="mb-0 dashboard__card__heading fw-semibold me-8"
                key={idx}
              >
                {announcement?.title}
              </h2>
            ))}
          </Marquee>
        )}
      </Container>

      <Container className="p-0">
        <Col md="12">
          <div className="card mt-2">
            <div className="card__body border-radius-all">
              <div className="d-flex align-items-center justify-content-between">
                <p className="mb-0 filter__card-label">Welcome </p>
                <p className="mb-0 filter__card-label text-site-primary">
                  {authLoading ? (
                    <Spinner size="sm" />
                  ) : user?.user?.user_name ? (
                    user?.user?.user_name
                  ) : (
                    "N/A"
                  )}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-1">
                <p className="mb-0 filter__card-label">Date of 1st Stake:</p>
                <p className="mb-0 filter__card-label text-site-primary">
                  {fetchLoader ? (
                    <Spinner size="sm" />
                  ) : stake_info?.newStakeData?.[0]?.date_time ? (
                    moment(stake_info?.newStakeData?.[0]?.date_time).format(
                      "DD MMM YYYY"
                    )
                  ) : (
                    "N/A"
                  )}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-1">
                <p className="mb-0 filter__card-label">Staked Capital</p>
                <p className="mb-0 filter__card-label">
                  {" "}
                  $
                  {fetchLoader ? (
                    <Spinner size="sm" />
                  ) : (
                    stake_info?.newStake?.toFixed(3) || 0
                  )}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-1">
                <p className="mb-0 filter__card-label">Your Referral Code</p>
                <p className="mb-0 filter__card-label text-primary">
                  {" "}
                  {authLoading ? (
                    <Spinner size="sm" />
                  ) : user?.user?.user_lid ? (
                    user?.user?.user_lid
                  ) : (
                    "N/A"
                  )}
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Container>
      <Container className="p-0">
        <Col md="12">
          <div className="card mt-2 ">
            <div className="card__body border-radius-all">
              <div className="d-flex align-items-center justify-content-between">
                <p className="mb-0 filter__card-label">Total Leverage</p>
                <p className="mb-0 filter__card-label "> Leverage Used</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-1">
                <h6 className="mb-0  text-site-primary number">
                  {" "}
                  $
                  {fetchLoader ? (
                    <Spinner size="sm" />
                  ) : leverageData?.mlimit ? (
                    Number(leverageData?.mlimit)?.toFixed(3)
                  ) : (
                    0
                  )}
                </h6>
                <h6 className="mb-0   text-site-primary number">
                  {" "}
                  $
                  {fetchLoader ? (
                    <Spinner size="sm" />
                  ) : leverageData?.income ? (
                    Number(leverageData?.income)?.toFixed(3)
                  ) : (
                    0
                  )}
                </h6>
              </div>
            </div>
          </div>
        </Col>
      </Container>

      <Container className="mt-2 dashboard__card px-0 px-sm-4 shadow-sm">
        <div className=" dashboard__menu ">
          <div className="dashboard__menu-item">
            <div
              className="dashboard__menu-item__content"
              onClick={toggleInvite}
            >
              {/* <div className="dashboard__menu-item__content-icon"></div> */}
              <img
                src={InviteIco}
                alt="invite"
                className="dashboard__menu-item__content-ico"
              />
              Invite
            </div>
          </div>
          <div className="dashboard__menu-item">
            <div
              className="dashboard__menu-item__content"
              onClick={() => {
                navigate("/admin/sub-pages?comp=stake");
              }}
            >
              <img
                src={StakeIco}
                alt="stake"
                className="dashboard__menu-item__content-ico"
              />
              Stake
            </div>
          </div>
          <div className="dashboard__menu-item">
            <div
              className="dashboard__menu-item__content"
              onClick={() => {
                navigate("/admin/sub-pages?comp=team");
              }}
            >
              <img
                src={TeamIco}
                alt="team"
                className="dashboard__menu-item__content-ico"
              />
              Team
            </div>
          </div>
          <div className="dashboard__menu-item">
            <div
              className="dashboard__menu-item__content"
              onClick={() => {
                navigate("/admin/sub-pages?comp=business");
              }}
            >
              <img
                src={BusinessIco}
                alt="business"
                className="dashboard__menu-item__content-ico"
              />
              Business
            </div>
          </div>
          <div className="dashboard__menu-item">
            <div
              className="dashboard__menu-item__content"
              onClick={() => {
                navigate("/admin/sub-pages?comp=earnings");
              }}
            >
              <img
                src={EarningsIcon}
                alt="earnings"
                className="dashboard__menu-item__content-ico"
              />
              Earnings
            </div>
          </div>
          <div
            className="dashboard__menu-item"
            style={{
              borderBottom: !isMore ? "none" : "",
            }}
          >
            <div
              className="dashboard__menu-item__content"
              onClick={() => {
                navigate("/admin/sub-pages?comp=profile");
              }}
            >
              <img
                src={ProfileIco}
                alt="profile"
                className="dashboard__menu-item__content-ico"
              />
              Profile
            </div>
          </div>
          <div
            className="dashboard__menu-item"
            style={{
              borderBottom: !isMore ? "none" : "",
            }}
          >
            <div
              className="dashboard__menu-item__content"
              // onClick={() => {
              //   navigate("/admin/bonanza");
              // }}
            >
              <img
                src={bon}
                alt="nft"
                className="dashboard__menu-item__content-ico"
              />
              Bonanza
            </div>
          </div>
          <div
            className="dashboard__menu-item"
            style={{
              borderBottom: !isMore ? "none" : "",
            }}
          >
            <div
              className="dashboard__menu-item__content"
              onClick={() => {
                navigate("/admin/wallet");
              }}
            >
              <img
                src={WalletIco}
                alt="wallet"
                className="dashboard__menu-item__content-ico"
              />
              Wallet
            </div>
          </div>
          <div
            className="dashboard__menu-item"
            style={{
              borderBottom: !isMore ? "none" : "",
            }}
          >
            <div
              className="dashboard__menu-item__content"
              onClick={() => {
                navigate("/admin/statement");
              }}
            >
              <img
                src={StatementIco}
                alt="statement"
                className="dashboard__menu-item__content-ico"
              />
              Statement
            </div>
          </div>

          <div
            className="dashboard__menu-item"
            style={{
              borderBottom: !isMore ? "none" : "",
            }}
          >
            <div
              className="dashboard__menu-item__content"
              onClick={() => {
                toggleIsMore();
              }}
            >
              <img
                src={MoreIco}
                alt="more"
                className="dashboard__menu-item__content-ico"
                style={{
                  transform: isMore ? "" : "rotateX(190deg)",
                }}
              />
              More
            </div>
          </div>
          {isMore && (
            <>
              <div className="dashboard__menu-item">
                <div
                  className="dashboard__menu-item__content"
                  onClick={() => {
                    // window?.open(
                    //   "https://blocksafu.com/audit/0xaB289Db90A149A0a3257Cab7c996025D8D3b9598"
                    // );
                    navigate("/admin/audit-list");
                  }}
                >
                  <img
                    src={AuditIco}
                    alt="nft"
                    className="dashboard__menu-item__content-ico"
                  />
                  Audit
                </div>
              </div>
              <div className="dashboard__menu-item">
                <a
                  href="https://twitter.com/LinkdaoN"
                  target="_blank"
                  rel="noreferrer"
                  className="text-decoration-none"
                >
                  <div className="dashboard__menu-item__content">
                    <img
                      src={TwitterIco}
                      alt="nft"
                      className="dashboard__menu-item__content-ico"
                    />
                    Twitter
                  </div>
                </a>
              </div>
              <div className="dashboard__menu-item">
                <a
                  href="https://t.co/wLSoKue1Yi"
                  target="_blank"
                  rel="noreferrer"
                  className="text-decoration-none"
                >
                  <div className="dashboard__menu-item__content">
                    <img
                      src={FbIco}
                      alt="nft"
                      className="dashboard__menu-item__content-ico"
                    />
                    Facebook
                  </div>
                </a>
              </div>
              <div className="dashboard__menu-item">
                <a
                  href="https://t.co/Un1gnd1atj"
                  target="_blank"
                  rel="noreferrer"
                  className="text-decoration-none"
                >
                  <div className="dashboard__menu-item__content">
                    <img
                      src={TgIco}
                      alt="nft"
                      className="dashboard__menu-item__content-ico"
                    />
                    Telegram
                  </div>
                </a>
              </div>
              <div className="dashboard__menu-item">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="text-decoration-none"
                >
                  <div className="dashboard__menu-item__content">
                    <img
                      src={YTIco}
                      alt="nft"
                      className="dashboard__menu-item__content-ico"
                    />
                    Youtube
                  </div>
                </a>
              </div>
            </>
          )}
        </div>
      </Container>
      <Container className="dashboard__card mt-2 shadow-sm">
        {fetchLoader ? (
          <div className="text-center">
            <Spinner size="md" />
          </div>
        ) : (
          <Row className="justify-content-center">
            {livePrices
              ?.filter((price: any) => price?.name != "ethereum")
              ?.map((price: any, idx: any) => (
                <Col
                  md="3"
                  xs="4"
                  className=" dashboard__card__live-price"
                  key={idx}
                >
                  <div
                    style={{
                      maxWidth: "150px",
                      margin: "auto",
                    }}
                  >
                    <h2 className="mb-1 dashboard__card__heading fw-semibold">
                      <img
                        src={
                          price?.name == "bitcoin"
                            ? BTCIcon
                            : price?.name == "binancecoin"
                            ? BNBIcon
                            : LKDIcon
                        }
                        alt="ico"
                        height="17px"
                        className="me-2"
                      />{" "}
                      {getCoinSymbol(price?.name)} / USDT
                    </h2>
                    <div className={`d-flex align-items-center mt-2`}>
                      <h2 className="mb-0 dashboard__card__value text-black">
                        ${price?.price?.toFixed(2)}
                      </h2>
                      <Badge
                        className="ms-1 ms-md-2"
                        style={{
                          fontSize: "9px",
                          background:
                            price?.priceChange < 1
                              ? "rgba(220, 53, 69, 0.09)"
                              : "rgba(25, 135, 84, 0.09)",
                          color: price?.priceChange < 1 ? "#dc3545" : "#198754",
                        }}
                      >
                        {price?.priceChange?.toFixed(2)}%
                      </Badge>
                    </div>
                  </div>
                </Col>
              ))}
            {livePrices
              ?.filter((price: any) => price?.name == "ethereum")
              ?.map((price: any, idx: any) => (
                <Col
                  md="3"
                  xs="4"
                  className="dashboard__card__live-price d-none d-md-block"
                  key={idx}
                >
                  <div
                    style={{
                      maxWidth: "150px",
                      margin: "auto",
                    }}
                  >
                    <h2 className="mb-1 dashboard__card__heading fw-semibold">
                      <img
                        src={EthIcon}
                        alt="ico"
                        height="17px"
                        className="me-2"
                      />{" "}
                      {getCoinSymbol(price?.name)} / USDT
                    </h2>
                    <div className="d-flex align-items-center mt-2">
                      <h2 className="mb-0 dashboard__card__value text-black">
                        ${price?.price?.toFixed(2)}
                      </h2>
                      <Badge
                        className="ms-2"
                        style={{
                          fontSize: "9px",
                          background:
                            price?.priceChange < 1
                              ? "rgba(220, 53, 69, 0.09)"
                              : "rgba(25, 135, 84, 0.09)",
                          color: price?.priceChange < 1 ? "#dc3545" : "#198754",
                        }}
                      >
                        {price?.priceChange?.toFixed(2)}%
                      </Badge>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        )}
      </Container>
      <Container className="dashboard__card mt-2 shadow-sm">
        <h2 className="text-center mb-0 dashboard__card__heading fw-semibold text-uppercase">
          FREQUENTLY ASK QUESTION
        </h2>
      </Container>
      <FAQs />

      <Modal isOpen={isInviteOpen} className="dashboard__invite-modal border-0">
        <ModalBody id="lkd-invitation">
          <div className="dashboard__invite-modal__custom-body">
            <div className="d-flex justify-content-between align-items-center">
              <img src={Logo} alt="logo" width="124px" />
              <i
                className="fa fa-times-circle text-white fa-lg pointer"
                onClick={toggleInvite}
              ></i>
            </div>
            <div className="text-center mt-5">
              <div className="d-flex align-items-start justify-content-center">
                <h2 className="text-white dashboard__invite-modal__custom-body-call me-1">
                  Opprtunity Call
                </h2>
                <i className="fa fa-phone-volume fa-lg text-site-primary dashboard__invite-modal__custom-body-phone"></i>
              </div>
              <p className="text-white dashboard__invite-modal__custom-body-refer mb-0">
                BUY I STAKE I REFER & EARN
              </p>
              <div className="d-flex align-items-end justify-content-center">
                <h2 className="me-2 text-white dashboard__invite-modal__custom-body-upto">
                  Upto
                </h2>
                <h2 className="text-site-primary dashboard__invite-modal__custom-body-percent">
                  400%
                </h2>
              </div>
            </div>
            <div className="dashboard__invite-modal__custom-body__code-wrapper mx-auto mt-4">
              <div className="dashboard__invite-modal__custom-body__code-wrapper-main">
                <p className="mb-0 dashboard__invite-modal__custom-body__code-wrapper-title mb-0">
                  Invitation Code
                </p>
                <h2 className="dashboard__invite-modal__custom-body__code-wrapper-value mb-0">
                  {user?.user?.user_lid ? user?.user?.user_lid : "N/A"}
                </h2>
              </div>
              {/* <QRCode
                value={user?.user?.user_lid ? user?.user?.user_lid : "N/A"}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              /> */}
              <img
                src={AreYouReady}
                alt="are you ready"
                className="img-fluid"
              />
            </div>
            <div className="d-flex justify-content-center dashboard__invite-modal__custom-body__code-wrapper__btn-wrapper">
              <Button
                // className="me-2"
                onClick={() => {
                  let baseURL = window?.location?.href?.split("/admin/index");
                  navigator?.clipboard?.writeText(
                    baseURL?.[0] +
                      "/register?invitation-code=" +
                      user?.user?.user_lid
                  );
                  Swal.fire({
                    text: "Link Copied",
                    title: "Success",
                    confirmButtonText: "Ok",
                    icon: "success",
                  });
                }}
              >
                Copy Link
              </Button>
              {/* <Button
                onClick={() => {
                  // toggleIsShare();
                  sharePoster();
                }}
              >
                Download
              </Button> */}
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={isShare} centered>
        <ModalBody className="pt-2">
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <small>Share Link</small>
              <i
                className="fa fa-times text-danger pointer"
                onClick={toggleIsShare}
              ></i>
            </div>
            <div className="mt-3 modal_scroll">
              <FacebookShareButton url={InviteLink} quote="">
                <FacebookIcon round size={48} />
              </FacebookShareButton>
              <WhatsappShareButton url={InviteLink}>
                <WhatsappIcon round size={48} />
              </WhatsappShareButton>
              <TelegramShareButton url={InviteLink}>
                <TelegramIcon round size={48} />
              </TelegramShareButton>
              <TwitterShareButton url={InviteLink}>
                <TwitterIcon round size={48} />
              </TwitterShareButton>
              <LinkedinShareButton url={InviteLink}>
                <LinkedinIcon round size={48} />
              </LinkedinShareButton>
            </div>
            <div className="mt-3">
              <div className="copy-wrapper position-relative">
                <Input type="text" readOnly disabled value={InviteLink} />
                <i
                  className="fa fa-copy"
                  onClick={() => {
                    let baseURL = window?.location?.href?.split("/admin/index");
                    navigator?.clipboard?.writeText(
                      baseURL?.[0] + "?invitation-code=" + user?.user?.user_lid
                    );
                    Swal.fire({
                      text: "Link Copied",
                      title: "Success",
                      confirmButtonText: "Ok",
                      icon: "success",
                    });
                  }}
                ></i>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Dashboard;
